@if (pageStatus() === "loading") {
  <div class="blurred-div">
    <p-progressSpinner
      ariaLabel="loading"
      class="blurred-div-spinner"
    ></p-progressSpinner>
  </div>
} @else if (pageStatus() === "success"){
  <div class="wrapper text-center py-4">
    @switch (response()?.pageType) {
      @case ("default-pick-up") {
        <app-cbox-default-pick-up-open-page
          [response]="response()"
        ></app-cbox-default-pick-up-open-page>
      }

      @case ("default-drop-off") {
        <app-cbox-default-drop-off-open-page
          [response]="response()"
        ></app-cbox-default-drop-off-open-page>
      }

      @case ("ro-post-pick-up") {
        <app-cbox-ro-post-pick-up-open-page
          [response]="response()"
        ></app-cbox-ro-post-pick-up-open-page>
      }

      @case ("ro-post-drop-off") {
        <app-cbox-default-pick-up-open-page
          [response]="response()"
        ></app-cbox-default-pick-up-open-page>
      }
    }
  </div>
} @else if (pageStatus() === "error") {
  <div class="grid py-4 h-screen">
    <div class="col-12 flex flex-column justify-content-center text-center gap-4">
      <span class="pi pi-times-circle text-red-500 text-8xl"></span>
      <p class="text-red-500 font-bold text-2xl line-height-2">Link-ul este invalid sau a expirat.</p>
    </div>
  </div>
}