<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Configurare modul {{ module.serial }}</h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    size="small"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <form class="grid" [formGroup]="moduleForm">
    <div class="col-12 sm:col-6">
      <span class="block font-bold mb-1">Poziție</span>
      <input
        pInputText
        pAutoFocus
        pKeyFilter="int"
        formControlName="position"
        [autofocus]="true"
      >
    </div>
    <div class="col-12 sm:col-6">
      <span class="block font-bold mb-1">ID placă</span>
      <input
        pInputText
        pAutoFocus
        pKeyFilter="int"
        formControlName="boardCardAddress"
        [autofocus]="true"
      >
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex gap-2 align-items-center">
    <button
      size="small"
      pButton
      label="Anulează"
      mat-dialog-close
      [text]="true"
    ></button>
    <button
      size="small"
      pButton
      label="Salvează"
      (click)="update()"
    ></button>
  </div>
</mat-dialog-actions>