import { CommonModule } from '@angular/common';``
import { Component, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from "@angular/material/icon";
import { ValidateEmail } from '../../validators/async-email-validator';
import { ApiService, handlePublicApiError } from '../../services/api/api.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from "primeng/inputtext";
import { PasswordModule } from "primeng/password";
import { FloatLabelModule } from "primeng/floatlabel";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { CardModule } from "primeng/card";
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from 'src/services/user/user.service';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    MatIconModule,
    PasswordModule,
    FloatLabelModule,
    ProgressSpinnerModule,
    CardModule,
    InputGroupModule,
    InputGroupAddonModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl(""),
    password: new FormControl("")
  });

  showPassword = signal(false);
  loading = signal(false);

  constructor(
    private api: ApiService,
    private router: Router,
    private toastService: ToastService,
    private userService: UserService
  ) {
    this.loginForm.get('email')?.setAsyncValidators(
        ValidateEmail.createValidator(this.api));
  }

  ngOnInit(): void {}

  login(): void {
    const { email, password } = this.loginForm.value;
    this.loading.set(true);
    this.api.post("backend/auth/login", { email, password }).subscribe(async (response) => {
      try {
        await this.userService.login();
        this.router.navigate(["/profile"]);
      } catch (e) {
        this.loading.set(false);
      }
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

}
