<p-button
  size="small"
  label="Creează echipament"
  icon="pi pi-plus"
  (click)="createEquipment()"
></p-button>

<form class="grid align-items-center mt-1" [formGroup]="filtersForm">
  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-1">Locker</span>
    <p-dropdown
      showClear="true"
      filter="true"
      formControlName="lockerIdentifier"
      optionLabel="name"
      optionValue="id"
      placeholder="Selectați un locker"
      styleClass="w-full"
      appendTo="body"
      [options]="lockers()"
      [loading]="!lockers()"
    />
  </div>

  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-1">Serie modul</span>
    <p-iconField class="w-full" iconPosition="right">
      @if (filtersForm.get('moduleSerial')?.value?.length > 0) {
        <p-inputIcon
          styleClass="pi pi-times cursor-pointer"
          (click)="patchFilters({
            moduleSerial: ''
          })"
        />
      }
      <input
        pInputText
        class="w-full"
        placeholder="Serie modul"
        formControlName="moduleSerial"
      />
    </p-iconField>
  </div>

  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-1">Tip echipament</span>
    <p-dropdown
      showClear="true"
      filter="true"
      formControlName="equipmentType"
      optionLabel="name"
      optionValue="id"
      placeholder="Selectați un tip de echipament"
      styleClass="w-full"
      appendTo="body"
      [options]="equipmentTypes()"
      [loading]="!equipmentTypes()"
    />
  </div>

  <div class="flex align-items-center gap-2 col-12 lg:col-3">
    <p-checkbox
      class="mt-4"
      label="Instalate"
      formControlName="includeInstalled"
      [binary]="true"
    ></p-checkbox>

    <p-checkbox
      class="mt-4"
      label="Neinstalate"
      formControlName="includeNotInstalled"
      [binary]="true"
    ></p-checkbox>
  </div>
</form>
<p-table
  styleClass="p-datatable-sm p-datatable-striped w-full"
  paginatorPosition="both"
  currentPageReportTemplate="{first} - {last} din {totalRecords} echipamente"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [value]="equipments()"
  [rows]="25"
  [paginator]="true"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[25, 50, 100]"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (fetchingEquipments()) {
      <tr>
        <td colspan="8">
          <p-progressBar
            mode="indeterminate"
            styleClass="w-full"
            [style]="{ height: '6px' }"
          ></p-progressBar>
        </td>
      </tr>
    }
    <tr>
      <th>Serie</th>
      <th>Tip</th>
      <th>Serie producător</th>
      <th>Modul</th>
      <th>Locker</th>
      <th>Comentarii</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-equipment>
    <tr>
      <td>{{ equipment.internalSerial }}</td>
      <td>
        <p class="w-max cursor-pointer" (click)="patchFilters({
          equipmentType: equipment.type.id
        })">
          {{ equipment.type.name }}
        </p>
      </td>
      <td>{{ equipment.manufacturerSerial }}</td>
      <td>
        <p
          class="w-max cursor-pointer"
          (click)="patchFilters({
            moduleSerial: equipment.module.serial
          })"
        >
          {{ equipment.module.serial }}
          <span
            class="pi pi-external-link cursor-pointer w-max"
            pTooltip="Propagă modulul în celelalte componente"
            tooltipPosition="top"
            (click)="$event.stopPropagation(); propagateModule(equipment.module.serial)"
          ></span>
        </p>
        <span></span>
      </td>
      <td>
        <div
          class="flex flex-column w-max cursor-pointer"
          (click)="patchFilters({
            lockerIdentifier: equipment.module.locker.externalIdentifier
          })"
        >
          <small class="text-gray-400">#{{ equipment.module.locker.externalIdentifier }}</small>
          <b>{{ equipment.module.locker.name }}</b>
          <span
            class="pi pi-external-link cursor-pointer w-max"
            pTooltip="Propagă locker-ul în celelalte componente"
            tooltipPosition="top"
            (click)="$event.stopPropagation(); propagateLocker(equipment.module.locker.externalIdentifier)"
          ></span>
        </div>
      </td>
      <td>{{ equipment.notes }}</td>
      <td class="text-right">
        <p-menu
            styleClass="w-15rem"
            #equipmentMenu
            appendTo="body"
            [popup]="true"
            [model]="equipmentMenuItems()"
          />
          <p-button
            size="small"
            icon="pi pi-ellipsis-v"
            [plain]="true"
            [text]="true"
            [rounded]="true"
            (click)="$event.stopPropagation(); selectedEquipment.set(equipment); equipmentMenu.toggle($event)"
          ></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    @if (!fetchingEquipments()) {
      <tr>
        <td colspan="8">
          <p class="text-gray-400 text-center text-lg font-bold">Nu există echipamente.</p>
        </td>
      </tr>
    }
  </ng-template>
</p-table>