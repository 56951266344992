<div class="flex flex-column justify-content-between align-items-center h-full gap-4">
  <div></div>
  <form onsubmit="return false" class="flex flex-column gap-4 w-30rem" [formGroup]="loginForm" (submit)="login()">
    <div class="text-center">
      <mat-icon class="logo align-self-center w-20rem h-4rem" svgIcon="logo"></mat-icon>
      <h1>Autentificare</h1>
    </div>

    <p-inputGroup>
      <p-inputGroupAddon class="bg-white border-noround-right">
        <i class="pi pi-user"></i>
      </p-inputGroupAddon>
      <input
        autocomplete="off"
        class="w-full"
        id="email"
        type="text"
        pInputText
        formControlName="email"
        placeholder="Email"
        [autofocus]="true"
        [ngClass]="{
          'ng-invalid': loginForm.get('email')?.invalid
        }"
      >
    </p-inputGroup>

    <p-inputGroup>
      <p-inputGroupAddon class="bg-white border-noround-right">
        <i class="pi pi-lock"></i>
      </p-inputGroupAddon>
      <input
        type="password"
        styleClass="border-noround-left"
        autocomplete="off"
        class="w-full border-noround-left"
        id="password"
        formControlName="password"
        placeholder="Parolă"
        pInputText
      >
    </p-inputGroup>

    <p-button
      severity="primary"
      styleClass="w-full"
      label="Conectează-te"
      type="submit"
      [disabled]="loading()"
      (click)="$event.preventDefault(); login()"
    ></p-button>
  </form>
  <div></div>
</div>
@if (loading() === true) {
  <div class="blurred-div">
    <p-progressSpinner
      ariaLabel="loading"
      class="blurred-div-spinner"
    ></p-progressSpinner>
  </div>
}

