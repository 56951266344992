import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, DestroyRef, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CBox_ActionUpdateSelfUserData, CBox_GetSelfUserResponse, CBox_EntityLoginDevice } from '@server/services/cbox/public/api/v1/resources/user/types';
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ProfileService } from 'src/services/profile/profile.service';
import { UserService } from 'src/services/user/user.service';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { HttpErrorResponse } from '@angular/common/http';
import { MenuItem } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AvatarModule } from 'primeng/avatar';
import { MenuModule } from 'primeng/menu';
import { MatDialog } from '@angular/material/dialog';
import { CBoxProfileDeviceDisconnectDialogComponent } from '../dialogs/device-disconnect/cbox-profile-device-disconnect-dialog.component';
import { first } from 'rxjs';
import { getLastActiveMessage } from 'src/helpers/functions';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-cbox-profile-me-general',
  templateUrl: './cbox-profile-me-general.component.html',
  styleUrls: ["./cbox-profile-me-general.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextareaModule,
    ProgressSpinnerModule,
    AvatarModule,
    MenuModule
  ]
})

export class CBoxProfileMeGeneralComponent implements OnInit {

  userDataFetchStatus = signal<"fetching" | "success" | "error">("fetching");
  userData = signal<CBox_GetSelfUserResponse | undefined>(undefined);
  userDataForm: FormGroup;
  initialFormValue = signal("");
  deviceMenuItems = signal<MenuItem[]>([
    {
      label: ""
    }
  ])

  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private api: ApiService,
    private destroyRef: DestroyRef,
    private dialog: MatDialog,
    private toastService: ToastService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.userDataForm = this.fb.group({
        name: [{
          value: "",
          disabled: true
        }, Validators.required],
        email: [{
          value: "",
          disabled: true
        }, Validators.required],
        phone: ["", Validators.required],
        notes: [""]
      });
    }

  ngOnInit(): void {
    this.init();
  }

  async loadData(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      await this.fetchUserData();
    }
  }

  changeUserData(): void {
    this.userDataFetchStatus.set("fetching");
    this.api.auth().patch("backend/users/me", this.getUpdateStructure()).subscribe(async () => {
      this.toastService.showSuccessToast("Confirmare", "Datele au fost actualizate cu succes.");
      this.userService.clear();
      await this.loadData();
      this.userDataFetchStatus.set("success");
    }, (e: HttpErrorResponse) => {
      this.userDataFetchStatus.set("error");
      handlePublicApiError(e, this.toastService);
    });
  }

  formDataChanged(): boolean {
    const currentFormValue = JSON.stringify(this.userDataForm.getRawValue());
    return this.initialFormValue() !== currentFormValue;
  }

  getLastActiveMessage(lastUsedAt: string): string {
    return getLastActiveMessage(lastUsedAt);
  }

  logoutDevice(device: CBox_EntityLoginDevice): void {
    const dialog = this.dialog.open(CBoxProfileDeviceDisconnectDialogComponent, {
      data: device,
      width: "min(400px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(async (deleted: boolean) => {
      if (deleted) {
        this.toastService.showSuccessToast("Confirmare", "Dispozitivul a fost deconectat cu succes.");
        this.userService.clear();
        await this.loadData();
      }
    });
  }

  private async init(): Promise<void> {
    this.profileService.setTitle("Detalii cont");
    await this.loadData();
  }

  private async fetchUserData(): Promise<void> {
    this.userDataFetchStatus.set("fetching");
    const { isFetched, isError, data } = await this.userService.getData();
    if (isFetched && !isError) {
      this.userData.set(data);
      this.userDataForm.patchValue({
        name: data?.name,
        email: data?.email,
        phone: data?.phone
      });
      this.initialFormValue.set(JSON.stringify(this.userDataForm.getRawValue()));
      this.userDataForm.enable();
      this.userDataFetchStatus.set("success");
    }
    if (isError) {
      this.userDataFetchStatus.set("error");
      this.userDataForm.disable();
    }
  }

  private getUpdateStructure(): CBox_ActionUpdateSelfUserData {
    const { name, email, phone, notes } = this.userDataForm.value;
    return {
      name,
      email,
      phone,
      notes,
    };
  }
}