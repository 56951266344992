import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CBox_GetSelfUserResponse } from '@server/services/cbox/public/api/v1/resources/user/types';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ApiService } from 'src/services/api/api.service';
import { ProfileService } from 'src/services/profile/profile.service';
import { SessionService } from 'src/services/session/session.service';
import { UserService } from 'src/services/user/user.service';
import { first } from 'rxjs';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-cbox-profile-me',
  templateUrl: 'cbox-profile-me.component.html',
  styleUrls: ["./cbox-profile-me.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    DividerModule,
    InputGroupModule,
    InputGroupAddonModule,
    MatTooltipModule,
    TabMenuModule
  ]
})

export class CBoxProfileMeComponent implements OnInit {

  userDataFetchStatus = signal<"fetching" | "success" | "error">("fetching");
  userData = signal<CBox_GetSelfUserResponse | undefined>(undefined);
  tabMenuItems = signal<MenuItem[]>([
    {
      label: "General",
      routerLink: "/profile/me/general",
      icon: "pi pi-cog"
    },
    {
      label: "Securitate",
      routerLink: "/profile/me/security",
      icon: "pi pi-lock"
    },
  ]);

  constructor(
    private profileService: ProfileService,
    private api: ApiService,
    private fb: FormBuilder,
    private session: SessionService,
    private userService: UserService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {}

  ngOnInit(): void {
    this.init();
  }

  async loadData(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      await this.fetchUserData();
    }
  }

  private async init(): Promise<void> {
    this.profileService.setTitle("Detalii cont");
    await this.loadData();
  }

  private async fetchUserData(): Promise<void> {
    this.userDataFetchStatus.set("fetching");
    const { isFetched, isError, data } = await this.userService.getData();
    if (isFetched && !isError) {
      this.userData.set(data);
      this.userDataFetchStatus.set("success");
    }
    if (isError) {
      this.userDataFetchStatus.set("error");
    }
  }
}