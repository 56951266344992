<div class="wrapper">
  <mat-icon class="logo align-self-center w-20rem h-4rem" svgIcon="logo"></mat-icon>
  <p class="text-lg mb-0 mx-2">
    Comanda dumneavoastră poate fi depusă la locker până la data de<br>
  </p>
  <p class="font-bold text-2xl">
    {{ response?.expiresAt | date: "dd MMMM" }} ora {{ response?.expiresAt | date: "HH:mm" }}
  </p>
  <div [innerHTML]="response?.openQrImage" class="flex align-items-center justify-content-center">

  </div>
</div>