export enum CBox_EquipmentTypeEnum {
  GENERIC = "Generic",
  DVR = "DVR",
  ROUTER = "ROUTER"
}

export type CBox_EquipmentTypeRequestData = undefined;

export type CBox_EquipmentModel = {
  id: number;
  name: string;
  description: string;
};

export type CBox_EquipmentType = {
  id: number;
  name: string;
  description: string;
  type: CBox_EquipmentTypeEnum;
  models: CBox_EquipmentModel[];
};

export type CBox_EquipmentTypeResponse = CBox_EquipmentType[];

export type CBox_GenericEquipment = {
  notes: string;
  internalSequenceNumber: number;
  internalSerial: string;
  manufacturerSerial: string;
  modelId: number;
};

export type CBox_DvrEquipmentData = {
  dvr: {
    user: string;
    password: string
    connectCode: string;
  }
};

export type CBox_RouterEquipmentData = {
  adminUser: string;
  adminPassword: string;
  ssid: string;
  wifiPassword: string;
  sim: {
    phoneNumber: string;
    simNumber: string;
    ip: string;
    subscription: {
      name: string;
      // description: string;
      // price: number;
    }
  }
};

// dvr
export type CBox_DvrEquipment = CBox_GenericEquipment & CBox_DvrEquipmentData;

// router
export type CBox_RouterEquipment = CBox_GenericEquipment & {
  router: CBox_RouterEquipmentData;
};

export type CBox_AdminEquipmentRequestData =
    CBox_GenericEquipment|CBox_DvrEquipment|CBox_RouterEquipment;

export type CBox_AdminEquipmentResponse = undefined;

export type CBox_AdminEquipmentListRequestData = {
  page?: number;
  pageSize?: number;
  typeId?: number;
  includeInstalled?: boolean;
  includeNotInstalled?: boolean;
  lockerIdentifier?: string;
  moduleSerial?: string;
};

export type CBox_AdminEquipmentListItem = {
  id: number;
  internalSerial: string;
  manufacturerSerial: string;
  model: {
    id: number;
    name: string;
    description: string;
  }
  type: {
    id: number;
    name: string;
    description: string;
    type: CBox_EquipmentTypeEnum;
  }
  isAttached: boolean;
  module: {
    id: number;
    serial: string;
    locker?: {
      externalIdentifier: string;
      name: string;
    }
  }
};

export type CBox_AdminEquipmentListResponse = CBox_AdminEquipmentListItem[];