
@if (agentUpdateStatus() === "waiting" || agentUpdateStatus() === "updating") {
  <div class="dialog-header">
    <h2>Editare agent</h2>
      <p-button
        mat-dialog-close
        icon="pi pi-times"
        severity="secondary"
        [rounded]="true"
        [text]="true"
      />
    </div>
  }
  <mat-dialog-content>
    @if (agentUpdateStatus() === "waiting" || agentUpdateStatus() === "updating") {
      <form class="formgrid grid row-gap-2 relative" [formGroup]="agentForm">
        @if (oldUniqueIdentifier() !== agentForm.get("identifier")?.value && oldUniqueIdentifier()) {
          <p-messages
            styleClass="text-xs mt-0"
            class="col-12"
            [value]="identifierChangeWarning()"
            [enableService]="false"
            [closable]="false"
          />
        }

        <div class="col-12 sm:col-6">
          <span class="font-bold block mb-1">Nume agent</span>
          <input
            formControlName="name"
            class="w-full"
            id="awb"
            pInputText
            pAutoFocus
            [autofocus]="true"
          >
          @let name = agentForm.get("name");
          @let nameErrors = agentForm.get("name")?.errors;
          @if (name?.dirty) {
            @if (nameErrors) {
              @if (nameErrors?.["required"]) {
                <small class="text-red-500">Numele agentului este obligatoriu!</small>
              }
            }
          }
        </div>

        <div class="col-12 sm:col-6">
          <span class="font-bold block mb-1">Identificator</span>
          <input
            formControlName="identifier"
            class="w-full"
            id="awb"
            pInputText
          >
          @let identifier = agentForm.get("identifier");
          @let identifierErrors = agentForm.get("identifier")?.errors;
          @if (identifier?.dirty) {
            @if (identifierErrors) {
              @if (identifierErrors?.["required"]) {
                <small class="text-red-500">Identificatorul este obligatoriu!</small>
              } @else if (identifierErrors?.["minlength"]) {
                <small class="text-red-500">Identificatorul trebuie sa aiba minim {{ identifierErrors["minlength"] }}!</small>
              }
            }
          }
          <div class="flex align-items-center gap-2">
            <p-checkbox
              class="mt-1"
              [binary]="true"
              formControlName="customIdentifier"
              inputId="customIdentifier"
              (onChange)="toggleCustomIdentifier()"
            ></p-checkbox>
            <label class="-mb-1" for="customIdentifier">Identificator personalizat</label>
          </div>
        </div>

        <div
        class="col-12 sm:col-6"
      >
        <span class="font-bold block mb-1">Telefon</span>
        @let phone = agentForm.get("phone");
        <input
          class="w-full"
          type="text"
          pInputText
          [placeholder]="phone?.disabled ? '' : '07xxxxxxxx'"
          formControlName="phone"
        />
        @let phoneErrors = agentForm.get("phone")?.errors;
        @if (phone?.dirty) {
          @if (phoneErrors) {
            @if (phoneErrors?.["required"]) {
              <small class="text-red-500">Numarul de telefon este obligatoriu!</small>
            }
          }
        }
        <div class="flex align-items-center gap-2">
          <p-checkbox
            class="mt-1"
            [binary]="true"
            formControlName="noPhoneNumber"
            inputId="noPhoneNumber"
            (onChange)="noPhoneNumberToggled()"
          ></p-checkbox>
          <label class="-mb-1 text-xs" for="noPhoneNumber">Agentul nu are numar de telefon</label>
        </div>
      </div>

      <div class="col-12 sm:col-6">
        <span class="font-bold block mb-1">Email</span>
        @let email = agentForm.get("email");
        <input
          class="w-full"
          type="text"
          pInputText
          [placeholder]="email?.disabled ? '' : 'test@companie.ro'"
          formControlName="email"
        >
        @let emailErrors = agentForm.get("email")?.errors;
        @if (email?.dirty) {
          @if (emailErrors) {
            @if (emailErrors?.["required"]) {
              <small class="text-red-500">Email-ul este obligatoriu!</small>
            }
          }
        }
        <div class="flex align-items-center gap-2">
          <p-checkbox
            class="mt-1"
            [binary]="true"
            formControlName="noEmailAddress"
            inputId="noEmailAddress"
            (onChange)="noEmailAddressToggled()"
          ></p-checkbox>
          <label class="-mb-1 text-xs" for="noEmailAddress">Agentul nu are adresa de email</label>
        </div>
      </div>

      @if (allowedZones().length > 1) {
        <div class="col-12">
          <span class="block font-bold mb-1">Zonă</span>
          <p-dropdown
            formControlName="zone"
            optionLabel="name"
            optionValue="id"
            styleClass="w-full"
            appendTo="body"
            placeholder="Selectează zona agentului"
            [options]="allowedZones()"
            [loading]="!zonesFetched()"
            (onChange)="zoneSelected()"
          ></p-dropdown>
        </div>
      }

      @let selectedZone = agentForm.get("zone")?.value;
      @if (selectedZone || allowedZones().length === 1) {
        <div [formGroup]="lockersForm" class="col-12">
          <span class="font-bold block mb-1">Lockere</span>
          @let specific = lockersForm.get("specific");
          @let specificErrors = specific?.errors;
          <p-multiSelect
            #lockersMultiSelect
            formControlName="specific"
            optionLabel="name"
            optionValue="id"
            [placeholder]="lockersForm.get('all')?.value ? 'Toate lockerele din zona ' + this.getZoneName(
              allowedZones().length === 1 ?
                allowedZones()[0].id :
                selectedZone
            ) : 'Selectează lockere'"
            styleClass="w-full"
            appendTo="body"
            emptyFilterMessage="Nu există lockere"
            selectedItemsLabel="{0} lockere selectate"
            [options]="
              allowedZones().length === 1 ?
                groupedLockers()[allowedZones()[0].id] :
                groupedLockers()[selectedZone]"
            (onChange)="lockerSelected()"
          >
            <ng-template let-locker pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div>
                  <small class="text-gray-400">#{{ locker?.id }}</small>
                  <p class="m-0">{{ locker?.name }}</p>
                </div>
              </div>
            </ng-template>
          </p-multiSelect>
          @if (specific?.dirty) {
            @if (specificErrors?.["minlength"]) {
              <small class="text-red-500">Vă rugăm să selectați minim un locker</small>
            }
          }
          <div class="flex align-items-center gap-2 mt-1">
            <p-checkbox
              [binary]="true"
              formControlName="all"
              inputId="allLockers"
              (onChange)="toggleAllLockers()"
            ></p-checkbox>
            <label class="text-xs" for="allLockers">Toate lockerele</label>
          </div>
        </div>
      }

        <div class="col-12">
          <span class="font-bold block mb-1">Permisiuni</span>
          <p-multiSelect
            formControlName="permissions"
            optionLabel="label"
            optionValue="name"
            placeholder="Alege permisiuni"
            styleClass="w-full"
            appendTo="body"
            emptyFilterMessage="Nu există permisiuni"
            [filter]="false"
            [options]="permissions()"
            [loading]="!permissions()"
          ></p-multiSelect>
        </div>

        <div class="col-12 sm:col-6">
          <span class="font-bold block mb-1">Perioadă valabilitate parolă</span>
          <div class="grid mt-1">
            <div class="col-7">
              <input
                pInputText
                pKeyFilter="money"
                class="w-full"
                formControlName="temporaryPasswordExpirationOffset"
              >
            </div>
            <div class="col-5 pl-0">
              <p-dropdown
                formControlName="passwordExpirationTimeUnit"
                optionLabel="label"
                optionValue="value"
                styleClass="w-full"
                appendTo="body"
                [options]="[
                  { label: 'Zile', value: 'days' },
                  { label: 'Ore', value: 'hours' }
                ]"
              ></p-dropdown>
            </div>
          </div>
          <small class="block">Se aplică pentru parolele viitoare</small>
        </div>
      </form>
    } @else if (agentUpdateStatus() === "created") {
      <div class="flex flex-column align-items-center justify-content-center text-center">
        <span class="material-symbols-rounded md-64 text-green-500">task_alt</span>
        <h2 class="text-center text-green-500 line-height-2 mb-0">Parola a fost regenerata cu succes!</h2>
        <p class="text-2xl font-bold mb-0 word-wrap-anywhere max-w-full line-height-2">{{ createResponse()?.lockerOpenCode }}</p>
        <p>Codul de mai sus trebuie folosit pentru autentificarea la locker. Vă rugăm să vă asigurați că îl copiați.</p>
        <p-button
          size="small"
          [severity]="courierCodeCopied() ? 'success' : 'primary'"
          [label]="courierCodeCopied() ? 'Parola a fost copiată' : 'Copiază parolă'"
          [icon]="courierCodeCopied() ? 'pi pi-check' : 'pi pi-copy'"
          iconPos="right"
          [text]="true"
          (click)="copyCourierCode()"
        ></p-button>
        <p>Atenție! Parola generată nu va mai putea fi vizualizată, ci doar resetată.</p>
        <div class="inline-flex align-items-center gap-2 mb-2">
          <p-checkbox
            inputId="confirmation"
            [formControl]="confirmation"
            [binary]="true"
          ></p-checkbox>
          <label
            for="confirmation"
            [ngClass]="{
              'text-red-500': !confirmation.value && confirmation.dirty && confirmation.touched
            }"
          >Confirm că am copiat codul</label>
        </div>
      </div>
    }
  </mat-dialog-content>
  <mat-dialog-actions>
    @if (agentUpdateStatus() === "waiting" || agentUpdateStatus() === "updating") {
      <button
        pButton
        label="Salvează"
        icon="pi pi-check"
        iconPos="right"
        (click)="updateAgent()"
      ></button>
    }
    @if (agentUpdateStatus() === "created") {
      <button
        pButton
        class="w-full"
        severity="danger"
        label="Închide"
        [disabled]="!confirmation.value"
        (click)="closeModal()"
      ></button>
    }
    </mat-dialog-actions>
  @if (agentUpdateStatus() === "updating" || loading() === true) {
    <div class="blurred-div">
      <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
    </div>
  }