import { CommonModule } from "@angular/common";
import { Component, Inject, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_AdminActionUpdateLockerRequestData } from "@server/services/cbox/public/api/v1/resources/internal/locker/types";
import { AutoFocusModule } from "primeng/autofocus";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { KeyFilterModule } from "primeng/keyfilter";

@Component({
  selector: "app-cbox-admin-locker-module-update",
  templateUrl: "./cbox-admin-locker-module-update.component.html",
  styleUrls: ["./cbox-admin-locker-module-update.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    ButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    AutoFocusModule,
    KeyFilterModule,
    MatDialogClose
  ]
})

export class CBoxAdminLockerModuleUpdateComponent {

  loading = signal(true);
  moduleForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CBoxAdminLockerModuleUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public module: CBox_AdminActionUpdateLockerRequestData["body"]["modules"][0] & {
      serial: string;
    }) {
      this.moduleForm = this.fb.group({
        position: [+this.module.position, Validators.required],
        boardCardAddress: [+this.module.boardCardAddress, Validators.required],
      })
    }

  update(): void {
    if (this.moduleForm.invalid) {
      return;
    }
    this.dialogRef.close(this.moduleForm.value);
  }
}