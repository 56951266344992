import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_ActionUpdateApiUserResponse, CBox_GetApiUserListResponseItem } from '@server/services/cbox/public/api/v1/resources/user/types';
import { Message } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { copyString, getLastActiveMessage } from 'src/helpers/functions';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-cbox-profile-api-user-regenerate-secret-dialog',
  templateUrl: './cbox-profile-api-user-regenerate-secret-dialog.component.html',
  styleUrls: ["./cbox-profile-api-user-regenerate-secret-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MessagesModule,
    CheckboxModule,
    ProgressSpinnerModule,
  ]
})

export class CBoxProfileApiUserRegenerateSecretDialogComponent implements OnInit {

  pageStatus = signal<"regenerated" | "regenerating" | "waiting">("waiting");
  regenerateResponse = signal<CBox_ActionUpdateApiUserResponse | undefined>(undefined);
  confirmed = signal(false);
  warnMessage = signal<Message[]>([{
    severity: 'warn',
    detail:
        `Regenerarea credentialelor va duce la invalidarea celor vechi.`
  }]);
  clientSecretCopied = signal(false);
  clientSecretCopyConfirmation = new FormControl(false, Validators.requiredTrue);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public apiUser: CBox_GetApiUserListResponseItem,
    @Inject(PLATFORM_ID) private platformId: string) { }

  ngOnInit() { }

  regenerateClientSecret(): void {
    const params = new HttpParams();
    params.append("regenerateSecret", true);
    this.api.auth().patch<CBox_PublicSuccessResponse<CBox_ActionUpdateApiUserResponse>>("backend/users/api/" + this.apiUser.identifier, undefined, params).subscribe((response) => {
      this.regenerateResponse.set(response.data);
      this.pageStatus.set("regenerated");
    }, (e: HttpErrorResponse) => {
      this.pageStatus.set("waiting");
      handlePublicApiError(e, this.toastService);
    });
  }

  getLastActiveMessage(lastUsedAt: string): string {
    return getLastActiveMessage(lastUsedAt);
  }

  copyClientSecret(): void {
    if (this.clientSecretCopied()) {
      return;
    }
    copyString(this.regenerateResponse()?.clientSecret!);
    this.clientSecretCopied.set(true);
    setTimeout(() => {
      this.clientSecretCopied.set(false);
    }, 2000);
  }
}