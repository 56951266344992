import { CommonModule } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_ActionDeleteAgentResponse, CBox_PublicGetAgentDataResponse } from "@server/services/cbox/public/api/v1/resources/agent/types";
import { CBox_PublicErrorResponse, CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { Message, MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { MessagesModule } from "primeng/messages";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-profile-agent-access-revoke-confirmation-dialog",
  templateUrl: "./cbox-profile-agent-access-revoke-confirmation-dialog.component.html",
  styleUrls: ["./cbox-profile-agent-access-revoke-confirmation-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ButtonModule,
    ProgressSpinnerModule,
    CheckboxModule,
    MessagesModule
  ],
  providers: [MessageService]
})

export class CBoxProfileAgentAccessRevokeConfirmationDialogComponent implements OnInit {

  revokingAccess = signal(false);
  confirmed = signal(false);

  warnMessage = signal<Message[]>([
    {
      severity: "warn",
      detail: "Această acțiune va avea ca efect invalidarea parolei și a codului de identificare ale agentului.",
    }
  ]);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfileAgentAccessRevokeConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public agent: CBox_PublicGetAgentDataResponse) { }

  ngOnInit(): void {}

  revokeAccess(): void {
    this.revokingAccess.set(true);
    this.api.auth()
        .delete<CBox_PublicSuccessResponse<CBox_ActionDeleteAgentResponse>>(
            "backend/agent/" + this.agent.agent.identifier + "/codes")
        .subscribe(
            (response) => {
              this.dialogRef.close(response.success);
              this.revokingAccess.set(false);
            },
            (e: HttpErrorResponse) => {
              this.revokingAccess.set(false);
              handlePublicApiError(e, this.toastService);
            });
  }
}