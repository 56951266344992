import { CommonModule, isPlatformBrowser } from "@angular/common";
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { CBox_PublicGetCodeDataResponse } from "@server/services/cbox/public/api/v1/resources/open/code/types";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBoxDefaultPickUpOpenPageComponent } from "./templates/pick-up/default/cbox-default-pick-up-open-page.component";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { ToastService } from "src/services/toast/toast.service";
import { CBoxDefaultDropOffOpenPageComponent } from "./templates/drop-off/default/cbox-default-drop-off-open-page.component";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { CBoxRoPostPickUpOpenPageComponent } from "./templates/pick-up/ro-post/cbox-ro-post-pick-up-open-page.component";

export type CBox_PublicGetCodeDataResponseFormatted = Omit<CBox_PublicGetCodeDataResponse, "openQrImage"> & {
  openQrImage: SafeHtml;
}

@Component({
  selector: "app-cbox-open-page-container",
  templateUrl: "./cbox-open-page-container.component.html",
  styleUrls: ["./cbox-open-page-container.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    CBoxDefaultPickUpOpenPageComponent,
    CBoxDefaultDropOffOpenPageComponent,
    CBoxRoPostPickUpOpenPageComponent
  ]
})

export class CBoxOpenPageContainerComponent implements OnInit {

  response = signal<CBox_PublicGetCodeDataResponseFormatted | undefined>(undefined);
  pageStatus = signal<"loading" | "error" | "success">("loading");

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {}

  ngOnInit() {
    this.init();
  }

  private async init(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      this.checkIdentifier();
    }
  }

  checkIdentifier(): void {
    const identifier = this.route.snapshot.paramMap.get("identifier");
    if (!identifier?.length) {
      return;
    }
    const params = new HttpParams().append("reference", identifier!);
    this.api.auth().get<CBox_PublicSuccessResponse<CBox_PublicGetCodeDataResponse>>("backend/open/code", params).subscribe((response) => {
      this.response.set(response.data);
      this.pageStatus.set("success");
    }, (e: HttpErrorResponse) => {
      this.pageStatus.set("error");
      handlePublicApiError(e, this.toastService);
    });
  }
}