// src/app/auth/admin-guard.service.ts

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ConfigurationService } from 'src/services/configuration/configuration.service';
import { ToastService } from 'src/services/toast/toast.service';

@Injectable({ providedIn: "root"})
class PermissionGuard {
  constructor(public configuration: ConfigurationService,
              public router: Router,
              private toastService: ToastService,
              @Inject(PLATFORM_ID) private platformId: Object,
              public activatedRoute: ActivatedRoute) {
}
  url = "";

/**
 * @function PermissionGuard (CAN ACTIVATE)
 *
 * @returns { boolean }
 * @description: checks if the user has access to a route
 * @example
 * * children: [ // NEVER ON CONTAINER only on Children Routes
 * * {
 * *  path: "route",
 * *  component: Component,
 * *  canActivate: [PermissionGuard],
 * *  data: {
 * *    permissions: ["permission1", "permission2"]
 * *  }
 * * }
 * * ]
 * ! IMPORTANT:
 * ! If you want to include the guard in a route, you must add a data property
 * ! to the route, with a permission property AS a string[].
 */
  async canActivateRoute(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (route.routeConfig?.children) {
      throw new Error(
        `PermissionGuard should never be on a container route,
        only on children routes`
      );
    }
    if (route.data == null) {
      throw new Error("Route data is undefined");
    }
    if (this.configuration.hasAccess(route.data["permissions"])) {
      return true;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.router.navigate(["/login"]);
    }

    // this.toastService.showErrorToast("Acces interzis", "Nu aveți permisiunea de a accesa această pagină");
    return false;
  }

  /**
 * @function RedirectToRouteThatHasAccess (REDIRECT)
 *
 * @alias permission_guard.ts
 *
 * @param { any[] } routes
 * @returns { boolean } // navigateByUrl
 * @description: redirects the user to a route that he has access to
 * @example
 * THIS HAS TO BE USED ON CONTAINER OF THE COMPONENT
 * * routes = [
 * *   {
 * *     link: "./list",
 * *     name: "Puncte de ridicare",
 * *     rights: [
 * *       "admin.pickUpPoints.list.canSeePage"
 * *     ]
 * *   }
 * * ];
 *
 * * constructor() {
 * *   (async () => {
 * *     await RedirectToRouteThatHasAccess(this.routes);
 * *   })();
 * * } // this will redirect the user to the first route that he has access to
 *
 * ! IMPORTANT:
 * You need to add inside the container.html:
 * @example
 *<ng-container *ngFor="let route of routes">
 *   <ng-container *appRights="route.rights">
 *  <a
 *   mat-button
 *   [routerLink]="route.link"
 *   routerLinkActive #rla="routerLinkActive"
 *   [active]="rla.isActive"
 *   >{{ route.name }}</a
 *
 *   </ng-container>
 * </ng-container>
 */
  async RedirectToRouteThatHasAccess(routes: any): Promise<void> {
    const currentUrl = this.router.url;
    await this.configuration.addRoutes(currentUrl, routes);
    let match = await this.configuration.matchesRoute(currentUrl, true);
    if (match.found === false) {
      this.router.navigate(["/login"]);
      // this.toastService.showErrorToast("Acces interzis", "Nu aveți permisiunea de a accesa această pagină");
    }
  }
}
/*
  !Important
  When you include this guard in a route, you must add a data property
  to the route, with a permission property as a string[].
*/
export const PermissionGuardService: CanActivateFn =
  async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
    return await inject(PermissionGuard).canActivateRoute(route, state);
  }

export async function RedirectToRouteThatHasAccess(routes: any) {
  return await inject(PermissionGuard).RedirectToRouteThatHasAccess(routes);
}