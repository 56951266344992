<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Confirmare</h2>
  <p-button
    size="small"
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <p>Sunteți sigur că doriți deconectarea dispozitivului?</p>
  <div class="flex align-items-center border-round-xs w-full">
    <p-avatar
      size="large"
      shape="circle"
      class="mr-2"
      icon="pi pi-desktop"
    ></p-avatar>
    <div class="flex flex-column">
      @if (
        device.deviceDetails.browser === "Other" ||
        device.deviceDetails.os === "Other" ||
        device.deviceDetails.platform === "Other"
      ) {
        <span>Nedefinit</span>
        <small class="text-gray-400">{{ getLastActiveMessage(device.lastUsedAt) }}</small>
      } @else {
        <p class="m-0">{{ device.deviceDetails.browser }} (<small class="vertical-align-top">{{ device.deviceDetails.os }}</small>)</p>
        <small class="text-gray-400">{{ getLastActiveMessage(device.lastUsedAt) }}</small>
      }
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex gap-2 align-items-center">
    <p-button
      size="small"
      mat-dialog-close
      label="Anulează"
      severity="secondary"
      [text]="true"
    ></p-button>
    <p-button
      size="small"
      label="Deconectează"
      severity="danger"
      icon="pi pi-sign-out"
      iconPos="right"
      (click)="disconnectDevice()"
    ></p-button>
  </div>
</mat-dialog-actions>

@if (loading() === true) {
  <div class="blurred-div">
    <p-progressSpinner
      ariaLabel="loading"
      class="blurred-div-spinner"
    ></p-progressSpinner>
  </div>
}
