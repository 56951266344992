<p-button
  *appRights="[permissions.createZone]"
  label="Creează zonă"
  icon="pi pi-plus"
  iconPos="left"
  (click)="createZone()"
></p-button>

<p-table
  styleClass="p-datatable-sm"
  currentPageReportTemplate="{first} - {last} din {totalRecords} zone"
  paginatorPosition="both"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [rows]="this.filtersForm.get('pageSize')?.value"
  [value]="zones()"
  [paginator]="true"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[25, 50, 100]"
>
  <ng-template pTemplate="header">
    @if (fetchingZones()) {
      <tr>
        <td colspan="7">
          <p-progressBar
            mode="indeterminate"
            styleClass="w-full"
            [style]="{ height: '6px' }"
          ></p-progressBar>
        </td>
      </tr>
    }
    <tr>
      <th>Nume</th>
      <th>Descriere</th>
      <th>Număr subzone</th>
      <th>Număr lockere</th>
      <th>Zonă părinte</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-zone>
    <tr>
      <td>{{ zone.name }}</td>
      <td>{{ zone.description }}</td>
      <td>{{ zone.childZoneCount }}</td>
      <td>{{ zone.zoneLockerCount }}</td>
      <td>
        @if (zone.parent) {
          <p class="m-0">{{ zone.parent.name }}</p>
        }
      </td>
      <td class="text-right py-0">
        @if (!zone.isMainZone && zone.id !== this.currentUserZone()) {
          <p-button
            size="small"
            icon="pi pi-pencil"
            [plain]="true"
            [rounded]="true"
            [text]="true"
            (click)="updateZone(zone)"
          ></p-button>
        }
      </td>
    </tr>
  </ng-template>
</p-table>