<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Detalii sesiune</h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    size="small"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  @if (sessionData()) {
    <p class="text-xs text-gray-400 m-0">{{ sessionData()?.agent?.identifier }}</p>
    <h3 class="m-0">{{ sessionData()?.agent?.name }}</h3>
    <p class="text-xs m-0 text-gray-400">
      {{ sessionData()?.agent?.phone }}
      <span>
        &#x2022;
        {{ sessionData()?.agent?.email }}
      </span>
    </p>
    <p>
      {{ sessionData()?.activity?.start | date: 'dd MMMM HH:mm' }} - {{ sessionData()?.activity?.end | date: 'dd MMMM HH:mm' }}
    </p>
    <h3>Comenzi</h3>
    <p-table
      [value]="[{}]"
      styleClass="p-datatable-sm"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="text-center">Livrate</th>
          <th class="text-center">Recuperate</th>
          <th class="text-center">Ridicate</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body">
        <tr>
          <td class="text-center">{{ getActionParcelCount(actions.COURIER_PUT_ORDERS) }}</td>
          <td class="text-center">{{ getActionParcelCount(actions.COURIER_RETRIEVE_EXPIRED) }}</td>
          <td class="text-center">{{ getActionParcelCount(actions.COURIER_TAKE_ORDERS) }}</td>
        </tr>
      </ng-template>
    </p-table>
    <h3>Acțiuni</h3>
    <p-table
      [value]="sessionData()?.actions!.details"
      styleClass="p-datatable-sm"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Acțiune</th>
          <th>AWB</th>
          <th>Referință unică</th>
          <th>Oră</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-action>
        <tr
          class="hover:surface-100 cursor-pointer"
          (click)="openOrderInfo(action)"
        >
          <td [ngClass]="{
            'text-blue-500': action.type === actions.COURIER_TAKE_ORDERS,
            'text-green-500': action.type === actions.COURIER_PUT_ORDERS,
            'text-red-500': action.type === actions.COURIER_RETRIEVE_EXPIRED,
          }">
            @switch (action.type) {
              @case (actions.COURIER_PUT_ORDERS) {
                Livrare
              }

              @case (actions.COURIER_TAKE_ORDERS) {
                Ridicare
              }

              @case (actions.COURIER_RETRIEVE_EXPIRED) {
                Recuperare
              }
            }
          </td>
          <td>{{ action.request.awb }}</td>
          <td>{{ action.request.uniqueReference }}</td>
          <td>
            {{ action.request.occurredAt | date: 'HH:mm' }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  }
</mat-dialog-content>
@if (!sessionData()) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}