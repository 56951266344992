import { Component, DestroyRef, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { ProfileService } from "../../../../services/profile/profile.service";
import { CommonModule, isPlatformBrowser } from "@angular/common";
import { ApiService, handlePublicApiError } from "../../../../services/api/api.service";
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { lockerCleanAddress } from "../../../../helpers/functions";
import { CacheService } from "../../../../services/cache/cache.service";
import { CBoxProfileLockerDataComponent } from "../data/cbox-profile-locker-data.component";
import { debounceTime, first } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { InputTextModule } from "primeng/inputtext";
import { FloatLabelModule } from "primeng/floatlabel";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { CBoxProfileCreatePickupRequestComponent } from "app/profile/requests/pickup/dialogs/add/cbox-profile-create-pickup-request.component";
import { ButtonModule } from "primeng/button";
import { TableModule, TablePageEvent } from "primeng/table";
import { CardModule } from "primeng/card";
import { ChipModule } from "primeng/chip";
import { TagModule } from "primeng/tag";
import { MessageService } from "primeng/api";
import { CBoxProfileAgentCreateComponent } from "app/profile/agents/dialogs/create/cbox-profile-agent-create.component";
import { CBox_GetLockerBaseData, CBox_GetLockerDataResponse, CBox_GetLockerListDataItem, CBox_GetLockerListDataParams, CBox_GetLockerShortData, CBox_LockerBoxCount } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { CBox_PublicPaginatedResponse, CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CheckboxModule } from "primeng/checkbox";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { ToastService } from "src/services/toast/toast.service";
import { PermissionDirective } from "src/directives/permission.directive";
import { PermissionNamesEnum } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { ProgressBarModule } from "primeng/progressbar";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { SessionService } from "src/services/session/session.service";
import { IconFieldModule } from "primeng/iconfield";
import { InputIconModule } from "primeng/inputicon";
import { LockersService } from "src/services/lockers/lockers.service";
import { MultiSelectModule } from "primeng/multiselect";

@Component({
  selector: "app-profile-lockers-list",
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FloatLabelModule,
    ButtonModule,
    TableModule,
    CardModule,
    ChipModule,
    TagModule,
    CheckboxModule,
    ReactiveFormsModule,
    InputGroupModule,
    InputGroupAddonModule,
    PermissionDirective,
    ProgressBarModule,
    IconFieldModule,
    InputIconModule,
    MultiSelectModule
],
  providers: [
    MessageService
  ],
  templateUrl: "./profile-lockers-list.component.html",
  styleUrl: "./profile-lockers-list.component.scss"
})
export class ProfileLockersListComponent implements OnInit {

  lockers = signal<CBox_GetLockerBaseData[]>([]);
  toSearchLockers = signal<CBox_GetLockerShortData[] | undefined>(undefined);
  filtersForm: FormGroup;
  fetching = signal(false);
  totalCount = signal(0);
  showBoxesReport = new FormControl(false);

  dialogRef: DynamicDialogRef | undefined;
  permissions = PermissionNamesEnum;

  constructor(
    private profileService: ProfileService,
    private api: ApiService,
    private cache: CacheService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private toastService: ToastService,
    private configuration: ConfigurationService,
    private destroyRef: DestroyRef,
    private session: SessionService,
    private lockersService: LockersService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>){
    this.profileService.setTitle("Listă lockere");
    this.filtersForm = this.fb.group({
      name: [""],
      pageSize: [100], // default 100
      page: [1], // default 1
      lockers: [[]],
      includePickupCount: [false],
      includeDropoffCount: [false],
    });
  }

  ngOnInit(): void {
    this.init();
  }

  formatLockerAddress(address: any): string {
    return lockerCleanAddress(address);
  }

  pageChanged(event: TablePageEvent): void {
    const page = event.first / event.rows;
    this.filtersForm.patchValue({
      page: page + 1,
      pageSize: event.rows
    });
    this.saveFilters();
  }

  async openLockerDetails(locker: CBox_GetLockerBaseData): Promise<void> {
    if (!this.configuration.hasAccess([PermissionNamesEnum.viewLockerDetails])) {
      this.toastService.showErrorToast("Acces interzis", "Nu aveți acces pentru a vizualiza detaliile unui locker");
      return;
    }
    const dialog = this.dialog.open(CBoxProfileLockerDataComponent, {
      data: locker.name,
      minWidth: "min(1200px, 100%)"
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchLockerData(locker.id);
    });
  }

  addPickupOrder(): void {
    this.dialog.open(CBoxProfileCreatePickupRequestComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });
  }

  addCourier(): void {
    this.dialog.open(CBoxProfileAgentCreateComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });
  }

  loadLockers(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.lockers.set([]);
      this.fetching.set(true);
      this.fetchLockers();
    }
  }

  addPickupOrderToLocker(locker: CBox_GetLockerBaseData): void {
    const dialog = this.dialog.open(CBoxProfileCreatePickupRequestComponent, {
      data: locker,
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchLockers();
    });
  }

  sortedBySize(boxes: CBox_LockerBoxCount[]): CBox_LockerBoxCount[] {
    const sizes = ["XS", "S", "M", "L", "XL"];
    return boxes.sort((a, b) => sizes.indexOf(a.size) - sizes.indexOf(b.size));
  }

  // addComponent(): void {
  //   this.dialog.open(CBoxAdminEquipmentCreateDialogComponent, {
  //     width: "min(600px, 100%)",
  //     disableClose: true,
  //     autoFocus: false
  //   });
  // }

  // createModule(): void {
  //   this.dialog.open(CBoxAdminModuleCreateComponent, {
  //     width: "min(600px, 100%)",
  //     disableClose: true,
  //     autoFocus: false
  //   });
  // }

  // createLocker(): void {
  //   this.dialog.open(CBoxAdminLockerCreateConfirmDialogComponent, {
  //     width: "min(600px, 100%)",
  //     disableClose: true,
  //     autoFocus: false
  //   });
  // }

  private async init(): Promise<void> {
    this.profileService.setTitle("Listă lockere");

    this.toSearchLockers.set(await this.lockersService.getList());

    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const defaultFilters = {
      name: "",
      pageSize: 100, // default 100
      page: 1, // default 1
      lockers: [],
      includePickupCount: false,
      includeDropoffCount: false,
      showBoxesReport: false
    };

    const sessionFilters = this.session.getVal("profile-lockers-list/filters", undefined);
    const storageFilters = this.cache.getVal("profile-lockers-list/filters", undefined);

    if (sessionFilters || storageFilters) {
      let parsedFilters;
      let parsedStorageFilters;
      try {
        parsedFilters = JSON.parse(sessionFilters);
      } catch (e) {}
      try {
        parsedStorageFilters = JSON.parse(storageFilters);
      } catch (e) {}

      this.filtersForm.patchValue(
        {
          ...defaultFilters,
          ...parsedFilters,
          ...parsedStorageFilters
        },
        { emitEvent: false }
      );
      this.showBoxesReport.patchValue(parsedStorageFilters?.showBoxesReport);
    } else {
      this.filtersForm.patchValue(defaultFilters, { emitEvent: false });
    }

    this.filtersForm.valueChanges
      .pipe(
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.saveFilters();
        this.loadLockers();
      });

    if (isPlatformBrowser(this.platformId)) {
      this.loadLockers();
    }
  }

  private fetchLockers(): void {
    const queryParams = new HttpParams({
      fromObject: this.getSearchStructure()
    });
    this.api.auth().get<CBox_PublicPaginatedResponse<CBox_GetLockerBaseData>>("backend/locker/list?includePickUpCount=true&includeDropOffCount=true", queryParams).subscribe((response) => {
      this.lockers.set(response.data);
      this.totalCount.set(response.totalCount);
      this.fetching.set(false);
    }, (e: HttpErrorResponse) => {
      this.fetching.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  private fetchLockerData(id: string): void {
    const lockers = this.lockers();
    const index = lockers.findIndex((locker: CBox_GetLockerListDataItem) => locker.id === id);
    if (index !== -1) {
      const queryParams = new HttpParams().append("name", lockers[index].name);
      this.api.auth().get<CBox_PublicSuccessResponse<CBox_GetLockerDataResponse>>("backend/locker", queryParams).subscribe((response) => {
        lockers[index] = response.data;
        this.lockers.set(lockers);
      });
    }
  }

  saveFilters(): void {
    const filtersValues = this.filtersForm.value;
    const sessionFilters = {
      lockers: filtersValues.lockers,
    };
    const storageFilters = {
      pageSize: filtersValues.pageSize,
      showBoxesReport: this.showBoxesReport.value,
    }
    this.session.setVal("profile-lockers-list/filters", JSON.stringify(sessionFilters));
    this.cache.setVal("profile-lockers-list/filters", JSON.stringify(storageFilters));
  }

  private getSearchStructure(): CBox_GetLockerListDataParams {
    return Object.entries({
      name: this.filtersForm.value.name,
      page: +this.filtersForm.value.page,
      "id[]": this.filtersForm.value.lockers,
      pageSize: +this.filtersForm.value.pageSize
    })
    .filter(([_, value]) => value?.length || !!value)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_GetLockerListDataParams] = value;
      return acc;
    }, {} as CBox_GetLockerListDataParams);
  }

}
