
<div
  class="sidebar-container"
  [ngClass]="{
    'collapsed': collapsed()
  }"
  [style.width]="sidenavWidth()"
  (mouseenter)="toggleSidenav(true)"
  (mouseover)="toggleSidenav(true)"
  (mouseleave)="toggleSidenav(false)"
>

  <div class="sidebar-header">
    @if (brandLogoSrc) {
      <img [src]="brandLogoSrc" class="logo">
    } @else {
      <mat-icon
        class="logo"
        svgIcon="logo-small"
      ></mat-icon>
    }
  </div>

  <div class="sidebar-menu-container">
    <p-menu [model]="menuItems()" styleClass="w-full border-none" class="sidebar-menu">
      <ng-template pTemplate="item" let-item>
        @if (item.visible) {
          <ng-container *appRights="item.rights">
            <a routerLinkActive="active" [routerLink]="item.link" class="p-menuitem-link">
              @if (item.icon) {
                <span class="icon" [class]="item.icon"></span>
              }
              @if (item.materialIcon) {
                <span class="material-symbols-rounded">{{ item.materialIcon }}</span>
              }
              <span class="ml-4 label">{{ item.label }}</span>
            </a>
          </ng-container>
        }
      </ng-template>
    </p-menu>
  </div>
  <div class="sidebar-bottom-menu-container">
    <p-menu class="sidebar-bottom-menu" styleClass="border-none w-full">
      <ng-template pTemplate="end">
        <a routerLinkActive="active" routerLink="/profile/me/general" class="p-menuitem-link text-gray-600 hover:bg-gray-100">
          <span class="material-symbols-rounded">person</span>
          <span class="inline-flex flex-column ml-4 label">
            <span>Detalii cont</span>
          </span>
        </a>

        <a class="p-menuitem-link text-red-500 hover:bg-gray-100" (click)="confirmLogout($event)">
          <span class="material-symbols-rounded">logout</span>
          <span class="inline-flex flex-column ml-4 label">
            Deconectare
          </span>
        </a>
      </ng-template>
    </p-menu>
  </div>
</div>
<div [style.marginLeft]="'92px'">
  <div class="bg-gray-100 px-4 py-3 border-bottom-1 border-300">
    <h1 class="m-0 text-2xl">{{ profileService.getTitle()() }}</h1>
  </div>
  <div class="px-4 py-2">
    <router-outlet></router-outlet>
  </div>
</div>

<p-confirmDialog></p-confirmDialog>