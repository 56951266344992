<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Reactivare comandă</h2>
  <p-button
    mat-dialog-close
    size="small"
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  @if (orderData()) {
    <p>Doriți să reactivați comanda cu AWB <b>{{ orderData()?.awb }}</b>?</p>
    @if (orderData()?.pickUpPossibleUntil) {
      <p>
        Termenul de ridicare al comenzii a fost
        <b>{{ orderData()?.pickUpPossibleUntil | date: "dd MMMM" }}</b>
        ora
        <b>{{ orderData()?.pickUpPossibleUntil | date: "HH:mm" }}</b>
      </p>
    }
    <span class="font-bold block mb-1">Data de expirare</span>
    <p-calendar
      inputId="reactivateDate"
      dateFormat="dd/mm/yy"
      styleClass="w-full"
      appendTo="body"
      [showTime]="true"
      [formControl]="extendDate"
      [minDate]="today()"
      [showIcon]="true"
    ></p-calendar>
  } @else {
    <p-skeleton styleClass="w-full mt-1"></p-skeleton>

    <p-skeleton styleClass="w-full mt-3"></p-skeleton>
    <p-skeleton styleClass="w-full mt-2"></p-skeleton>

    <p-skeleton width="8rem" styleClass="mt-3"></p-skeleton>
    <p-skeleton height="2.8125rem" styleClass="w-full mt-2" borderRadius=".5rem"></p-skeleton>
  }
</mat-dialog-content>
<mat-dialog-actions>
  @if (fetching()) {
    <div class="flex align-items-center gap-2">
      <p-skeleton
        height="2.5rem"
        width="6.25rem"
        borderRadius=".5rem"
      ></p-skeleton>

      <p-skeleton
        height="2.5rem"
        width="9.375rem"
        borderRadius=".5rem"
      ></p-skeleton>
    </div>
  } @else {
    <div class="flex align-items-center gap-2">
      <p-button
        label="Anulează"
        size="small"
        [text]="true"
        mat-dialog-close
      ></p-button>

      <p-button
        label="Reactivează"
        size="small"
        icon="pi pi-play"
        iconPos="left"
        (click)="reactivateOrder()"
      ></p-button>
    </div>
  }
</mat-dialog-actions>
@if (reactivating()) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}