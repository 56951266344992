import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { CBox_GetLockerBaseData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { MapComponent } from "app/map/map.component";
import { CBox_PublicGetCodeDataResponseFormatted } from "app/open/cbox-open-page-container.component";
import { DividerModule } from "primeng/divider";
import { lockerCleanAddress } from "src/helpers/functions";

@Component({
  selector: "app-cbox-ro-post-pick-up-open-page",
  templateUrl: "./cbox-ro-post-pick-up-open-page.component.html",
  styleUrls: ["./cbox-ro-post-pick-up-open-page.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MapComponent,
    DividerModule
  ]
})

export class CBoxRoPostPickUpOpenPageComponent implements OnInit {
  @Input() response: CBox_PublicGetCodeDataResponseFormatted | undefined;

  constructor() { }

  ngOnInit() { }

  lockerCleanAddress(addressParts: CBox_GetLockerBaseData["addressParts"]): string {
    return lockerCleanAddress(addressParts);
  }

  openGoogleMaps(): void {
    const coordinates = this.response?.locker?.coordinates;
    if (coordinates) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${coordinates.latitude},${coordinates.longitude}`, "_blank");
    }
  }
}