import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, computed, DestroyRef, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule, TablePageEvent } from 'primeng/table';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { LockersService } from 'src/services/lockers/lockers.service';
import { ProfileService } from 'src/services/profile/profile.service';
import { SessionService } from 'src/services/session/session.service';
import { ToastService } from 'src/services/toast/toast.service';
import { CBox_AdminModuleListItemDataResponse, CBox_AdminModuleListRequestData } from "@server/services/cbox/public/api/v1/resources/internal/locker_module/types";
import { CBox_GetLockerShortData } from '@server/services/cbox/public/api/v1/resources/locker/types';
import { MenuItem } from 'primeng/api';
import { debounceTime, first } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CBox_PublicPaginatedResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { CBoxAdminModuleCreateComponent } from '../dialogs/create/cbox-admin-module-create.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CBoxAdminModuleInfoComponent } from '../dialogs/info/cbox-admin-module-info.component';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';

@Component({
  selector: 'app-cbox-admin-locker-modules-list',
  templateUrl: './cbox-admin-locker-modules-list.component.html',
  styleUrls: ["./cbox-admin-locker-modules-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    MenuModule,
    ProgressBarModule,
    ReactiveFormsModule,
    CheckboxModule,
    DropdownModule,
    InputTextModule,
    InputIconModule,
    IconFieldModule
  ]
})

export class CBoxAdminLockerModulesListComponent implements AfterViewInit {
  filtersForm: FormGroup;

  modules = signal<CBox_AdminModuleListItemDataResponse[]>([]);
  moduleTypes = signal([
    {
      id: "ALL",
      label: "Toate"
    },
    {
      id: "ATTACHED",
      label: "Atașate"
    },
    {
      id: "NOT_ATTACHED",
      label: "Neatașate"
    }
  ]);
  fetchingModules = signal(false);

  lockers = signal<CBox_GetLockerShortData[]>([]);
  fetchingLockers = signal(false);

  selectedModule = signal<CBox_AdminModuleListItemDataResponse | undefined>(undefined);
  moduleMenuItems = computed<MenuItem[]>(() => [
    {
      label: "Șterge comanda",
      icon: "pi pi-trash",
      command: () => {
        // this.confirmOrderDelete(this.selectedOrder());
      },
    },
  ]);
  totalCount = signal(0);

  constructor(
    private api: ApiService,
    private profileService: ProfileService,
    private session: SessionService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private destroyRef: DestroyRef,
    private lockerService: LockersService,
    private toastService: ToastService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {
    this.filtersForm = this.fb.group({
      page: [1],
      pageSize: [100],
      filter: ["ALL"],
      serial: [""],
      lockerIdentifier: [undefined]
    });
  }

  ngAfterViewInit(): void {
    this.init();
  }

  patchFilters(filters: {[key: string]: string}) {
    this.filtersForm.patchValue(filters);
  }

  private async init(): Promise<void> {
    this.profileService.setTitle("Listă module");

    this.lockers.set(await this.lockerService.getList());

    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const defaultFilters = {
      page: 1,
      pageSize: 100,
      serial: "",
      filter: "ALL",
      lockerIdentifier: undefined
    };

    const cachedFilters = this.session.getVal("admin-modules-list/filters", undefined);

    if (cachedFilters) {
      const parsedFilters = JSON.parse(cachedFilters);
      console.log(parsedFilters, "MODULES LIST PARSED FILTERS");

      this.filtersForm.patchValue(
        {
          ...defaultFilters,
          ...parsedFilters,
        },
        { emitEvent: false }
      );

      if (parsedFilters?.lockerIdentifier?.length) {
        this.filtersForm.patchValue({
          filter: "ALL"
        }, {
          emitEvent: false
        });
        this.filtersForm.get("filter")?.disable({
          emitEvent: false
        });
      } else {
        this.filtersForm.get("filter")?.enable({
          emitEvent: false
        });
      }
    } else {
      this.filtersForm.patchValue(defaultFilters, { emitEvent: false });
    }

    this.filtersForm.valueChanges
      .pipe(
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((value) => {
        if (value.lockerIdentifier?.length) {
          this.filtersForm.patchValue({
            filter: "ALL"
          }, {
            emitEvent: false
          });
          this.filtersForm.get("filter")?.disable({
            emitEvent: false
          });
        } else {
          this.filtersForm.get("filter")?.enable({
            emitEvent: false
          });
        }
        this.saveFilters();
        this.loadModules();
      });

    this.loadModules();
  }

  private saveFilters(): void {
    const filtersValues = this.filtersForm.value;
    const filtersToSave = {
      ...filtersValues,
      interval: filtersValues.interval
        ? filtersValues.interval.map((date: Date) => date?.toISOString())
        : undefined,
    };
    this.session.setVal("admin-modules-list/filters", JSON.stringify(filtersToSave));
  }

  loadModules(): void {
    this.modules.set([]);
    this.fetchingModules.set(true);
    if (isPlatformBrowser(this.platformId)) {
      this.fetchModules();
    }
  }

  openModuleInfo(module: CBox_AdminModuleListItemDataResponse): void {
    const dialog = this.dialog.open(CBoxAdminModuleInfoComponent, {
      data: +module.id,
      minWidth: "min(800px, 100%)",
      autoFocus: false,
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loadModules();
    });
  }

  pageChanged(event: TablePageEvent): void {
    const page = event.first / event.rows;
    this.filtersForm.patchValue({
      page: page + 1,
      pageSize: event.rows
    });
    this.saveFilters();
  }

  createModule(): void {
    const dialog = this.dialog.open(CBoxAdminModuleCreateComponent, {
      minWidth: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loadModules();
    });
  }

  private fetchModules(): void {
    const params = new HttpParams({
      fromObject: this.getSearchStructure(),
    });

    this.api
      .auth()
      .get<CBox_PublicPaginatedResponse<CBox_AdminModuleListItemDataResponse>>("backend/internal/modules/list", params)
      .subscribe(
        (response) => {
          this.modules.set(response.data);
          this.totalCount.set(response.totalCount);
          this.fetchingModules.set(false);
        }, (e: HttpErrorResponse) => {
          this.fetchingModules.set(false);
          handlePublicApiError(e, this.toastService);
        }
      );
  }

  private getSearchStructure(): CBox_AdminModuleListRequestData {
    const values = this.filtersForm.value;

    const params: CBox_AdminModuleListRequestData = {
      filter: values.filter,
      page: values.page,
      pageSize: values.pageSize,
      serial: values.serial,
      lockerIdentifier: values.lockerIdentifier
    };

    Object.keys(params).forEach(
        (key) =>
            (params[key as keyof CBox_AdminModuleListRequestData] == null ||
             params[key as keyof CBox_AdminModuleListRequestData] === '') &&
            delete params[key as keyof CBox_AdminModuleListRequestData]);

    return params;
  }
}