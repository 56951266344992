import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CBox_ActionDeleteAgentResponse } from '@server/services/cbox/public/api/v1/resources/agent/types';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_GetDropOffDataResponse } from '@server/services/cbox/public/api/v1/resources/drop_off/types';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-cbox-profile-drop-off-request-delete-dialog',
  templateUrl: './cbox-profile-drop-off-request-delete-dialog.component.html',
  styleUrls: ["./cbox-profile-drop-off-request-delete-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ButtonModule,
    ProgressSpinnerModule,
    CheckboxModule,
    MessagesModule,
  ]
})

export class CBoxProfileDropOffRequestDeleteDialogComponent implements OnInit {

  deleting = signal(false);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfileDropOffRequestDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public order: CBox_GetDropOffDataResponse) {}

  ngOnInit(): void {}

  deleteOrder(): void {
    this.deleting.set(true);
    this.api.auth()
        .delete<CBox_PublicSuccessResponse<CBox_ActionDeleteAgentResponse>>("backend/request/drop-off?responseId=" + this.order.responseId)
        .subscribe(
            (response) => {
              this.toastService.showSuccessToast("Confirmare", "Comanda a fost ștearsă cu succes!");
              this.dialogRef.close(response.success);
              this.deleting.set(false);
            },
            (e: HttpErrorResponse) => {
              this.deleting.set(false);
              handlePublicApiError(e, this.toastService);
            });
  }
}