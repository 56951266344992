import { CommonModule } from "@angular/common";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Component, Inject, OnInit, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { MessageService } from "primeng/api";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";
import { DividerModule } from "primeng/divider";
import { MessagesModule } from "primeng/messages";
import { TagModule } from "primeng/tag";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { CBox_GetDropOffDataResponse, CBox_GetDropOffListItemDataResponse } from "@server/services/cbox/public/api/v1/resources/drop_off/types";
import { TimelineModule } from "primeng/timeline";
import { TableModule } from "primeng/table";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { copyString } from "src/helpers/functions";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CBoxProfileDropOffRequestDeleteDialogComponent } from "../delete/cbox-profile-drop-off-request-delete-dialog.component";
import { first } from "rxjs";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { PermissionNamesEnum } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { ToastService } from "src/services/toast/toast.service";
import { ProgressSpinnerModule } from "primeng/progressspinner";

@Component({
  selector: "app-cbox-profile-dropoff-request-info",
  templateUrl: "./cbox-profile-dropoff-request-info.component.html",
  styleUrls: ["./cbox-profile-dropoff-request-info.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TagModule,
    AvatarModule,
    TableModule,
    TimelineModule,
    DividerModule,
    MessagesModule,
    MatDialogContent,
    MatDialogClose,
    MatTooltipModule,
    ProgressSpinnerModule
  ],
  providers: [MessageService]
})

export class CBoxProfileDropOffRequestInfoComponent implements OnInit {

  orderData = signal<CBox_GetDropOffDataResponse | undefined>(undefined);
  fetching = signal<"fetching" | "success" | "error">("fetching");
  fetchRetryTimer = 0;
  fetchRetryInterval?: NodeJS.Timeout;

  awbCopied = signal<boolean>(false);
  awbCopyTimeout?: NodeJS.Timeout;

  publicLinkCopied = signal<boolean>(false);
  publicLinkCopyTimeout?: NodeJS.Timeout;

  sendingEmail = signal(false);
  sendingSMS = signal(false);
  showCode = signal(false);
  showCodeTimeout?: NodeJS.Timeout;

  constructor(
    private messageService: MessageService,
    private toastService: ToastService,
    private api: ApiService,
    private dialog: MatDialog,
    private configuration: ConfigurationService,
    private dialogRef: MatDialogRef<CBoxProfileDropOffRequestInfoComponent>,
    @Inject(MAT_DIALOG_DATA) private dropOffOrder: CBox_GetDropOffListItemDataResponse
  ) {}

  ngOnInit(): void {
    this.fetchDropoffOrderInfo();
  }

  async fetchDropoffOrderInfo(withCode = false): Promise<void> {
    let params = new HttpParams();
    params = params.append("responseId", this.dropOffOrder.responseId);
    if (this.configuration.hasAccess([PermissionNamesEnum.viewDropOffOpenCode]) && withCode) {
      params = params.append("includeCode", "true");
    }
    this.api.auth().get<CBox_PublicSuccessResponse<CBox_GetDropOffDataResponse>>("backend/request/drop-off", params).subscribe((response) => {
      this.orderData.set(response.data);
      this.fetching.set("success");
      if (withCode && !response.data.openCode) {
        this.toastService.showErrorToast("Eroare", "Codul de acces nu a putut fi preluat");
        this.showCode.set(false);
        clearTimeout(this.showCodeTimeout);
      }
    }, (e: HttpErrorResponse) => {
      this.fetching.set("error");
      handlePublicApiError(e, this.toastService);
    });
  }

  confirmOrderDelete(): void {
    const dialog = this.dialog.open(CBoxProfileDropOffRequestDeleteDialogComponent, {
      data: this.orderData(),
      width: "min(400px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe((deleted: boolean) => {
      if (deleted) {
        this.dialogRef.close({
          orderDeleted: true
        });
      }
    });
  }

  hasActiveFlags(): boolean {
    if (!this.orderData()?.flags) {
      return false;
    }
    const flagValues = Object.values(this.orderData()?.flags!);
    return flagValues.some(flag => flag === true);
  }

  splitted(value: string | undefined): string[] {
    if (!value) {
      return [];
    }

    return value.split("");
  }

  copyAwb(): void {
    if (this.awbCopyTimeout) {
      clearTimeout(this.awbCopyTimeout);
    }

    copyString(this.dropOffOrder.awb);
    this.awbCopied.set(true);
    this.awbCopyTimeout = setTimeout(() => {
      this.awbCopied.set(false);
    }, 500);
  }

  copyPublicLink(): void {
    if (this.publicLinkCopyTimeout) {
      clearTimeout(this.publicLinkCopyTimeout);
    }

    const link = "https://cbox.ro/" + this.orderData()?.publicReference;
    copyString(link);
    this.publicLinkCopied.set(true);
    this.publicLinkCopyTimeout = setTimeout(() => {
      this.publicLinkCopied.set(false);
    }, 500);
  }

  retryFetch(): void {
    this.fetchDropoffOrderInfo();
    if (this.fetchRetryInterval) {
      clearTimeout(this.fetchRetryInterval);
    }

    let retryAgainAfter = 10; // seconds
    this.fetchRetryTimer = retryAgainAfter;

    this.fetchRetryInterval = setInterval(() => {
      this.fetchRetryTimer = this.fetchRetryTimer - 1;
      if (this.fetchRetryTimer === 0) {
        clearInterval(this.fetchRetryInterval);
        this.fetchRetryInterval = undefined;
      }
    }, 1000);
  }

  toggleCode(): void {
    if (this.showCode()) {
      return;
    }
    this.showCode.set(true);
    this.showCodeTimeout = setTimeout(() => {
      this.showCode.set(false);
    }, 20000);
  }

  resendEmail(): void {
    this.sendingEmail.set(true);
    this.api.auth().patch("backend/request/drop-off", {
      notify: {
        email: {
          resend: true
        }
      }
    }).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "Email-ul a fost retrimis cu succes!");
      this.fetchDropoffOrderInfo();
      this.sendingEmail.set(false);
    }, (e: HttpErrorResponse) => {
      this.sendingEmail.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  resendSMS(): void {
    this.sendingEmail.set(true);
    this.api.auth().patch("backend/request/drop-off", {
      notify: {
        sms: {
          resend: true
        }
      }
    }).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "SMS-ul a fost retrimis cu succes!");
      this.fetchDropoffOrderInfo();
      this.sendingSMS.set(false);
    }, (e: HttpErrorResponse) => {
      this.sendingSMS.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }
}

