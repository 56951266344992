import { Routes } from "@angular/router";
import { PermissionGuardService } from "src/guards/permission.guard";
import { ProfileContainerComponent } from "./profile/cbox-profile-container.component";
import { ProfileLockersListComponent } from "./profile/lockers/list/profile-lockers-list.component";
import { CBoxProfileDropoffRequestsListComponent } from "./profile/requests/drop-off/list/cbox-profile-dropoff-requests-list.component";
import { CBoxProfilePickupRequestsListComponent } from "./profile/requests/pickup/list/cbox-profile-pickup-requests-list.component";
import { CBoxProfileAgentListComponent } from "./profile/agents/list/cbox-profile-agent-list.component";
import { LoginComponent } from "./login/login.component";
import { CBoxProfileUsersListContainerComponent } from "./profile/user/list/cbox-profile-users-list-container.component";
import { CBoxProfileMeComponent } from "./profile/user/me/cbox-profile-me.component";
import { CBoxPublicPasswordResetComponent } from "./password-reset/cbox-public-password-reset.component";
import { CBoxProfileMeGeneralComponent } from "./profile/user/me/general/cbox-profile-me-general.component";
import { CBoxProfileMeSecurityComponent } from "./profile/user/me/security/cbox-profile-me-security.component";
import { CBoxProfileApiUsersListComponent } from "./profile/user/list/api/cbox-profile-api-users-list.component";
import { CBoxProfilePlatformUsersListComponent } from "./profile/user/list/platform/cbox-profile-platform-users-list.component";
import { CBoxOpenPageContainerComponent } from "./open/cbox-open-page-container.component";
import { CBoxProfileAgentsReportsListComponent } from "./profile/reports/agents/cbox-profile-agents-reports-list.component";
import { CBoxProfileReportsContainerComponent } from "./profile/reports/cbox-profile-reports-container.component";
import { ConfigurationGuard } from "src/guards/logged.guard";
import { CBoxAdminEquipmentsListComponent } from "./profile/admin/components/list/cbox-admin-equipments-list.component";
import { CBoxAdminLockersListComponent } from "./profile/admin/lockers/list/cbox-admin-lockers-list.component";
import { CBoxAdminLockerModulesListComponent } from "./profile/admin/modules/list/cbox-admin-locker-modules-list.component";
import { CBoxProfileAdminContainerComponent } from "./profile/admin/cbox-profile-admin-container.component";
import { CBoxProfileZonesListComponent } from "./profile/zones/list/cbox-profile-zones-list.component";
import { PermissionNamesEnum } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { CBoxPublicAccountCreateComponent } from "./account-create/cbox-public-account-create.component";

export const routes: Routes = [
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "profile",
    component: ProfileContainerComponent,
    canActivate: [ConfigurationGuard],
    children: [
      {
        path: "lockers/list",
        component: ProfileLockersListComponent,
        canActivate: [PermissionGuardService],
        data: {
          permissions: [
            PermissionNamesEnum.viewLockersList,
            PermissionNamesEnum.viewLockerDetails
          ]
        }
      },
      {
        path: "users",
        component: CBoxProfileUsersListContainerComponent,
        children: [
          {
            path: "internal",
            component: CBoxProfilePlatformUsersListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.viewPlatformUsers,
                PermissionNamesEnum.editPlatformUser,
                PermissionNamesEnum.resetPlatformUserPassword,
                PermissionNamesEnum.createPlatformUser
              ]
            },
          },
          {
            path: "api",
            component: CBoxProfileApiUsersListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.viewApiUsers,
                PermissionNamesEnum.editApiUser,
                PermissionNamesEnum.createApiUser,
                PermissionNamesEnum.deleteApiUser,
                PermissionNamesEnum.regenerateApiUserCredentials
              ]
            },
          }
        ]
      },
      {
        path: "me",
        component: CBoxProfileMeComponent,
        children: [
          {
            path: "general",
            component: CBoxProfileMeGeneralComponent
          },
          {
            path: "security",
            component: CBoxProfileMeSecurityComponent
          }
        ]
      },
      {
        path: "orders/drop-off/list",
        component: CBoxProfileDropoffRequestsListComponent,
        canActivate: [PermissionGuardService],
        data: {
          permissions: [
            PermissionNamesEnum.viewDropOffsList,
            PermissionNamesEnum.viewDropOffDetails,
            PermissionNamesEnum.deleteDropOff
          ]
        }
      },
      {
        path: "orders/pick-up/list",
        component: CBoxProfilePickupRequestsListComponent,
        canActivate: [PermissionGuardService],
        data: {
          permissions: [
            PermissionNamesEnum.viewPickUpsList,
            PermissionNamesEnum.viewPickUpDetails,
            PermissionNamesEnum.createPickUp,
            PermissionNamesEnum.deletePickUp,
          ]
        }
      },
      {
        path: "agents/list",
        component: CBoxProfileAgentListComponent,
        canActivate: [PermissionGuardService],
        data: {
          permissions: [
            PermissionNamesEnum.viewAgentsList,
            PermissionNamesEnum.createAgent,
            PermissionNamesEnum.editAgent,
            PermissionNamesEnum.deleteAgent,
            PermissionNamesEnum.revokeAgentAccess,
            PermissionNamesEnum.regenerateAgentCredentials
          ]
        }
      },
      {
        path: "admin",
        component: CBoxProfileAdminContainerComponent,
        children: [
          {
            path: "equipments",
            component: CBoxAdminEquipmentsListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.systemAdminAll
              ]
            }
          },
          {
            path: "lockers",
            component: CBoxAdminLockersListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.systemAdminAll
              ]
            }
          },
          {
            path: "modules",
            component: CBoxAdminLockerModulesListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.systemAdminAll
              ]
            }
          },
        ]
      },
      {
        path: "reports",
        component: CBoxProfileReportsContainerComponent,
        children: [
          {
            path: "agents",
            component: CBoxProfileAgentsReportsListComponent
          }
          // {
          //   path: "lockers",
          //   component: CBoxProfileLockersReportsListComponent
          // },
        ]
      },
      {
        path: "zones",
        component: CBoxProfileZonesListComponent,
        canActivate: [PermissionGuardService],
        data: {
          permissions: [
            PermissionNamesEnum.viewZonesList,
            PermissionNamesEnum.createZone
          ]
        }
      }
    ]
  },
  {
    path: "c/:identifier",
    component: CBoxOpenPageContainerComponent
  },
  {
    path: "account/password-reset/:token",
    component: CBoxPublicPasswordResetComponent
  },
  {
    path: "account/create/:token",
    component: CBoxPublicAccountCreateComponent
  },
  {
    path: "",
    redirectTo: "profile",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "profile"
  }
];
