import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { CBox_GetLockerBaseData } from "@server/services/cbox/public/api/v1/resources/locker/types";

export function getLastActiveMessage(lastUsedAt: string): string {
  const lastUsedDate = new Date(lastUsedAt);
  const now = new Date();

  const timeDifferenceMs = now.getTime() - lastUsedDate.getTime();

  const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);

  if (timeDifferenceHours < 1) {
    return "activ in ultima oră";
  } else if (timeDifferenceHours < 6) {
    return "activ in ultimele 6 ore";
  } else if (timeDifferenceHours < 24) {
    return "activ ultima data astazi";
  } else if (timeDifferenceHours < 24 * 7) {
    return "activ in ultima saptamana";
  } else if (timeDifferenceHours > 24 * 7 && timeDifferenceHours < 24 * 30) {
    return "activ in ultima luna";
  } else if (timeDifferenceHours > 24 * 30) {
    return "peste o luna de inactivitate";
  }

  return "necunoscut";
}

export function lockerCleanAddress(address: CBox_GetLockerBaseData["addressParts"]): string {
  let a = `${address.countyName}, ${address.localityName}, str. ${
      address.street} nr. ${address.streetNumber}`;
  if (address.restOfAddress && address.restOfAddress.length) {
    a += `, ${address.restOfAddress}`;
  }
  return a;
}

export function isObject(value: any): boolean {
  var type = typeof value;
  return value != null && (type == 'object' || type == 'function');
}

export function markFormDirty(form?: FormGroup): void {
  if (!form) {
    return;
  }
  markGroupDirty(form);
}

function markGroupDirty(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach((key) => {
    switch (formGroup.get(key)?.constructor.name) {
      case "FormGroup":
        markGroupDirty(formGroup.get(key) as FormGroup);
        break;
      case "FormArray":
        markArrayDirty(formGroup.get(key) as FormArray);
        break;
      case "FormControl2":
        markControlDirty(formGroup.get(key) as FormControl);
        break;
    }
  });
}

function markArrayDirty(formArray: FormArray) {
  formArray.controls.forEach((control) => {
    switch (control.constructor.name) {
      case "FormGroup":
        markGroupDirty(control as FormGroup);
        break;
      case "FormArray":
        markArrayDirty(control as FormArray);
        break;
      case "FormControl2":
        markControlDirty(control as FormControl);
        break;
    }
  });
}

function markControlDirty(formControl: FormControl) {
  formControl.markAsDirty();
}

export function copyString(string: string) {
  const selBox = document.createElement("textarea");
  selBox.style.position = "fixed";
  selBox.style.left = "0";
  selBox.style.top = "0";
  selBox.style.opacity = "0";
  selBox.value = string;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand("copy");
  document.body.removeChild(selBox);
}