import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_GetLockerShortData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { CBox_ActionUpdateZoneRequestData, CBox_GetZoneDataResponse } from "@server/services/cbox/public/api/v1/resources/zone/types";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelect, MultiSelectModule } from "primeng/multiselect";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { LockersService } from "src/services/lockers/lockers.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-profile-zone-update-dialog",
  templateUrl: "./cbox-profile-zone-update-dialog.component.html",
  styleUrls: ["./cbox-profile-zone-update-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    ReactiveFormsModule,
    MultiSelectModule,
    CheckboxModule,
    ProgressSpinnerModule
  ]
})

export class CBoxProfileZoneUpdateDialogComponent implements OnInit {

  zoneForm: FormGroup;
  zoneData = signal<CBox_GetZoneDataResponse | undefined>(undefined);
  fetching = signal(true);
  zones = signal<Record<number, { id: string; name: string }>[]>([]);
  groupedLockers = signal<Record<CBox_GetLockerShortData["id"], CBox_GetLockerShortData[]>>({});
  lockers = signal<CBox_GetLockerShortData[]>([]);

  @ViewChild("lockersMultiSelect") lockersMultiSelect?: MultiSelect;

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private fb: FormBuilder,
    private lockersService: LockersService,
    private dialogRef: MatDialogRef<CBoxProfileZoneUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public zoneId: number,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.zoneForm = this.fb.group({
        name: ["", Validators.required],
        lockers: [[]],
        hasAccessToAllParentLockers: [false]
      });
    }

  ngOnInit() {
    this.init();
  }

  allLockersToggled(): void {
    const f = this.zoneForm;
    const hasAccessToAllParentLockers = f.value.hasAccessToAllParentLockers;
    const lockers = f.get("lockers");
    if (hasAccessToAllParentLockers) {
      lockers?.disable();
      lockers?.patchValue([], {
        emitEvent: false
      })
    } else {
      lockers?.enable();
    }
  }

  update(): void {
    this.api.auth().patch("backend/zones", this.getUpdateStructure()).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "Zona a fost actualizată cu succes!");
      this.dialogRef.close();
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
    })
  }

  lockerSelected(): void {
    const lockers = this.zoneForm.get("lockers");
    const hasAccessToAllParentLockers = this.zoneForm.get("hasAccessToAllParentLockers");
    const parentZone = this.zoneData()?.parent?.id;
    const allLockersSelected = this.groupedLockers()[parentZone as number].length === lockers?.value.length;
    hasAccessToAllParentLockers?.patchValue(allLockersSelected, {
      emitEvent: false
    });
    this.allLockersToggled();
    if (allLockersSelected) {
      this.lockersMultiSelect?.hide();
    }
  }

  private async init(): Promise<void> {
    try {
      const lockers = await this.lockersService.getList();
      let groupedLockers: Record<CBox_GetLockerShortData["id"], CBox_GetLockerShortData[]> = {};
      const zones: CBox_GetLockerShortData["zones"] = [];
        for (const locker of lockers) {
          for (const zone of locker?.zones!) {
            if (!groupedLockers[+zone.id]) {
              groupedLockers[+zone.id] = [];
            }
            groupedLockers[+zone.id].push({id: locker.id, name: locker.name});
            if (!zones.find(z => +z.id === zone.id)) {
              zones.push({id: zone.id, name: zone.name});
            }
          }
        }
        this.zones.set(zones);
        this.groupedLockers.set(groupedLockers);
        if (isPlatformBrowser(this.platformId)) {
          this.fetchZoneData();
        }
    } catch (e) {
      this.dialogRef.close();
      this.toastService.showErrorToast("Eroare", "A apărut o eroare. Vă rugăm reincercati!");
    }
  }

  private fetchZoneData(): void {
    this.api.auth().get<CBox_PublicSuccessResponse<CBox_GetZoneDataResponse>>("backend/zones/" + this.zoneId).subscribe((response) => {
      this.zoneData.set(response.data);
      const hasAccessToAllParentLockers =
          this.groupedLockers()?.[response.data.id].length ===
          this.groupedLockers()?.[response.data.parent?.id as number]?.length;
      this.zoneForm.patchValue({
        name: response.data.name,
        lockers: this.groupedLockers()[response.data.id].map((locker) => locker.id),
        hasAccessToAllParentLockers: hasAccessToAllParentLockers
      });
      this.lockers.set(this.groupedLockers()[response.data.parent?.id as number]);
      this.allLockersToggled();
      this.fetching.set(false);
    }, (e: HttpErrorResponse) => {
      this.dialogRef.close();
      this.toastService.showErrorToast("Eroare", "A apărut o eroare la furnizarea datelor zonei selectate!")
      handlePublicApiError(e, this.toastService);
    });
  }

  private getUpdateStructure(): CBox_ActionUpdateZoneRequestData["body"] {
    const data: CBox_ActionUpdateZoneRequestData["body"] = {
      description: this.zoneForm.value.description,
      hasAccessToAllParentLockers: this.zoneForm.value.hasAccessToAllParentLockers,
      name: this.zoneForm.value.name,
      lockers: this.zoneForm.value.lockers,
    };

    return data;
  }
}