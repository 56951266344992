import { CommonModule } from "@angular/common";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Component, Inject, OnInit, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogClose, MatDialogContent } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_EquipmentType } from "@server/services/cbox/public/api/v1/resources/internal/equipment/types";
import { CBox_AdminLockerModuleDataResponse } from "@server/services/cbox/public/api/v1/resources/internal/locker_module/types";
import { CBoxAdminLinkNecesarryEquipmentToLockerModuleDialogComponent } from "app/profile/admin/components/dialogs/link-to-locker/cbox-admin-link-necesarry-equipment-to-locker-module-dialog.component";
import { ButtonModule } from "primeng/button";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TableModule } from "primeng/table";
import { first } from "rxjs";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-admin-module-info",
  templateUrl: "./cbox-admin-module-info.component.html",
  styleUrls: ["./cbox-admin-module-info.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogContent,
    MatDialogClose,
    ProgressSpinnerModule,
    TableModule,
    MatTooltipModule
  ]
})

export class CBoxAdminModuleInfoComponent implements OnInit {

  moduleData = signal<CBox_AdminLockerModuleDataResponse | undefined>(undefined);
  loading = signal(true);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private moduleId: number) {}

  ngOnInit(): void {
    this.fetchModuleData();
  }

  linkNecesarryEquipment(equipmentType: CBox_EquipmentType): void {
    const dialog = this.dialog.open(CBoxAdminLinkNecesarryEquipmentToLockerModuleDialogComponent, {
      data: {
        equipmentType,
        module: {
          id: this.moduleData()?.id,
          serial: this.moduleData()?.serial
        }
      },
      width: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loading.set(true);
      this.fetchModuleData();
    });
  }

  private fetchModuleData(): void {
    this.api.auth().get<CBox_PublicSuccessResponse<CBox_AdminLockerModuleDataResponse>>("backend/internal/modules?moduleId=" + this.moduleId).subscribe((response) => {
      this.moduleData.set(response.data);
      this.loading.set(false);
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }
}