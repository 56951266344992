<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Modificare număr de telefon</h2>
  <p-button
    mat-dialog-close
    size="small"
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content class="pb-2">
  <p>Introduceți noul număr în câmpul de mai jos.</p>
  <span class="font-bold block mb-1 mt-4">Numar de telefon</span>
  <p-iconField class="w-full" iconPosition="right">
    @if (phoneNumber.value?.length! > 0) {
      <p-inputIcon
        styleClass="pi pi-times cursor-pointer"
        (click)="phoneNumber.reset()"
      />
    }
    <input
      pInputText
      class="w-full"
      placeholder="07xxxxxxxx"
      [formControl]="phoneNumber"
    />
  </p-iconField>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex align-items-center gap-2">
    <p-button
      label="Anulează"
      size="small"
      [text]="true"
      mat-dialog-close
    ></p-button>

    <p-button
      label="Salveaza"
      size="small"
      icon="pi pi-check"
      iconPos="left"
      (click)="changePhoneNumber()"
    ></p-button>
  </div>
</mat-dialog-actions>
@if (changingPhone()) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}