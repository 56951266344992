
<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Adaugă PickUp</h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content class="pb-2">
  <form class="formgrid grid relative" [formGroup]="form">
    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">AWB*</span>
      <input
        id="awb"
        class="w-full"
        formControlName="awb"
        pInputText
        pAutoFocus
        [autofocus]="true"
        [ngClass]="{
          'ng-invalid': form.get('awb')?.invalid,
          'ng-dirty': form.get('awb')?.invalid,
          'ng-touched': form.touched
        }"
      >
      @let awb = form.get("awb");
      @if (awb?.dirty) {
        @if (awb?.errors?.["maxlength"]) {
          @let maxAwbLength = awb?.errors?.["maxlength"]?.requiredLength;
          <small class="text-red-500">Câmpul poate conține maxim {{ maxAwbLength }} caractere</small>
        } @else if (awb?.errors?.["required"]) {
          <small class="text-red-500">Câmpul este obligatoriu</small>
        }
      }
    </div>

    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Referință comandă <small>(optional)</small></span>
      <input
        formControlName="uniqueReference"
        class="w-full"
        id="awb"
        placeholder="#123"
        pInputText
      >
      @let reference = form.get("uniqueReference");
      @if (reference?.dirty) {
        @if (reference?.errors?.["maxlength"]) {
          @let maxReferenceLength = reference?.errors?.["maxlength"]?.requiredLength;
          <small class="text-red-500">Câmpul poate conține maxim {{ maxReferenceLength }} caractere</small>
        } @else if (reference?.errors?.["minlength"]) {
          @let minReferenceLength = reference?.errors?.["minlength"]?.requiredLength;
          <small class="text-red-500">Câmpul trebuie sa contina minim {{ minReferenceLength }} caractere</small>
        } @else if (reference?.errors?.["required"]) {
          <small class="text-red-500">Câmpul este obligatoriu</small>
        }
      }
    </div>

    <div class="col-12 sm:col-6 mt-2">
      <span class="font-bold block mb-1">Nume expeditor</span>
      <input
        formControlName="senderName"
        class="w-full"
        id="awb"
        placeholder="Expeditor"
        pInputText
      >
    </div>

    <div class="col-12 sm:col-6 mt-2">
      <span class="font-bold block mb-1">Nume destinatar</span>
      <input
        formControlName="recipientName"
        class="w-full"
        id="awb"
        placeholder="Destinatar"
        pInputText
      >
    </div>

    <div class="col-12 mt-2">
      <span class="font-bold block mb-1">Locker*</span>
      <p-dropdown
        id="locker"
        styleClass="w-full"
        placeholder="Selectează un locker"
        optionLabel="name"
        formControlName="locker"
        filterBy="name"
        [options]="lockers()"
        [loading]="fetchingLockers()"
        [filter]="true"
        [showClear]="true"
        [ngClass]="{
          'ng-invalid': form.get('locker')?.invalid,
          'ng-dirty': form.get('locker')?.invalid
        }"
      >
        <ng-template let-locker pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ locker?.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      @let locker = form.get("locker");
      @if (locker?.dirty) {
        @if (locker?.errors) {
          <small class="text-red-500">Câmpul este obligatoriu</small>
        }
      }
    </div>

    <h2 class="col-12">Dimensiuni colet</h2>
    @if (fetchingPredefinedParcels()) {
      <div class="grid pr-0 col-12 mb-2">
        <div class="col-12 sm:col-4">
          <p-skeleton height="2.5rem" styleClass="w-full"></p-skeleton>
        </div>

        <div class="col-12 sm:col-4">
          <p-skeleton height="2.5rem" styleClass="w-full"></p-skeleton>
        </div>

        <div class="col-12 sm:col-4">
          <p-skeleton height="2.5rem" styleClass="w-full"></p-skeleton>
        </div>
      </div>
    } @else {
      <div class="grid pr-0 col-12 mb-2">
        @for (parcel of predefinedParcels(); track $index) {
          <div class="col-12 sm:col-4">
            <p-button
              styleClass="w-full py-1 px-2"
              [severity]="selectedParcel() === parcel ? 'success' : 'secondary'"
              outlined="true"
              (click)="selectPredefinedParcel(parcel)"
            >
              <div class="flex gap-2 align-items-center">
                @if (selectedParcel() === parcel) {
                  <span class="pi pi-check"></span>
                } @else {
                  <span class="pi pi-box"></span>
                }
                <div class="flex flex-column align-items-start text-truncate w-full">
                  <p
                    class="m-0 text-xs font-bold flex-1"
                  >{{ parcel.name }}</p>
                  <p class="m-0 text-gray-400 text-xs">
                    {{ parcel.length }}x{{ parcel.width }}x{{ parcel.height }} cm
                  </p>
                </div>
              </div>
            </p-button>
          </div>
        }
      </div>
    }
    <form [formGroup]="packageForm" class="col-12 contents">
      <div class="col-6 sm:col-3">
        <span class="font-bold block mb-1">Lungime*</span>
        <p-inputGroup>
          <input
            class="no-controls-number-input"
            formControlName="length"
            pKeyFilter="money"
            type="number"
            id="length"
            pInputText
          />
          <p-inputGroupAddon>
            cm
          </p-inputGroupAddon>
        </p-inputGroup>
        @let length = form.get("parcel")?.get("length");
        @if (length?.dirty) {
          @if (length?.errors?.["required"]) {
            <small class="text-red-500">Câmpul este obligatoriu</small>
          } @else if (length?.errors?.["min"]) {
            <small class="text-red-500">Valoarea trebuie sa fie mai mare decat 0</small>
          }
        }
      </div>

      <div class="col-6 sm:col-3">
        <span class="font-bold block mb-1">Lățime*</span>
        <p-inputGroup>
          <input
            class="no-controls-number-input"
            formControlName="width"
            pKeyFilter="money"
            type="number"
            id="width"
            pInputText
          />
          <p-inputGroupAddon>
            cm
          </p-inputGroupAddon>
        </p-inputGroup>
        @let width = form.get("parcel")?.get("width");
        @if (width?.dirty) {
          @if (width?.errors?.["required"]) {
            <small class="text-red-500">Câmpul este obligatoriu</small>
          } @else if (width?.errors?.["min"]) {
            <small class="text-red-500">Valoarea trebuie să fie mai mare decât 0</small>
          }
        }
      </div>

      <div class="col-6 sm:col-3">
        <span class="font-bold block mb-1">Înălțime*</span>
        <p-inputGroup>
          <input
            class="no-controls-number-input"
            formControlName="height"
            pKeyFilter="money"
            type="number"
            id="height"
            pInputText
          />
          <p-inputGroupAddon>
            cm
          </p-inputGroupAddon>
        </p-inputGroup>
        @let height = form.get("parcel")?.get("height");
        @if (height?.dirty) {
          @if (height?.errors?.["required"]) {
            <small class="text-red-500">Câmpul este obligatoriu</small>
          } @else if (height?.errors?.["min"]) {
            <small class="text-red-500">Valoarea trebuie să fie mai mare decât 0</small>
          }
        }
      </div>

      <div class="col-6 sm:col-3">
        <span class="font-bold block mb-1">Greutate</span>
        <p-inputGroup>
          <input
            formControlName="weight"
            pKeyFilter="money"
            id="weight"
            pInputText
            type="number"
          />
          <p-inputGroupAddon>
            kg
          </p-inputGroupAddon>
        </p-inputGroup>
      </div>
    </form>

    <h2 class="col-12">Opțiuni extra</h2>
    <div [formGroup]="reservationForm" class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Rezervă o ușă</span>
      <p-inputGroup>
        <p-inputGroupAddon>
          <p-checkbox
            formControlName="enabled"
            [binary]="true"
            (onChange)="boxReservationToggled($event.checked)"
          />
        </p-inputGroupAddon>
        <input
          formControlName="offset"
          pKeyFilter="money"
          type="number"
          id="valability"
          inputId="horizontal"
          spinnerMode="horizontal"
          pInputText
          [pTooltip]="reservationForm.get('enabled')?.value === false ? 'Activați opțiunea folosind căsuța din partea stangă' : ''"
        />
        <p-inputGroupAddon>
          ore
        </p-inputGroupAddon>
      </p-inputGroup>
      @let reservationEnabled = reservationForm.get("enabled")?.value;
      @let reservationField = reservationForm.get("offset");
      @let reservationOffset = reservationForm.get("offset")?.value;
      @let reservationErrors = reservationForm.get("offset")?.errors;
      @if (reservationEnabled) {
        @if (reservationErrors && reservationField?.dirty) {
          @if (reservationErrors["required"]) {
            <small class="text-red-500">Câmpul este obligatoriu</small>
          } @else if (reservationErrors["min"]) {
            @let min = reservationErrors["min"];
            <small class="text-red-500">Numarul minim de ore este {{ min.min }}</small>
          } @else if (reservationErrors["max"]) {
            @let max = reservationErrors["max"];
            <small class="text-red-500">Numarul maxim de ore este {{ max.max }}</small>
          }
        } @else {
          @if (reservationOffset) {
            <small>Rezervare pana la data de
              <b>
                {{ getDateWithOffset(reservationOffset) | date: "dd MMM" }}
              </b>
              ora
              <b>
                {{ getDateWithOffset(reservationOffset) | date: "HH:mm" }}
              </b>
            </small>
          } @else {
            <small>Cât timp vom rezerva ușa pentru comanda dumneavoastră</small>
          }
        }
      } @else {
        <small>Cât timp vom rezerva ușa pentru comanda dumneavoastră</small>
      }
    </div>

    <div [formGroup]="valabilityForm" class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Valabilitate comandă</span>
      <p-inputGroup>
        <input
          formControlName="offset"
          pKeyFilter="money"
          type="number"
          id="valability"
          inputId="horizontal"
          spinnerMode="horizontal"
          pInputText
          [min]="0"
          [max]="96"
          [step]="2"
        />
        <p-inputGroupAddon>
          ore
        </p-inputGroupAddon>
      </p-inputGroup>
      @let valabilityField = valabilityForm.get("offset");
      @if (valabilityField?.["errors"]) {
        @if (valabilityField?.["errors"]?.["max"]) {
          <small class="text-red-500">Numarul maxim de ore este 96</small>
        } @else if (valabilityField?.["errors"]?.["required"]) {
          <small class="text-red-500">Câmpul este obligatoriu</small>
        }
      } @else {
        <small>Cât va sta coletul în locker după livrare</small>
      }
    </div>

    <div [formGroup]="smsNotifyForm" class="col-12 sm:col-6 mt-2">
      <span class="font-bold block mb-1">Notificare prin SMS</span>
      <div class="p-inputgroup w-full">
        <span class="p-inputgroup-addon">
          <p-checkbox
            [binary]="true"
            formControlName="enabled"
            (onChange)="smsNotifyToggled($event.checked)"
          ></p-checkbox>
        </span>
        <input
          pInputText
          class="w-full"
          placeholder="07xxxxxxxx"
          formControlName="phoneNumber"
          [pTooltip]="smsNotifyForm.get('enabled')?.value === false ? 'Activați opțiunea folosind căsuța din partea stangă' : ''"
        >
      </div>
    </div>

    <div [formGroup]="emailNotifyForm" class="col-12 sm:col-6 mt-2">
      <span class="font-bold block mb-1">Notificare prin email</span>
      <div class="p-inputgroup w-full">
        <span class="p-inputgroup-addon">
          <p-checkbox
            [binary]="true"
            formControlName="enabled"
            (onChange)="emailNotifyToggled($event.checked)"
          ></p-checkbox>
        </span>
        <input
          type="text"
          pInputText
          placeholder="test@companie.ro"
          formControlName="email"
          [pTooltip]="emailNotifyForm.get('enabled')?.value === false ? 'Activați opțiunea folosind căsuța din partea stangă' : ''"
        >
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex align-items-center gap-2">
    <button
      pButton
      label="Renunță"
      [text]="true"
      mat-dialog-close
    ></button>
    <button
      pButton
      label="Creează PickUp"
      icon="pi pi-plus"
      iconPos="right"
      (click)="placeOrder()"
    ></button>
  </div>
</mat-dialog-actions>
@if (placingOrder()) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}
