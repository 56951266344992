import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, DestroyRef, Inject, InjectionToken, OnInit, PLATFORM_ID, computed, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { CBox_PublicPaginatedResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_GetUserListRequestParams, CBox_GetUserListResponseItem } from "@server/services/cbox/public/api/v1/resources/user/types";
import { MenuItem, MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { TableModule, TablePageEvent } from "primeng/table";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ProfileService } from "src/services/profile/profile.service";
import { SessionService } from "src/services/session/session.service";
import { CBoxProfilePlatformUserCreateComponent } from "./dialogs/create/cbox-profile-platform-user-create.component";
import { MatDialog } from "@angular/material/dialog";
import { debounceTime, first } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ProgressBarModule } from "primeng/progressbar";
import { MenuModule } from "primeng/menu";
import { CBoxProfilePlatformUserUpdateComponent } from "./dialogs/update/cbox-profile-platform-user-update.component";
import { ToastService } from "src/services/toast/toast.service";
import { CBoxProfilePlatformUserPasswordResetConfirmDialogComponent } from "./dialogs/password-reset-confirm/cbox-profile-platform-user-password-reset-confirm-dialog.component";
import { PermissionNamesEnum } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { PermissionDirective } from "src/directives/permission.directive";
import { CBoxProfilePlatformUserDeleteConfirmDialogComponent } from "./dialogs/delete/cbox-profile-platform-user-delete-confirm-dialog.component";
import { IconFieldModule } from "primeng/iconfield";
import { InputIconModule } from "primeng/inputicon";
import { UserService } from "src/services/user/user.service";

@Component({
  selector: "app-cbox-profile-platform-users-list",
  templateUrl: "cbox-profile-platform-users-list.component.html",
  styleUrls: ["./cbox-profile-platform-users-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    ProgressBarModule,
    MenuModule,
    PermissionDirective,
    IconFieldModule,
    InputIconModule
  ]
})

export class CBoxProfilePlatformUsersListComponent {

  filtersForm: FormGroup;
  users = signal<CBox_GetUserListResponseItem[]>([]);
  usersFetchStatus = signal<"fetching" | "success" | "error">("fetching");
  selectedUser = signal<CBox_GetUserListResponseItem | undefined>(undefined);
  hasPermission = (permission: string) => computed(() => {
    return this.configuration.hasAccess([permission]);
  });
  userMenuItems = computed<MenuItem[]>(() => [
    {
      label: "Resetează parolă",
      icon: "pi pi-refresh",
      visible: this.hasPermission(PermissionNamesEnum.resetPlatformUserPassword)(),
      command: () => {
        this.resetUserPassword(this.selectedUser());
      }
    },
    {
      label: "Editează utilizator",
      icon: "pi pi-pencil",
      visible: this.hasPermission(PermissionNamesEnum.editPlatformUser)(),
      command: () => {
        this.updateUser(this.selectedUser());
      }
    },
    {
      label: "Șterge utilizator",
      icon: "pi pi-trash",
      visible: this.hasPermission(PermissionNamesEnum.deletePlatformUser)(),
      command: () => {
        this.confirmUserDelete(this.selectedUser());
      }
    }
  ]);
  zones = signal<{id: number; name: string}[]>([]);
  permissions = PermissionNamesEnum;
  totalCount = signal(0);
  currentUser = signal<CBox_GetUserListResponseItem | undefined>(undefined);

  constructor(
    private profileService: ProfileService,
    private api: ApiService,
    private fb: FormBuilder,
    private session: SessionService,
    private dialog: MatDialog,
    private destroyRef: DestroyRef,
    private toastService: ToastService,
    private configuration: ConfigurationService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.filtersForm = this.fb.group({
        name: [""],
        email: [""],
        phone: [""],
        page: [1],
        pageSize: [25]
      });
    }

  ngOnInit(): void {
    this.init();
  }

  loadUsers(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.usersFetchStatus.set("fetching");
      this.fetchUsers();
    }
  }

  addUser(): void {
    const dialog = this.dialog.open(CBoxProfilePlatformUserCreateComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe((created: boolean) => {
      if (created) {
        this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost adăugat cu succes.");
        this.loadUsers();
      }
    });
  }

  updateUser(user: CBox_GetUserListResponseItem | undefined): void {
    if (!user) {
      return;
    }
    const dialog = this.dialog.open(CBoxProfilePlatformUserUpdateComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false,
      data: user.identifier
    });

    dialog.afterClosed().pipe(first()).subscribe((edited: boolean) => {
      if (edited) {
        this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost editat cu succes.");
        this.loadUsers();
      }
    });
  }

  resetUserPassword(user: CBox_GetUserListResponseItem | undefined): void {
    if (!user) {
      return;
    }

    this.dialog.open(CBoxProfilePlatformUserPasswordResetConfirmDialogComponent, {
      data: user,
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });
  }

  confirmUserDelete(user: CBox_GetUserListResponseItem | undefined): void {
    if (!user) {
      return;
    }

    const dialog = this.dialog.open(CBoxProfilePlatformUserDeleteConfirmDialogComponent, {
      data: user,
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loadUsers();
    });
  }

  pageChanged(event: TablePageEvent): void {
    const page = event.first / event.rows;
    this.filtersForm.patchValue({
      page: page + 1,
      pageSize: event.rows
    });
    this.saveFilters();
  }

  getZoneName(zoneId: number): string {
    return this.zones().find((zone) => +zone.id === zoneId)?.name || "";
  }

  private async init(): Promise<void> {
    this.profileService.setTitle("Listă utilizatori");
    this.zones.set(await this.configuration.getAllowedZones());
    this.filtersForm.valueChanges.pipe(
      debounceTime(500),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.saveFilters();
      this.loadUsers();
    });

    if (isPlatformBrowser(this.platformId)) {
      this.patchCachedFilters();
      this.loadUsers();
    }
  }

  private fetchUsers(): void {
    this.api.auth().get<CBox_PublicPaginatedResponse<CBox_GetUserListResponseItem>>("backend/users", this.getSearchStructure()).subscribe(async (response) => {
      this.users.set(response.data);
      this.totalCount.set(response.totalCount);
      const currentUserEmail = (await this.userService.getData()).data?.email;
      this.currentUser.set(response.data.find((user) => user.email === currentUserEmail));
      this.usersFetchStatus.set("success");
    }, (e: HttpErrorResponse) => {
      this.usersFetchStatus.set("error");
      handlePublicApiError(e, this.toastService);
    });
  }

  private saveFilters(): void {
    const filtersValues = this.filtersForm.value;
    const sessionStorageFilters = {
      name: filtersValues.name,
      phone: filtersValues.phone,
      email: filtersValues.name,
    };
    this.session.setVal("users-list/filters/session", JSON.stringify(sessionStorageFilters));
  }

  private patchCachedFilters(): void {
    const sessionStorageFilters = this.session.getVal("users-list/filters/session", undefined);
    if (sessionStorageFilters) {
      const parsed = JSON.parse(sessionStorageFilters);
      this.filtersForm.patchValue(parsed, {
        emitEvent: false
      });
    }
  }

  private getSearchStructure(): CBox_GetUserListRequestParams {

    return Object.entries({
      name: this.filtersForm.value.name,
      phone: this.filtersForm.value.phone,
      email: this.filtersForm.value.email
    })
    .filter(([_, value]) => value?.length || !!value)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_GetUserListRequestParams] = value;
      return acc;
    }, {} as any);
  }
}