import { CommonModule } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_GetUserListResponseItem } from "@server/services/cbox/public/api/v1/resources/user/types";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-profile-platform-user-delete-confirm-dialog",
  templateUrl: "cbox-profile-platform-user-delete-confirm-dialog.component.html",
  styleUrls: ["./cbox-profile-platform-user-delete-confirm-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ButtonModule,
    ProgressSpinnerModule,
    CheckboxModule
  ]
})

export class CBoxProfilePlatformUserDeleteConfirmDialogComponent implements OnInit {

  deleting = signal(false);
  confirmed = signal(false);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfilePlatformUserDeleteConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public platformUser: CBox_GetUserListResponseItem) { }

  ngOnInit(): void {}

  deletePlatformUser(): void {
    this.api.auth().delete("backend/users/" + this.platformUser.identifier).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost sters cu succes!");
      this.dialogRef.close(true);
      this.deleting.set(false);
    }, (e: HttpErrorResponse) => {
      this.deleting.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }
}