<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Editare zonă</h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    size="small"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <form [formGroup]="zoneForm" class="grid">
    <div class="col-12">
      <span class="font-bold block mb-1">Nume zonă</span>
      <input
        formControlName="name"
        class="w-full"
        placeholder="Introduceți numele zonei"
        pInputText
        pAutoFocus
        [autofocus]="true"
      >
      @let name = zoneForm.get("name");
      @let nameErrors = zoneForm.get("name")?.errors;
      @if (name?.dirty) {
        @if (nameErrors) {
          @if (nameErrors?.["required"]) {
            <small class="text-red-500">Campul este obligatoriu!</small>
          }
        }
      }
    </div>

    <!-- <div class="col-12">
      <span class="block font-bold mb-1">Zonă părinte</span>
      <p-dropdown
        optionLabel="name"
        optionValue="id"
        appendTo="body"
        placeholder="Selectati zona părinte"
        formControlName="parentZone"
        styleClass="w-full"
        emptyMessage="Nu există zone"
        [options]="zoneData()?.part"
        [loading]="fetchingZoneData()"
      ></p-dropdown>
    </div> -->

    <div class="col-12">
      <span class="font-bold block mb-1">Lockere</span>
      @let lockersField = zoneForm.get("lockers");
      @let lockersErrors = lockersField?.errors;
      <p-multiSelect
        #lockersMultiSelect
        formControlName="lockers"
        optionLabel="name"
        optionValue="id"
        [placeholder]="zoneForm.get('hasAccessToAllParentLockers')?.value ? 'Toate lockerele din zona părinte' : 'Selectează lockere'"
        styleClass="w-full"
        appendTo="body"
        emptyFilterMessage="Nu există lockere"
        [options]="lockers()"
        (onChange)="lockerSelected()"
      />
      @if (lockersField?.dirty) {
        @if (lockersErrors?.["required"]) {
          <small class="text-red-500">Vă rugăm să selectați minim un locker</small>
        }
      }
      <div class="flex align-items-center gap-2 mt-1">
        <p-checkbox
          [binary]="true"
          formControlName="hasAccessToAllParentLockers"
          inputId="allLockers"
          (onChange)="allLockersToggled()"
        ></p-checkbox>
        <label class="text-xs" for="allLockers">Toate lockerele</label>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    pButton
    class="w-full"
    label="Creează zona"
    (click)="update()"
  ></button>
</mat-dialog-actions>
@if (fetching() === true) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}