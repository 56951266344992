import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_AdminEquipmentListItem, CBox_AdminEquipmentListResponse, CBox_EquipmentType } from '@server/services/cbox/public/api/v1/resources/internal/equipment/types';
import { CBox_AdminActionAttachEquipmentToModuleRequestData, CBox_AdminLockerModuleDataResponse, CBox_AdminModuleListItemDataResponse } from '@server/services/cbox/public/api/v1/resources/internal/locker_module/types';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-cbox',
  templateUrl: './cbox-admin-link-necesarry-equipment-to-locker-module-dialog.component.html',
  styleUrls: ["./cbox-admin-link-necesarry-equipment-to-locker-module-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    DropdownModule,
    ButtonModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    InputTextModule,
    InputTextareaModule
  ]
})

export class CBoxAdminLinkNecesarryEquipmentToLockerModuleDialogComponent implements OnInit {

  modules = signal<CBox_AdminModuleListItemDataResponse[] | undefined>(undefined);
  equipmentTypes = signal<string[] | undefined>(undefined);
  equipments = signal<CBox_AdminEquipmentListResponse | undefined>(undefined);
  loading = signal(true);
  form: FormGroup;

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CBoxAdminLinkNecesarryEquipmentToLockerModuleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      equipmentType: CBox_AdminLockerModuleDataResponse["equipmentTypeCounts"][0]["definition"];
      module?: {
        id: string;
        serial: string;
      };
    }) {
      this.form = this.fb.group({
        equipmentType: [{
          value: undefined,
          disabled: true
        }, Validators.required],
        equipment: [undefined],
        actionPerformedBy: ["", Validators.required],
        moduleId: [undefined, Validators.required],
        notes: [""]
      });
      this.equipmentTypes.set([this.data.equipmentType.name]);
      this.form.get("equipmentType")?.patchValue(this.data.equipmentType.name);
    }

  ngOnInit(): void {
    this.init();
  }

  link(): void {
    this.api.auth().post("backend/internal/equipments/attach", this.getLinkStructure()).subscribe((response) => {
      this.toastService.showSuccessToast("Confirmare", "Echipamentul a fost asociat cu succes.");
      this.dialogRef.close();
      this.loading.set(false);
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  private async init(): Promise<void> {
    this.fetchModules();
    this.fetchEquipments();
    this.equipmentTypes.set([this.data.equipmentType.name]);
  }

  private fetchEquipments(): void {
    const params = new HttpParams({
      fromObject: {
        typeId: this.data.equipmentType.typeId,
        includeInstalled: false,
        includeNotInstalled: true
      }
    });
    this.api.auth().get<CBox_PublicSuccessResponse<CBox_AdminEquipmentListItem[]>>("backend/internal/equipments/list", params).subscribe((response) => {
      this.equipments.set(response.data);
      this.loading.set(false);
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  private fetchModules(): void {
    this.api.auth().get<CBox_PublicSuccessResponse<CBox_AdminModuleListItemDataResponse[]>>("backend/internal/modules/list").subscribe((response) => {
      this.modules.set(response.data);
      if (this.data.module?.id) {
        const moduleId = this.form.get("moduleId");
        moduleId?.patchValue(this.data.module?.id);
        moduleId?.disable();
      }
      this.loading.set(false);
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  private getLinkStructure(): CBox_AdminActionAttachEquipmentToModuleRequestData {
    const f = this.form;
    const data = {
      moduleId: f.get("moduleId")?.value,
      equipmentId: f.get("equipment")?.value.id,
      actionPerformedBy: f.get("actionPerformedBy")?.value,
      notes: f.get("notes")?.value
    }

    return data;
  }
}