<div class="dialog-header flex justify-content-between align-items-center w-full">
  <h2>Confirmare</h2>
  <p-button
    size="small"
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <p>Urmează să anulați comanda PickUp cu AWB
    <span class="font-bold">{{ order.awb }}</span>. Continuați?</p>
  @if (order.uniqueReference) {
    <p>Referință unică: {{ order.uniqueReference }}</p>
  }
  @if (order.flags.isInLocker) {
    <p class="text-red-500">
      Atenție! Această comandă este în locker acum. Prin urmare, anularea nu va lăsa clientul să ridice comanda din locker. Când agentul
      va merge la locker va putea prelua comanda.
    </p>
  }
</mat-dialog-content>
<mat-dialog-actions class="flex gap-2">
  <p-button
    size="small"
    mat-dialog-close
    label="Renunță"
    [text]="true"
  ></p-button>
  <p-button
    size="small"
    severity="danger"
    label="Anulează"
    icon="pi pi-trash"
    (onClick)="deleteOrder()"
  ></p-button>
</mat-dialog-actions>
@if (deleting()) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}