import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ToastModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private toastService: ToastService
  ) {
    iconRegistry.addSvgIcon(
      'logo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/logo-black.svg')
    );

    iconRegistry.addSvgIcon(
      'logo-small',
      sanitizer.bypassSecurityTrustResourceUrl('assets/logo-small.svg')
    );

    iconRegistry.addSvgIcon(
      'ro-post-logo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/ro-post-logo.svg')
    );
  }

  ngOnInit(): void {
    this.toastService.showErrorToast("Eroare", "TEST EROARE");
  }

}
