import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';

@Injectable({providedIn: 'root'})
export class CacheService {
  cachedValues: {[key: string]: string} = {};

  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {}

  setVal(key: string, value: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage?.setItem(key, value);
    }
    this.cachedValues[key] = value;
  }

  getVal(key: string, defaultVal?: any): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage?.getItem(key) || this.cachedValues[key] || defaultVal;
    }
    return this.cachedValues[key] || defaultVal;
  }

  removeVal(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
    } else {
      delete this.cachedValues[key];
    }
  }

  getKeys(filter: RegExp) {
    if (isPlatformBrowser(this.platformId)) {
      let n = 0;
      const keys: string[] = [];
      do {
        const nthKey = localStorage?.key(n);
        if (nthKey === null) {
          break;
        }
        if (filter.test(nthKey)) {
          keys.push(nthKey);
        }
      } while (++n < 1000);
      return keys;
    }
    return [];
  }

  clear() {
    localStorage?.clear();
  }
}