<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Adaugă utilizator</h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <form [formGroup]="userForm" class="grid">
    @if (!userForm.get("customIdentifier")?.value) {
      <p-messages
        styleClass="text-xs"
        class="col-12"
        [value]="autoGeneratedIdentifierMessage()"
        [enableService]="false"
        [closable]="false" />
    }
    <div class="col-12 sm:col-6">
      <span class="block font-bold mb-1">Nume</span>
      <input
        autocomplete="off"
        class="w-full"
        type="text"
        pInputText
        placeholder="Nume"
        pAutoFocus
        [autofocus]="true"
        formControlName="name"
      >
    </div>

    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Identificator</span>
      @let identifier = userForm.get("identifier");
      <input
        formControlName="identifier"
        class="w-full"
        id="awb"
        [placeholder]="identifier?.disabled ? 'Se va genera automat' : 'Introduceți identificatorul'"
        pInputText
      >
      @let identifierErrors = userForm.get("identifier")?.errors;
      @if (identifier?.dirty) {
        @if (identifierErrors) {
          @if (identifierErrors?.["required"]) {
            <small class="text-red-500">Identificatorul este obligatoriu!</small>
          }
        }
      }
      <div class="flex align-items-center gap-2">
        <p-checkbox
          class="mt-1"
          [binary]="true"
          formControlName="customIdentifier"
          inputId="customIdentifier"
          (onChange)="toggleCustomIdentifier(); identifier?.reset();"
        ></p-checkbox>
        <label class="-mb-1 text-xs" for="customIdentifier">Identificator personalizat</label>
      </div>
    </div>

    <div class="col-12 sm:col-6">
      <span class="block font-bold mb-1">Email</span>
      <input
        autocomplete="off"
        class="w-full"
        type="text"
        pInputText
        placeholder="Email"
        formControlName="email"
      >
    </div>

    <div class="col-12 sm:col-6">
      <span class="block font-bold mb-1">Telefon</span>
      <input
        autocomplete="off"
        class="w-full"
        type="text"
        pInputText
        placeholder="07xxxxxxxx"
        formControlName="phone"
      >
    </div>

    <div class="col-12">
      <span class="block font-bold mb-1">Rol</span>
      <p-dropdown
        optionLabel="roleName"
        optionValue="roleName"
        styleClass="w-full"
        placeholder="Selectati un rol"
        formControlName="role"
        [options]="roles()"
        [loading]="!roles()"
      ></p-dropdown>
    </div>

    <div class="col-12">
      <span class="font-bold block mb-1">Zonă</span>
      <p-dropdown
        formControlName="zoneId"
        optionLabel="name"
        optionValue="id"
        placeholder="Selectează o zonă"
        styleClass="w-full"
        appendTo="body"
        emptyFilterMessage="Nu există zone"
        [options]="zones()"
        [loading]="!zonesFetched()"
      />
      @let zone = userForm.get("zoneId");
      @let zoneErrors = zone?.errors;
      @if (zone?.dirty && zone?.touched) {
        @if (zoneErrors?.["minlength"]) {
          <small class="text-red-500">Vă rugăm să selectați zona de acces</small>
        }
      }
    </div>

    <div class="col-12">
      <span class="font-bold block mb-1">Notițe</span>
      <textarea
        class="w-full"
        rows="5"
        cols="30"
        pInputTextarea
        formControlName="notes"
      ></textarea>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    pButton
    class="w-full"
    label="Adaugă"
    [disabled]="!userForm.valid"
    (click)="saveUser()"
  ></button>
</mat-dialog-actions>
@if (userCreateStatus() === "creating") {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}
