@if (fetching() === "success") {
  <div class="dialog-header">
    <div class="flex flex-column flex-grow-1">
      <p class="text-xs text-gray-400 m-0">
        @if (orderData()?.uniqueReference?.length) {
          &#8226; Referință unica: {{ orderData()?.uniqueReference }}
        }
        &#8226; ID unic {{ orderData()?.responseId }}
      </p>
      <h2>
        DropOff AWB
        <span
          matTooltip="Copiază AWB"
          matTooltipPosition="above"
          class="inline-flex cursor-pointer"
          [ngClass]="{
            'text-primary': !awbCopied(),
            'text-green-500': awbCopied(),
          }"
          (click)="copyAwb()"
        >
          {{ orderData()?.awb }}
          @if (awbCopied()) {
            <span class="material-symbols-rounded">check</span>
          } @else {
            <span class="material-symbols-rounded">content_copy</span>
          }
        </span>
      </h2>
      @if (hasActiveFlags()) {
        @let orderFlags = orderData()?.flags;
        <div [ngClass]="{
          'bg-green-500': orderFlags?.isInLocker || orderFlags?.isDroppedOffByUser || orderFlags?.isFinished,
          'bg-red-500': orderFlags?.isCanceled,
          'bg-yellow-500': orderFlags?.isPickedUpByCourier,
        }" class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1">
          <p class="text-xs m-0">
            @if (orderFlags?.isCanceled) {
              Anulată
            } @else if (orderFlags?.isInLocker) {
              În locker
            } @else if (orderFlags?.isPickedUpByCourier) {
              În returnare la curier
            } @else if (orderFlags?.isDroppedOffByUser) {
              Plasat de către client
            } @else if (orderFlags?.isFinished) {
              Finalizată
            }
          </p>
        </div>
      }
    </div>
    <div class="flex align-items-center gap-6">
      @let flags = orderData()?.flags;
      @if (
        !flags?.isInLocker &&
        !flags?.isDroppedOffByUser &&
        !flags?.isFinished &&
        !flags?.isCanceled &&
        !flags?.isPickedUpByCourier
      ) {
        <p-button
          size="small"
          icon="pi pi-trash"
          severity="danger"
          label="Anulare comanda"
          (click)="confirmOrderDelete()"
        />
      }

      <p-button
        mat-dialog-close
        size="small"
        icon="pi pi-times"
        severity="secondary"
        [rounded]="true"
        [text]="true"
      />
    </div>
  </div>
} @else if (fetching() === "error") {
  <div class="dialog-header flex justify-content-between align-items-center w-full">
    <h2>Ups!</h2>
    <p-button
        mat-dialog-close
        size="small"
        icon="pi pi-times"
        severity="secondary"
        [rounded]="true"
        [text]="true"
      />
  </div>
}
<p-divider styleClass="my-2"></p-divider>
<mat-dialog-content>
  @if (fetching() === "success") {
    <div class="grid">
      <div
        class="col-12 md:col-6 align-self-start"
        [ngClass]="{
          'md:col-6': orderData()?.flags?.isInLocker,
        }"
      >
        <h3 class="text-2xl font-normal m-0 line-height-2">
          Comanda cu DropOff la
          @if (orderData()?.locker) {
            <span class="font-bold capitalize">
              {{ orderData()?.locker?.name }}#{{ orderData()?.locker?.id }}
            </span>
          } @else {
            <span class="text-primary font-bold">
              Orice locker
            </span>
          }
        </h3>
        <p class="text-xs text-gray-400 m-0">
          Dată comandă: {{ orderData()?.createdAt | date: "dd/MM/YYYY" }}
        </p>
      </div>
      @if (orderData()?.flags?.isInLocker) {
        <div class="col-12 md:col-6 border-round-xs shadow-1 line-height-2 p-3 md:-mt-2 justify-content-end">
          <div class="flex gap-2 align-items-center">
            <h3 class="text-2xl m-0">Cod acces</h3>
            @if (orderData()?.openCode?.active) {
              <p-tag styleClass="border-round-xs py-1 px-2 font-bold" severity="success">
                Activ
              </p-tag>
            } @else {
              <p-tag styleClass="border-round-xs py-1 px-2 font-bold" severity="danger">
                Inactiv
              </p-tag>
            }
          </div>
          @if (orderData()?.publicReference) {
            <p
              class="text-xs m-0 w-max"
            >
              Link public:
              <span
                matTooltip="Copiază link"
                matTooltipPosition="above"
                class="inline-flex align-items-center gap-1 cursor-pointer"
                [ngClass]="{
                  'text-gray-400': !publicLinkCopied(),
                  'text-green-500': publicLinkCopied(),
                }"
                (click)="copyPublicLink()"
              >
                @if (publicLinkCopied()) {
                  link-ul a fost copiat
                  <span class="material-symbols-rounded md-18">check</span>
                } @else {
                  <span>https://www.cbox.ro/{{ orderData()?.publicReference }}</span>
                  <span class="material-symbols-rounded md-18">content_copy</span>
                }
              </span>
            </p>
          }
          @if (!showCode()) {
            <p-button
              styleClass="mt-2"
              outline="true"
              size="small"
              label="Afișează cod"
              icon="pi pi-eye"
              (click)="fetchDropoffOrderInfo(); toggleCode()"
            ></p-button>
          } @else {
            <div class="flex align-items-center gap-1">
              @for (letter of splitted(orderData()?.openCode?.code!); track $index) {
                <div class="flex align-items-center justify-content-center w-2rem h-3rem border-round surface-100">
                  <p class="p-2 font-bold text-xl m-0">{{ showCode() ? letter : "*" }}</p>
                </div>
              }
              @if (!showCode()) {
                <p class="text-xs text-primary underline m-0 cursor-pointer" (click)="toggleCode()">Afișează</p>
              }
            </div>
          }
        </div>
      }
      @if (orderData()?.reservedUntil && !orderData()?.dropOffPossibleUntil) {
        <div class="col-12 p-0">
          <div class="flex align-items-center col-12 w-full gap-2 bg-yellow-100 border-round-xs p-2 mt-2">
            <span class="material-symbols-rounded">timer</span>
            <div>
              <p class="m-0 font-bold">
                Comandă rezervată până la data de
                {{ orderData()?.reservedUntil | date: "dd MMMM" }}
                ora
                {{ orderData()?.reservedUntil | date: "HH:mm" }}
              </p>
            </div>
          </div>
        </div>
      }
      @if (orderData()?.dropOffPossibleUntil) {
        <div class="col-12">
          <div class="flex align-items-center gap-2 w-full bg-green-100 border-round-xs p-2 mt-2">
            <span class="material-symbols-rounded">check_circle</span>
            <div>
              <p class="m-0 font-bold">
                Comanda poate fi ridicată până la data de
                {{ orderData()?.dropOffPossibleUntil | date: "dd MMMM" }}
                ora
                {{ orderData()?.dropOffPossibleUntil | date: "HH:mm" }}
              </p>
            </div>
          </div>
        </div>
      }
        <div class="col-12 sm:col-6 lg:col-3">
          <div class="card h-full shadow-1 flex flex-column justify-content-between align-items-between gap-2 p-4">
            <div class="flex align-items-center gap-2">
              <p-avatar
                shape="circle"
                styleClass="p-1"
              >
                <span class="material-symbols-rounded">open_with</span>
              </p-avatar>
              <p class="m-0 text-lg">Dimensiuni colet</p>
            </div>
            <!-- <p class="text-xs text-gray-400 m-0">Dimensiunile pachetului dumneavoastră.</p> -->
            <p class=" text-xl font-bold m-0">
              @if (orderData()?.parcel?.weight) {
                {{ orderData()?.parcel?.weight }} kg
              }
              {{ orderData()?.parcel?.length }}
              <span class="font-normal">x</span>
              {{ orderData()?.parcel?.width }}
              <span class="font-normal">x</span>
              {{ orderData()?.parcel?.height }} cm
            </p>
          </div>
        </div>

        <div class="col-12 sm:col-6 lg:col-3">
          <div class="card h-full shadow-1 flex flex-column justify-content-between align-items-between gap-2 p-4">
            <div class="flex flex-column justify-content-between gap-2 h-full">
              <div class="flex align-items-center gap-2">
                <p-avatar
                  shape="circle"
                  styleClass="p-1 bg-primary"
                >
                  <span class="material-symbols-rounded">package_2</span>
                </p-avatar>
                <div class="flex flex-column">
                  <p class="m-0 text-lg">Cutie solicitată</p>
                  @if (orderData()?.box?.projected?.isActiveReservation) {
                    <span class="bg-yellow-100 shadow-1 border-round-xs text-xs w-max px-1 font-medium">Rezervare activă</span>
                  }
                </div>
              </div>
              <!-- <p class="m-0 text-gray-400 text-xs">Dimensiunea cutiei potrivită pentru coletul dumneavoastră.</p> -->
              <p class="text-xl font-bold m-0">{{ orderData()?.box?.projected?.size }}</p>
            </div>
          </div>
        </div>

        <div [ngClass]="{
          'opacity-60': !orderData()?.box?.used?.size,
        }" class="col-12 sm:col-6 lg:col-3">
          <div class="card h-full shadow-1 flex flex-column justify-content-between align-items-between gap-2 p-4">
            <div class="flex flex-column justify-content-between gap-2 h-full">
              <div class="flex align-items-center gap-2">
                <p-avatar
                  shape="circle"
                  styleClass="p-1 bg-green-500 text-white"
                >
                  <span class="material-symbols-rounded">package_2</span>
                </p-avatar>
                <div class="flex flex-column">
                  <p class="m-0 text-lg">Cutie folosită</p>
                  <span class="bg-yellow-100 shadow-1 border-round-xs text-xs w-max px-1 font-medium">{{ orderData()?.box?.used?.reason }}</span>
                </div>
              </div>
              <!-- <p class="m-0 text-gray-400 text-xs">Dimensiunea cutiei atribuită pentru coletul dumneavoastră.</p> -->
              <p class="text-xl font-bold m-0">{{ orderData()?.box?.used?.size }}</p>
            </div>
          </div>
        </div>

        <div class="col-12 sm:col-6 lg:col-3">
          <div class="card h-full shadow-1 flex flex-column justify-content-between align-items-between gap-2 p-4">
            <div class="flex flex-column justify-content-between gap-2 h-full">
              <div class="flex align-items-center gap-2">
                <p-avatar
                  shape="circle"
                  styleClass="p-1"
                  icon="pi pi-wallet"
                >
                </p-avatar>
                <p class="m-0 text-lg">Cutie facturabilă</p>
              </div>
              <!-- <p class="m-0 text-gray-400 text-xs">Dimensiunea cutiei facturată pentru coletul dumneavoastră.</p> -->
              <p class="text-xl font-bold m-0">
                @if (orderData()?.box?.projected?.size) {
                  {{ orderData()?.box?.projected?.size }}
                } @else {
                  Indisponibil
                }
              </p>
            </div>
          </div>
        </div>
      <div class="col-12 md:col-6">
        <h3 class="text-2xl">Istoric</h3>
        <p-table [tableStyle]="{ 'width': 'min(600px, 100%)' }" [value]="orderData()?.history || []" styleClass="p-datatable-sm">
          <ng-template pTemplate="header">
            <tr>
              <th>Stare</th>
              <th>Actualizat la</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-event>
            <tr>
              <td>{{ event.description }}</td>
              <td>{{ event.occurredAt | date: "dd MMMM HH:mm"}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="col-12 md:col-6">
        <h3 class="text-2xl">Notificări</h3>
        <div class="flow">
          @for (emailAddress of orderData()?.notify?.email; track $index) {
            @if (emailAddress.enabled) {
              <div class="flex gap-2 align-items-center">
                <p-avatar shape="circle" size="large">
                  <span class="material-symbols-rounded">email</span>
                </p-avatar>
                <div class="flex flex-column">
                  <div class="flex align-items-center gap-1">
                    <p class="font-bold m-0">Email - {{ emailAddress.email }}</p>
                    <span class="w-max shadow-1 -mt-1 border-round-xs px-1 text-white font-semibold bg-green-500 text-xs">ACTIV</span>
                  </div>
                  @for (email of emailAddress.sendHistory; track $index) {
                    @if (email.sentAt) {
                      Trimis la {{ email.sentAt | date: "dd MMMM HH:mm" }}
                    } @else {
                      Netrimis
                    }
                    @if (sendingEmail() === true) {
                      <p-progressSpinner
                        styleClass="w-1rem h-1rem"
                      ></p-progressSpinner>
                    } @else {
                      <p
                        class="m-0 text-primary underline cursor-pointer"
                        (click)="resendEmail()"
                      >
                        Retrimite email
                      </p>
                    }
                  }
                </div>
              </div>
            }
          } @empty {
            <div class="flex gap-2 align-items-center">
              <p-avatar shape="circle" size="large">
                <span class="material-symbols-rounded">email</span>
              </p-avatar>
              <div class="flex flex-column">
                <div class="flex align-items-center gap-1">
                  <p class="font-bold m-0">Email</p>
                  <span class="w-max shadow-1 border-round-xs -mt-1 px-1 text-white font-semibold bg-red-500 text-xs">INACTIV</span>
                </div>
              </div>
            </div>
        }

          @for (phoneNumber of orderData()?.notify?.sms; track $index) {
            @if (phoneNumber.enabled) {
              <div class="flex gap-2 align-items-center">
                <p-avatar shape="circle" size="large">
                  <span class="material-symbols-rounded">sms</span>
                </p-avatar>
                <div class="flex flex-column">
                  <div class="flex align-items-center gap-1">
                    <p class="font-bold m-0">SMS - {{ phoneNumber.phone }}</p>
                    <span class="pi pi-pencil"></span>
                    <span class="w-max shadow-1 -mt-1 border-round-xs px-1 text-white font-semibold bg-green-500 text-xs">ACTIV</span>
                  </div>
                  @for (sms of phoneNumber.sendHistory; track $index) {
                    <p class="m-0 text-gray-400"></p>
                    Trimis la {{ sms.sentAt | date: "dd MMMM HH:mm" }}
                    @if (sendingSMS() === true) {
                      <p-progressSpinner
                        styleClass="w-1rem h-1rem"
                      ></p-progressSpinner>
                    } @else {
                      <p
                        class="m-0 text-primary underline cursor-pointer"
                        (click)="resendSMS()"
                      >
                        Retrimite SMS
                      </p>
                    }
                  }
                </div>
              </div>
            }
          } @empty {
            <div class="flex gap-2 align-items-center">
              <p-avatar shape="circle" size="large">
                <span class="material-symbols-rounded">sms</span>
              </p-avatar>
              <div class="flex flex-column">
                <div class="flex align-items-center gap-1">
                  <p class="font-bold m-0">SMS</p>
                  <span class="w-max shadow-1 border-round-xs -mt-1 px-1 text-white font-semibold bg-red-500 text-xs">INACTIV</span>
                </div>
              </div>
            </div>
        }
        </div>
      </div>
    </div>
  } @else if (fetching() === "error") {
    <div class="grid py-4">
      <div class="col-12 flex flex-column justify-content-center text-center gap-4">
        <span class="pi pi-times-circle text-red-500 text-8xl"></span>
        <p class="text-red-500 font-bold text-2xl line-height-2">A apărut o eroare la încărcarea datelor.<br>Vă rugăm să reîncercați.</p>
        <p-button
          size="large"
          icon="pi pi-refresh"
          iconPos="left"
          [label]="
            fetchRetryTimer ? 'Reîncearcă în ' + fetchRetryTimer + 's' : 'Reîncearcă'

          "
          [disabled]="!!fetchRetryTimer"
          (click)="retryFetch()"
        ></p-button>
      </div>
    </div>
  }

</mat-dialog-content>