import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CBox_ActionDeleteAgentResponse, CBox_PublicGetAgentDataResponse } from '@server/services/cbox/public/api/v1/resources/agent/types';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { Message, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-cbox-profile-agent-delete-dialog',
  templateUrl: './cbox-profile-agent-delete-dialog.component.html',
  styleUrls: ["./cbox-profile-agent-delete-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ButtonModule,
    ProgressSpinnerModule,
    CheckboxModule,
    MessagesModule
  ]
})

export class CBoxProfileAgentDeleteDialogComponent implements OnInit {

  deleting = signal(false);
  confirmed = signal(false);

  warnMessage = signal<Message[]>([{
    severity: 'error',
    detail:
        `Ștergerea agentului este ireversibilă. Daca doriți să interziceți accesul agentului la lockere, vă rugăm să folosiți opțiunea "revocă acces". După ștergere, identificatorul unic nu va mai putea fi folosit.`
  }]);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfileAgentDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public agent: CBox_PublicGetAgentDataResponse) { }

  ngOnInit(): void {}

  deleteAgent(): void {
    const encodedIdentifier = encodeURIComponent(this.agent.agent.identifier as string);
    this.deleting.set(true);
    this.api.auth()
        .delete<CBox_PublicSuccessResponse<CBox_ActionDeleteAgentResponse>>("backend/agents/" + encodedIdentifier)
        .subscribe(
            (response) => {
              this.dialogRef.close(response.success);
              this.deleting.set(false);
            },
            (e: HttpErrorResponse) => {
              this.deleting.set(false);
              handlePublicApiError(e, this.toastService);
            });
  }
}