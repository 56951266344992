import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../api/api.service';
import { BehaviorSubject } from 'rxjs';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_GetLockerListIdentifierResponse, CBox_GetLockerShortData } from '@server/services/cbox/public/api/v1/resources/locker/types';
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

interface Wrapped<T> {
  isFetched: boolean;
  isError: boolean;
  data: T;
}

@Injectable({providedIn: 'root'})
export class LockersService {

  lockers = new BehaviorSubject<Wrapped<CBox_GetLockerListIdentifierResponse>>({
    isFetched: false,
    isError: false,
    data: []
  });

  constructor(
    private api: ApiService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
    this.init();
  }

  init(): void {
    this.fetchLockers();
  }

  async getList(): Promise<CBox_GetLockerListIdentifierResponse> {
    const lockersValue = this.lockers.getValue();
    if (lockersValue.isFetched === false || lockersValue.isError === true) {
      await this.fetchLockers();
    }

    return this.lockers.getValue().data;
  }

  async getLocker(idOrName: string): Promise<CBox_GetLockerShortData | undefined> {
    const data = this.lockers.getValue().data;
    let locker = data.find((l) => l.id === idOrName || l.name === idOrName);
    if (!locker) {
      await this.fetchLockers();
    }

    return this.lockers.getValue().data.find((l) => l.id === idOrName || l.name === idOrName);
  }

  private async fetchLockers(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (isPlatformBrowser(this.platformId)) {
        this.api.auth().get<CBox_PublicSuccessResponse<CBox_GetLockerListIdentifierResponse>>("backend/locker/identifiers?includeZones=true").subscribe((response) => {
          this.lockers.next({
            isFetched: true,
            isError: false,
            data: response.data
          });1
          resolve();
        }, (e: HttpErrorResponse) => {
          this.lockers.next({
            isFetched: true,
            isError: true,
            data: []
          });
          resolve();
        });
      }
    });
  }

}