<ng-container [formGroup]="equipmentForm">
  <h2 class="col-12 m-0">Configurare router</h2>
  <input type="text" class="hidden">
  <input type="text" class="hidden">
  <div class="col-12">
    <span class="block font-bold mb-1">Admin user</span>
    <p-inputGroup>
      <p-inputGroupAddon class="bg-white border-noround-right">
        <i class="pi pi-user"></i>
      </p-inputGroupAddon>
      <input
        autocomplete="off"
        class="w-full"
        id="email"
        type="text"
        pInputText
        formControlName="adminUser"
        placeholder="Username"
      >
    </p-inputGroup>
    @let adminUser = equipmentForm.get("adminUser");
    @let adminUserErrors = adminUser?.errors;
    @if (adminUser?.dirty) {
      @if (adminUserErrors?.["required"]) {
        <small class="text-red-500">Câmpul este obligatoriu</small>
      }
    }
  </div>

  <div class="col-12">
    <span class="block font-bold mb-1">Admin password</span>
    <p-inputGroup>
      <p-inputGroupAddon class="bg-white border-noround-right">
        <i class="pi pi-lock"></i>
      </p-inputGroupAddon>
      <input
        type="text"
        styleClass="border-noround-left"
        autocomplete="off"
        class="w-full border-noround-left"
        id="password"
        formControlName="adminPassword"
        placeholder="Parolă"
        pInputText
      >
    </p-inputGroup>
    @let adminPassword = equipmentForm.get("adminPassword");
    @let adminPasswordErrors = adminPassword?.errors;
    @if (adminPassword?.dirty) {
      @if (adminPasswordErrors?.["required"]) {
        <small class="text-red-500">Câmpul este obligatoriu</small>
      }
    }
  </div>

  <div class="col-12">
    <span class="block font-bold mb-1">SSID</span>
    <input
      class="w-full"
      formControlName="ssid"
      placeholder="Introduceți SSID-ul"
      pInputText
    >
    @let ssid = equipmentForm.get("ssid");
    @let ssidErrors = ssid?.errors;
    @if (ssid?.dirty) {
      @if (ssidErrors?.["required"]) {
        <small class="text-red-500">Câmpul este obligatoriu</small>
      }
    }
  </div>

  <div class="col-12">
    <span class="block font-bold mb-1">Parolă rețea</span>
    <p-inputGroup>
      <p-inputGroupAddon class="bg-white border-noround-right">
        <i class="pi pi-lock"></i>
      </p-inputGroupAddon>
      <input type="text" class="hidden" autocomplete="off">
      <input
        type="text"
        styleClass="border-noround-left"
        autocomplete="new-password"
        class="w-full border-noround-left"
        formControlName="wifiPassword"
        placeholder="Parolă"
        pInputText
      >
    </p-inputGroup>
    @let wifiPassword = equipmentForm.get("wifiPassword");
    @let wifiPasswordErrors = wifiPassword?.errors;
    @if (wifiPassword?.dirty) {
      @if (wifiPasswordErrors?.["required"]) {
        <small class="text-red-500">Câmpul este obligatoriu</small>
      }
    }
  </div>
  <h2 class="col-12 m-0">SIM</h2>
  <div [formGroup]="simForm" class="col-12">
    <span class="block font-bold mb-1">Număr de telefon</span>
    <input
      class="w-full"
      formControlName="phoneNumber"
      placeholder="Introduceți numărul de telefon"
      pInputText
    >
    @let phoneNumber = simForm.get("phoneNumber");
    @let phoneNumberErrors = phoneNumber?.errors;
    @if (phoneNumber?.dirty) {
      @if (phoneNumberErrors?.["required"]) {
        <small class="text-red-500">Câmpul este obligatoriu</small>
      }
    }
  </div>

  <div [formGroup]="simForm" class="col-12">
    <span class="block font-bold mb-1">Număr SIM</span>
    <input
      class="w-full"
      formControlName="simNumber"
      placeholder="Introduceți numărul SIM-ului"
      pInputText
    >
    @let simNumber = simForm.get("simNumber");
    @let simNumberErrors = simNumber?.errors;
    @if (simNumber?.dirty) {
      @if (simNumberErrors?.["required"]) {
        <small class="text-red-500">Câmpul este obligatoriu</small>
      }
    }
  </div>

  <div [formGroup]="simForm" class="col-12">
    <span class="block font-bold mb-1">IP</span>
    <input
      class="w-full"
      formControlName="ip"
      placeholder="Introduceți IP-ul"
      pInputText
    >
    @let ip = simForm.get("ip");
    @let ipErrors = ip?.errors;
    @if (ip?.dirty) {
      @if (ipErrors?.["required"]) {
        <small class="text-red-500">Câmpul este obligatoriu</small>
      }
    }
  </div>

  <h2 class="col-12 m-0">Subscripție</h2>
  <div [formGroup]="subscriptionForm" class="col-12">
    <span class="block font-bold mb-1">Nume subscripție</span>
    <input
      class="w-full"
      formControlName="name"
      placeholder="Introduceți numele subscripției"
      pInputText
    >
    @let subName = subscriptionForm.get("name");
    @let subNameErrors = subName?.errors;
    @if (subName?.dirty) {
      @if (subNameErrors?.["required"]) {
        <small class="text-red-500">Câmpul este obligatoriu</small>
      }
    }
  </div>
</ng-container>