import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, computed, DestroyRef, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CBox_PublicPaginatedResponse, CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_GetLockerShortData } from '@server/services/cbox/public/api/v1/resources/locker/types';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule, TablePageEvent } from 'primeng/table';
import { debounceTime, first } from 'rxjs';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { LockersService } from 'src/services/lockers/lockers.service';
import { ProfileService } from 'src/services/profile/profile.service';
import { SessionService } from 'src/services/session/session.service';
import { ToastService } from 'src/services/toast/toast.service';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { CBox_AdminEquipmentListItem, CBox_AdminEquipmentListRequestData, CBox_AdminEquipmentListResponse, CBox_EquipmentType } from '@server/services/cbox/public/api/v1/resources/internal/equipment/types';
import { CBoxAdminEquipmentCreateDialogComponent } from '../dialogs/create/cbox-admin-equipment-create.component';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';

@Component({
  selector: 'app-cbox-admin-equipments-list',
  templateUrl: './cbox-admin-equipments-list.component.html',
  styleUrls: ["./cbox-admin-equipments-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    ProgressBarModule,
    MenuModule,
    CheckboxModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    IconFieldModule,
    InputIconModule
  ]
})

export class CBoxAdminEquipmentsListComponent implements OnInit {
  filtersForm: FormGroup;

  equipments = signal<CBox_AdminEquipmentListResponse>([]);
  equipmentTypes = signal<CBox_EquipmentType[] | undefined>(undefined);
  fetchingEquipments = signal(false);

  lockers = signal<CBox_GetLockerShortData[] | undefined>(undefined);
  fetchingLockers = signal(false);

  selectedEquipment = signal<any | undefined>(undefined);
  equipmentMenuItems = computed<MenuItem[]>(() => [
    {
      label: "Creează echipament similar",
      icon: "pi pi-plus",
      command: () => {
        this.copyAndCreateEquipment(this.selectedEquipment());
      },
    },
    // {
    //   label: "Șterge echipament",
    //   icon: "pi pi-trash",
    //   command: () => {
    //     // this.confirmOrderDelete(this.selectedOrder());
    //   },
    // },
  ]);
  totalCount = signal(0);

  constructor(
    private api: ApiService,
    private profileService: ProfileService,
    private session: SessionService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private destroyRef: DestroyRef,
    private lockerService: LockersService,
    private toastService: ToastService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {
    this.filtersForm = this.fb.group({
      page: [1],
      pageSize: [100],
      includeInstalled: [true],
      includeNotInstalled: [true],
      lockerIdentifier: [undefined],
      equipmentType: [undefined],
      moduleSerial: [""]
    });
  }
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.init();
  }

  private async init(): Promise<void> {
    this.profileService.setTitle("Listă echipamente");

    this.lockers.set(await this.lockerService.getList());

    const defaultFilters = {
      page: 1,
      pageSize: 100,
      includeInstalled: true,
      includeNotInstalled: true,
      lockerIdentifier: undefined,
      equipmentType: undefined,
      moduleSerial: ""
    };

    const cachedFilters = this.session.getVal("equipment-list/filters", undefined);

    if (cachedFilters) {
      const parsedFilters = JSON.parse(cachedFilters);

      if (parsedFilters.interval && parsedFilters.interval.length === 2) {
        parsedFilters.interval = parsedFilters.interval.map((dateString: string) => new Date(dateString));
      }

      this.filtersForm.patchValue(
        {
          ...defaultFilters,
          ...parsedFilters,
        },
        { emitEvent: false }
      );
    } else {
      this.filtersForm.patchValue(defaultFilters, { emitEvent: false });
    }

    this.filtersForm.valueChanges
      .pipe(
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.saveFilters();
        this.loadEquipments();
      });

    this.loadEquipments();
  }

  loadEquipments(): void {
    this.equipments.set([]);
    this.fetchingEquipments.set(true);
    if (isPlatformBrowser(this.platformId)) {
      this.fetchEquipments();
      this.fetchEquipmentTypes();
    }
  }

  pageChanged(event: TablePageEvent): void {
    const page = event.first / event.rows;
    this.filtersForm.patchValue({
      page: page + 1,
      pageSize: event.rows
    });
  }

  createEquipment(): void {
    const dialog = this.dialog.open(CBoxAdminEquipmentCreateDialogComponent, {
      width: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loadEquipments();
    });
  }

  copyAndCreateEquipment(equipment?: CBox_AdminEquipmentListItem): void {
    if (!equipment) {
      return;
    }

    const dialog = this.dialog.open(CBoxAdminEquipmentCreateDialogComponent, {
      data: equipment,
      width: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loadEquipments();
    });
  }

  patchFilters(filters: {[key: string]: unknown}): void {
    this.filtersForm.patchValue({
      ...filters
    });
  }

  propagateLocker(identifier: string): void {
    const adminLockersListFilters = this.session.getVal("admin-lockers-list/filters", undefined);
    const adminLockerModulesFilters = this.session.getVal("admin-modules-list/filters", undefined);
    if (adminLockersListFilters) {
      const parsed = JSON.parse(adminLockersListFilters);
      parsed.lockerIdentifiers = [identifier];
      this.session.setVal("admin-lockers-list/filters", JSON.stringify(parsed));
    } else {
      const filters = {
        lockerIdentifiers: [identifier]
      };

      this.session.setVal("admin-lockers-list/filters", JSON.stringify(filters));
    }

    if (adminLockerModulesFilters) {
      const parsed = JSON.parse(adminLockerModulesFilters);
      parsed.lockerIdentifier = identifier;

      this.session.setVal("admin-modules-list/filters", JSON.stringify(parsed));
    } else {
      const filters = {
        lockerIdentifier: identifier
      };

      this.session.setVal("admin-modules-list/filters", JSON.stringify(filters));
    }

    this.patchFilters({
      lockerIdentifier: identifier
    });
  }

  propagateModule(serial: string): void {
    const adminLockerModulesFilters = this.session.getVal("admin-modules-list/filters", undefined);

    if (adminLockerModulesFilters) {
      const parsed = JSON.parse(adminLockerModulesFilters);
      parsed.serial = serial;
      this.session.setVal("admin-modules-list/filters", JSON.stringify(parsed));
    }

    this.patchFilters({
      moduleSerial: serial
    });
  }

  private fetchEquipments(): void {
    const params = new HttpParams({
      fromObject: this.getSearchStructure()
    });
    this.api
      .auth()
      .get<CBox_PublicPaginatedResponse<CBox_AdminEquipmentListItem>>("backend/internal/equipments/list", params)
      .subscribe((response) => {
          this.equipments.set(response.data);
          this.totalCount.set(response.totalCount);
          this.fetchingEquipments.set(false);
        }, (e: HttpErrorResponse) => {
          this.fetchingEquipments.set(false);
          handlePublicApiError(e, this.toastService);
        }
      );
  }

  private fetchEquipmentTypes(): void {
    this.api
      .auth()
      .get<CBox_PublicSuccessResponse<CBox_EquipmentType[]>>("backend/internal/equipments/types")
      .subscribe((response) => {
          this.equipmentTypes.set(response.data);
        }, (e: HttpErrorResponse) => {
          handlePublicApiError(e, this.toastService);
        }
      );
  }

  private saveFilters(): void {
    const filtersValues = this.filtersForm.value;
    const filtersToSave = {
      ...filtersValues,
      interval: filtersValues.interval
        ? filtersValues.interval.map((date: Date) => date?.toISOString())
        : undefined,
    };
    this.session.setVal("equipment-list/filters", JSON.stringify(filtersToSave));
  }

  private getSearchStructure(): CBox_AdminEquipmentListRequestData {
    return Object.entries({
      lockerIdentifier: this.filtersForm.value.lockerIdentifier,
      moduleSerial: this.filtersForm.value.moduleSerial,
      includeInstalled: !!this.filtersForm.value.includeInstalled,
      includeNotInstalled: !!this.filtersForm.value.includeNotInstalled,
      typeId: this.filtersForm.value.equipmentType,
      page: +this.filtersForm.value.page,
      pageSize: +this.filtersForm.value.pageSize
    })
    .filter(([_, value]) => value?.length || !!value || value != null)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_AdminEquipmentListRequestData] = value;
      return acc;
    }, {} as CBox_AdminEquipmentListRequestData);
  }
}