import { Directive, ElementRef, Input, OnInit, signal, TemplateRef, ViewContainerRef } from '@angular/core';
import { ConfigurationService } from 'src/services/configuration/configuration.service';


/**
 * @directive appRights
 * @param rights - The rights that the user must have in order to see the element
 * @description Similar to *ngIf but it checks if the user has the rights to see the element
 *
 * @param { string[] } rights
 *
 * @example:
 * * <div *appRights="['permission1', 'permission2']">
 * *   <p> This will be shown if the user has permission1 and permission2 </p>
 * * </div>
 */
@Directive({
  selector: '[appRights]',
  standalone: true
})

export class PermissionDirective {
  private hasView = signal(false);

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private configuration: ConfigurationService) {}

  @Input() set appRights(rights: string[]) {
    this.updateView(rights);
  }

  private async updateView(rights: string[]) {
    if (rights == null) {
      this.createView();
      return;
    }

    const hasAccess = this.configuration.hasAccess(rights);
    if (hasAccess && !this.hasView()) {
      this.createView();
    } else {
      if (!hasAccess && this.hasView()) {
        this.viewContainer.clear();
        this.hasView.set(false);
      }
    }
  }

  private createView() {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.hasView.set(true);
  }
}
