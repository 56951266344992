<p-button
  *appRights="[permissions.createApiUser]"
  label="Adaugă utilizator"
  icon="pi pi-plus"
  (click)="addUser()"
></p-button>

<p-table
  styleClass="p-datatable-sm mt-2"
  currentPageReportTemplate="{first} - {last} din {totalRecords} utilizatori"
  paginatorPosition="both"
  [totalRecords]="totalCount()"
  [paginator]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [value]="apiUsers()"
  [rowsPerPageOptions]="[25, 50, 100]"
  [showCurrentPageReport]="true"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (apiUsersFetchStatus() === 'fetching') {
      <tr>
        <td colspan="5">
          <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" />
        </td>
      </tr>
    }
    <tr>
      <th>Client ID</th>
      <th>Descriere</th>
      <th>Rol</th>
      <th>Activitate</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
    <tr>
      <td>{{ user.clientId }}</td>
      <td>{{ user.description }}</td>
      <td>{{ user.role }}</td>
      <td>
        {{ getLastActiveMessage(user.lastUsedAt) }}
        nu e corect, trebuie modificat
      </td>
      <td class="text-right">
        <p-menu
          styleClass="w-15rem"
          #userMenu
          appendTo="body"
          [popup]="true"
          [model]="apiUserMenuItems()"
        />
        <p-button
          size="small"
          icon="pi pi-ellipsis-v"
          [plain]="true"
          [text]="true"
          [rounded]="true"
          (click)="this.selectedApiUser.set(user); userMenu.toggle($event)"
        ></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
      @if (apiUsersFetchStatus() === "success") {
      <tr>
        <td colspan="5">
          <p class="text-center text-gray-400 text-xl font-bold">Nu există utilizatori</p>
        </td>
      </tr>
    }
  </ng-template>
</p-table>
