<div class="grid">
  <div class="col-12 lg:col-6">
    <h2 class="mb-0">Informații generale</h2>
    <small class="text-gray-600">Puteți schimba aceste detalii oricând.</small>
    <form [formGroup]="userDataForm" class="relative flex flex-column gap-2 mt-4">
      <div>
        <span class="font-bold block mb-1">Nume</span>
        <p class="m-0">{{ userDataForm.get("name")?.value }}</p>
        <!-- <input
          autocomplete="off"
          class="w-full"
          type="text"
          pInputText
          placeholder="Nume"
          formControlName="name"
          readonly
        >
        @let name = this.userDataForm.get("name");
        @let nameErrors = name?.errors;
        @if (name?.dirty && name?.touched) {
          @if (nameErrors?.["required"]) {
            <small class="text-red-500">Numele este obligatoriu</small>
          }
        } -->
      </div>

      <div>
        <span class="font-bold block mb-1">Email</span>
        <p class="m-0">{{ userDataForm.get("email")?.value }}</p>
        <!-- <input
          autocomplete="off"
          class="w-full"
          id="email"
          type="text"
          pInputText
          placeholder="Email"
          formControlName="email"
          readonly
        >
        @let email = this.userDataForm.get("email");
        @let emailErrors = email?.errors;
        @if (email?.dirty && email?.touched) {
          @if (emailErrors?.["required"]) {
            <small class="text-red-500">Email-ul este obligatoriu</small>
          }
        } -->
      </div>

      <div>
        <span class="font-bold block mb-1">Telefon</span>
        <input
          autocomplete="off"
          class="w-full"
          id="email"
          type="text"
          pInputText
          placeholder="Telefon"
          formControlName="phone"
        >
        @let phone = this.userDataForm.get("phone");
        @let phoneErrors = phone?.errors;
        @if (phone?.dirty && phone?.touched) {
          @if (phoneErrors?.["required"]) {
            <small class="text-red-500">Numarul de telefon este obligatoriu</small>
          }
        }
      </div>

      <!-- <div>
        <span class="font-bold block mb-1">Notițe</span>
        <textarea
          class="w-full"
          rows="5"
          cols="30"
          pInputTextarea
          formControlName="notes"
        ></textarea>
      </div> -->

      <p-button
        size="small"
        class="ml-auto"
        label="Salvează date"
        [disabled]="formDataChanged() === false || userDataForm.invalid"
        (click)="changeUserData()"
      ></p-button>

      @if (this.userDataFetchStatus() === "fetching") {
        <div class="blurred-div">
          <p-progressSpinner
            ariaLabel="loading"
            class="blurred-div-spinner"
          ></p-progressSpinner>
        </div>
      }
    </form>
  </div>
  <div class="col-12 lg:col-6">
    <h2 class="mb-0">Dispozitive conectate</h2>
    <small class="text-gray-600">Dispozitivele pe care sunteți conectat cu acest cont.</small>
    <div class="grid grid-nogutter gap-2 mt-4">
      @for (login of userData()?.logins; track $index) {
        <div class="flex align-items-center border-round-xs w-full">
          <p-avatar
            size="large"
            shape="circle"
            class="mr-2"
            icon="pi pi-desktop"
          ></p-avatar>
          <div class="flex flex-column">
            @if (
              login.deviceDetails.browser === "Other" ||
              login.deviceDetails.os === "Other" ||
              login.deviceDetails.platform === "Other"
            ) {
              <span>Nedefinit</span>
              <small class="text-gray-400">{{ getLastActiveMessage(login.lastUsedAt) }}</small>
            } @else {
              <p class="m-0">
                {{ login.deviceDetails.browser }}
                (<small class="vertical-align-top">{{ login.deviceDetails.os }}</small>)
                @if (login.isCurrentDevice) {
                  <small class="text-green-500 vertical-align-top">actual</small>
                }
              </p>
              <small class="text-gray-400">{{ getLastActiveMessage(login.lastUsedAt) }}</small>
            }
          </div>
          @if (!login.isCurrentDevice) {
            <p-button
              pTooltip="Deconectați acest dispozitiv"
              tooltipPosition="left"
              class="ml-auto"
              size="small"
              icon="pi pi-times"
              [plain]="true"
              [text]="true"
              [rounded]="true"
              (click)="logoutDevice(login)"
            ></p-button>
          }
        </div>
      }
    </div>
  </div>
</div>
