<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Prelungește comandă</h2>
  <p-button
    mat-dialog-close
    size="small"
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  @if (orderData()) {
    <p>Doriți să extindeți termenul comenzii cu AWB <b>{{ orderData()?.awb }}</b>?</p>
    @if (orderData()?.pickUpPossibleUntil) {
      <p>
        Termenul de ridicare al comenzii este
        <b>{{ orderData()?.pickUpPossibleUntil | date: "dd MMMM" }}</b>
        ora
        <b>{{ orderData()?.pickUpPossibleUntil | date: "HH:mm" }}</b>
      </p>
    }
    <span class="font-bold block mb-1">Extinde comanda cu</span>
    <div class="grid mt-1">
      @for (period of extendPeriods(); track $index) {
        <div
          class="col-3 pt-0"
        >
          <div
            class="border-300 border-solid border-1 border-round-md w-full px-2 cursor-pointer transition-colors transition-duration-200"
            [pTooltip]="
              period.hours > 0 && addHours(orderData()?.pickUpPossibleUntil!, period.hours) > maxStorageDate ?
                'Această opțiune depășește perioada maximă de stocare permisă pentru comandă' : ''
            "
            tooltipPosition="top"
            [ngClass]="{
              'active': selectedPeriod() === period,
              'disabled': period.hours > 0 && addHours(orderData()?.pickUpPossibleUntil!, period.hours) > maxStorageDate,
            }"
            (click)="periodSelected(period)"
          >
          <p class="m-0 my-1 text-xs font-bold flex-1">{{ period.label }}</p>
          </div>
        </div>
      }
    </div>
    @if (selectedPeriod()?.hours === 0) {
      <p-calendar
        dateFormat="dd/mm/yy"
        styleClass="w-full"
        appendTo="body"
        [minDate]="minDate()"
        [showTime]="true"
        [formControl]="extendDate"
        [showIcon]="true"
      ></p-calendar>
    }
    @if (selectedPeriod()) {
      @if (selectedPeriod()!.hours > 0) {
        <p>Noul termen de ridicare al comenzii va fi {{ addHours(orderData()?.pickUpPossibleUntil!, selectedPeriod()!.hours) | date: "dd MMMM HH:mm" }}</p>
      }
    }
  } @else {
    <p-skeleton styleClass="w-full mt-1"></p-skeleton>
    <p-skeleton styleClass="w-full mt-2"></p-skeleton>

    <p-skeleton styleClass="w-full mt-3"></p-skeleton>
    <p-skeleton styleClass="w-full mt-2"></p-skeleton>

    <p-skeleton width="8rem" styleClass="mt-3"></p-skeleton>
    <p-skeleton height="2.8125rem" styleClass="w-full mt-2" borderRadius=".5rem"></p-skeleton>
  }
</mat-dialog-content>
<mat-dialog-actions>
  @if (fetching()) {
    <div class="flex align-items-center gap-2">
      <p-skeleton
        height="2.5rem"
        width="6.25rem"
        borderRadius=".5rem"
      ></p-skeleton>

      <p-skeleton
        height="2.5rem"
        width="9.375rem"
        borderRadius=".5rem"
      ></p-skeleton>
    </div>
  } @else {
    <div class="flex align-items-center gap-2">
      <p-button
        label="Anulează"
        size="small"
        [text]="true"
        mat-dialog-close
      ></p-button>

      <p-button
        label="Extinde"
        size="small"
        icon="pi pi-calendar-plus"
        iconPos="left"
        (click)="extendRequest()"
      ></p-button>
    </div>
  }
</mat-dialog-actions>
@if (extending()) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}