import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit, signal, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_GetLockerShortData } from '@server/services/cbox/public/api/v1/resources/locker/types';
import { CBox_ActionCreateUserResponse } from '@server/services/cbox/public/api/v1/resources/user/types';
import { CBox_ActionCreateZoneRequestData, CBox_GetZoneListResponseItem } from '@server/services/cbox/public/api/v1/resources/zone/types';
import e from 'express';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EMPTY, Subject, switchMap, tap } from 'rxjs';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { LockersService } from 'src/services/lockers/lockers.service';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-cbox-profile-zone-create-dialog',
  templateUrl: './cbox-profile-zone-create-dialog.component.html',
  styleUrls: ["./cbox-profile-zone-create-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    DropdownModule,
    ReactiveFormsModule,
    AutoFocusModule,
    MatDialogContent,
    MatDialogActions,
    ButtonModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    CheckboxModule,
    MatDialogClose
  ]
})

export class CBoxProfileZoneCreateDialogComponent implements OnInit {

  zoneForm: FormGroup;
  creatingZone = signal(false);
  zones = signal<Record<number, { id: string; name: string }>[]>([]);
  zonesFetched = signal(false);
  groupedLockers = signal<Record<CBox_GetLockerShortData["id"], CBox_GetLockerShortData[]>>({});
  lockers = signal<CBox_GetLockerShortData[]>([]);

  @ViewChild("lockersMultiSelect") lockersMultiSelect?: MultiSelect;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private lockersService: LockersService,
    private dialogRef: MatDialogRef<CBoxProfileZoneCreateDialogComponent>,
    private toastService: ToastService) {
      this.zoneForm = this.fb.group({
        name: ["", Validators.required],
        description: [""],
        parentZone: [undefined, Validators.required],
        hasAccessToAllParentLockers: [{
          value: false,
          disabled: true
        }],
        lockers: [{
          value: [],
          disabled: true
        }]
      });
    }

  ngOnInit(): void {
    this.init();
  }

  private async init(): Promise<void> {
    const lockers = await this.lockersService.getList();
    let groupedLockers: Record<CBox_GetLockerShortData["id"], CBox_GetLockerShortData[]> = {};
    const zones: CBox_GetLockerShortData["zones"] = [];
      for (const locker of lockers) {
        for (const zone of locker?.zones!) {
          if (!groupedLockers[+zone.id]) {
            groupedLockers[+zone.id] = [];
          }
          groupedLockers[+zone.id].push({id: locker.id, name: locker.name});
          if (!zones.find(z => +z.id === zone.id)) {
            zones.push({id: zone.id, name: zone.name});
          }
        }
      }
      const sortedZones = zones.sort((a, b) => {
        const countA = groupedLockers[a.id]?.length || 0;
        const countB = groupedLockers[b.id]?.length || 0;

        return countB - countA;
      });
      this.zones.set(sortedZones);
      this.groupedLockers.set(groupedLockers);
      this.zonesFetched.set(true);
  }

  parentZoneSelected(zoneId: number): void {
    const hasAccessToAllParentLockers = this.zoneForm.get("hasAccessToAllParentLockers");
    hasAccessToAllParentLockers?.enable({
      emitEvent: false
    });
    hasAccessToAllParentLockers?.patchValue(false, {
      emitEvent: false
    });
    const lockers = this.zoneForm.get("lockers");
    lockers?.enable();
    lockers?.patchValue([], {
      emitEvent: false
    });
    this.lockers.set(this.groupedLockers()[zoneId]);
  }

  create(): void {
    this.api.auth().post<CBox_PublicSuccessResponse<CBox_ActionCreateUserResponse>>("backend/zones", this.getCreateStructure()).subscribe((response) => {
      this.creatingZone.set(false);
      this.dialogRef.close();68720
      this.toastService.showSuccessToast("Confirmare", "Zona a fost creată cu succes!");
    }, (e: HttpErrorResponse) => {
      this.creatingZone.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  allLockersToggled(): void {
    const f = this.zoneForm;
    const hasAccessToAllParentLockers = f.value.hasAccessToAllParentLockers;
    const lockers = f.get("lockers");
    if (hasAccessToAllParentLockers) {
      lockers?.disable();
      lockers?.patchValue([], {
        emitEvent: false
      })
    } else {
      lockers?.enable();
    }
  }

  lockerSelected(): void {
    const lockers = this.zoneForm.get("lockers");
    const hasAccessToAllParentLockers = this.zoneForm.get("hasAccessToAllParentLockers");
    const parentZone = this.zoneForm.value.parentZone;
    const allLockersSelected = this.groupedLockers()[parentZone].length === lockers?.value.length;
    hasAccessToAllParentLockers?.patchValue(allLockersSelected, {
      emitEvent: false
    });
    this.allLockersToggled();
    if (allLockersSelected) {
      this.lockersMultiSelect?.hide();
    }
  }

  private getCreateStructure(): CBox_ActionCreateZoneRequestData {
    const f = this.zoneForm.value;
    const data: CBox_ActionCreateZoneRequestData = {
      name: f.name,
      description: f.description,
      parentZoneId: f.parentZone,
      hasAccessToAllParentLockers: f.hasAccessToAllParentLockers,
      lockers: f.lockers.map((id: string) => ({id}))
    };

    return data;
  }
}