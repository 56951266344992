<ng-container [formGroup]="credentialsForm">
  <h3 class="col-12 m-0">Date logare DVR</h3>
  <input type="text" class="hidden">
  <input type="password" class="hidden">
  <div class="col-12">
    <p-inputGroup>
      <p-inputGroupAddon class="bg-white border-noround-right">
        <i class="pi pi-user"></i>
      </p-inputGroupAddon>
      <input
        name="foo"
        autocomplete="off"
        class="w-full"
        type="text"
        pInputText
        formControlName="user"
        placeholder="Username"
      >
    </p-inputGroup>
    @let user = equipmentForm.get("user");
    @let userErrors = user?.errors;
    @if (user?.dirty) {
      @if (userErrors?.["required"]) {
        <small class="text-red-500">Câmpul este obligatoriu</small>
      }
    }
  </div>

  <div class="col-12">
    <p-inputGroup>
      <p-inputGroupAddon class="bg-white border-noround-right">
        <i class="pi pi-lock"></i>
      </p-inputGroupAddon>
      <input
        type="text"
        styleClass="border-noround-left"
        autocomplete="off"
        class="w-full border-noround-left"
        id="password"
        formControlName="password"
        placeholder="Parolă"
        pInputText
      >
    </p-inputGroup>
    @let password = equipmentForm.get("password");
    @let passwordErrors = password?.errors;
    @if (password?.dirty) {
      @if (passwordErrors?.["required"]) {
        <small class="text-red-500">Câmpul este obligatoriu</small>
      }
    }
  </div>

  <div class="col-12">
    <span class="font-bold block mb-1">HIK Connect code</span>
    <input
      type="text"
      formControlName="connectCode"
      class="w-full"
      pInputText
      placeholder="Introduceți codul de conectare"
    >
  </div>
</ng-container>