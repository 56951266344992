import { CommonModule } from "@angular/common";
import { Component, OnInit, signal } from "@angular/core";
import { PermissionNamesEnum } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { MenuItem } from "primeng/api";
import { TabMenuModule } from "primeng/tabmenu";
import { PermissionDirective } from "src/directives/permission.directive";

@Component({
  selector: "app-cbox-profile-users-list-container",
  templateUrl: "./cbox-profile-users-list-container.component.html",
  styleUrls: ["./cbox-profile-users-list-container.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TabMenuModule,
    PermissionDirective
  ]
})

export class CBoxProfileUsersListContainerComponent implements OnInit {

  tabMenuItems = signal<MenuItem[]>([
    {
      label: "Utilizatori platformă",
      routerLink: "/profile/users/internal",
      icon: "group",
      rights: [
        PermissionNamesEnum.viewPlatformUsers,
        PermissionNamesEnum.editPlatformUser,
        PermissionNamesEnum.resetPlatformUserPassword,
        PermissionNamesEnum.createPlatformUser
      ]
    },
    {
      label: "Utilizatori API",
      routerLink: "/profile/users/api",
      icon: "code",
      rights: [
        PermissionNamesEnum.viewApiUsers,
        PermissionNamesEnum.editApiUser,
        PermissionNamesEnum.createApiUser,
        PermissionNamesEnum.deleteApiUser,
        PermissionNamesEnum.regenerateApiUserCredentials
      ]
    },
  ]);

  constructor() { }

  ngOnInit() { }
}