import { CommonModule } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_ActionDeleteAgentResponse } from "@server/services/cbox/public/api/v1/resources/agent/types";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_GetApiUserListResponseItem } from "@server/services/cbox/public/api/v1/resources/user/types";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { getLastActiveMessage } from "src/helpers/functions";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-profile-agent-delete-dialog",
  templateUrl: "./cbox-profile-api-user-delete-dialog.component.html",
  styleUrls: ["./cbox-profile-api-user-delete-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ButtonModule,
    ProgressSpinnerModule,
    CheckboxModule,
  ]
})

export class CBoxProfileApiUserDeleteDialogComponent implements OnInit {

  deleting = signal(false);
  confirmed = signal(false);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfileApiUserDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public apiUser: CBox_GetApiUserListResponseItem) { }

  ngOnInit(): void {}

  deleteApiUser(): void {
    const encodedIdentifier = encodeURIComponent(this.apiUser.identifier as string);
    this.deleting.set(true);
    this.api.auth()
        .delete<CBox_PublicSuccessResponse<CBox_ActionDeleteAgentResponse>>("backend/users/" + encodedIdentifier)
        .subscribe(
            (response) => {
              this.dialogRef.close(response.success);
              this.deleting.set(false);
            },
            (e: HttpErrorResponse) => {
              this.deleting.set(false);
              handlePublicApiError(e, this.toastService);
            });
  }

  getLastActiveMessage(lastUsedAt: string): string {
    return getLastActiveMessage(lastUsedAt);
  }
}