import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_ActionUpdateSelfUserResponse } from '@server/services/cbox/public/api/v1/resources/user/types';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ToastService } from 'src/services/toast/toast.service';
import { matchOtherValidator } from 'src/validators/match-other-control-validator';
import { CBox_PublicActionSetPasswordRequestParams, CBox_PublicGetPasswordTokenDataResponse } from "@server/services/cbox/public/api/v1/resources/open/password/types";

@Component({
  selector: 'app-cbox-public-password-reset',
  templateUrl: './cbox-public-password-reset.component.html',
  styleUrls: ['./cbox-public-password-reset.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    InputTextModule,
    IconFieldModule,
    ButtonModule,
    MatIconModule,
    ProgressSpinnerModule,
    ReactiveFormsModule
  ]
})

export class CBoxPublicPasswordResetComponent implements OnInit {

  passwordForm: FormGroup;
  response = signal<CBox_PublicGetPasswordTokenDataResponse | undefined>(undefined);
  pageStatus = signal<"loading" | "error" | "validToken" | "passwordResetError" | "invalidToken">("loading");

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private router: Router,
    private toastService: ToastService,
    private route: ActivatedRoute) {
      this.passwordForm = this.fb.group({
        password: ["", [Validators.required, Validators.minLength(8)]],
        confirmPassword: ["", matchOtherValidator("password")]
      });
    }

  ngOnInit() {
    this.checkIdentifier();
  }

  resetPassword(): void {
    this.pageStatus.set("loading");
    this.api.auth().post<CBox_PublicSuccessResponse<CBox_ActionUpdateSelfUserResponse>>("backend/open/password", this.getResetStructure()).subscribe((response) => {
      this.toastService.showSuccessToast("Resetare parolă", "Parola a fost resetată cu succes.");
      this.router.navigate(["/login"]);
    }, (e: HttpErrorResponse) => {
      this.pageStatus.set("passwordResetError")
      handlePublicApiError(e, this.toastService);
    });
  }

  private checkIdentifier(): void {
    const token = this.route.snapshot.paramMap.get("token");
    if (!token) {
      this.pageStatus.set("invalidToken");
      return;
    }

    const params = new HttpParams().append("token", token);
    this.api.auth().get<CBox_PublicSuccessResponse<CBox_PublicGetPasswordTokenDataResponse>>("backend/open/password?includeBrandData=true", params).subscribe((response) => {
      this.response.set(response.data);
      this.pageStatus.set("validToken");
    }, () => {
      this.pageStatus.set("invalidToken");
    });
  }

  private getResetStructure(): CBox_PublicActionSetPasswordRequestParams {
    return {
      password: this.passwordForm.value.password,
      token: this.route.snapshot.paramMap.get("token") as string
    }
  }
}