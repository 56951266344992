import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CBox_ActionDeleteAgentResponse, CBox_PublicGetAgentDataResponse } from '@server/services/cbox/public/api/v1/resources/agent/types';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_GetUserListResponseItem } from '@server/services/cbox/public/api/v1/resources/user/types';
import { Message, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-cbox-profile-platform-user-password-reset-config-dialog',
  templateUrl: './cbox-profile-platform-user-password-reset-confirm-dialog.component.html',
  styleUrls: ["./cbox-profile-platform-user-password-reset-confirm-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ButtonModule,
    ProgressSpinnerModule,
    CheckboxModule,
    MessagesModule
  ]
})

export class CBoxProfilePlatformUserPasswordResetConfirmDialogComponent implements OnInit {

  reseting = signal(false);
  confirmed = signal(false);

  warnMessage = signal<Message[]>([{
    severity: 'warn',
    detail:
        `Resetarea parolei va deconecta utilizatorul de pe toate dispozitivele pe care este logat și va trebui să își reseteze parola accesând mail-ul trimis ulterior pe adresa de email.`
  }]);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfilePlatformUserPasswordResetConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public user: CBox_GetUserListResponseItem) { }

  ngOnInit(): void {}

  resetPassword(): void {
    this.reseting.set(true);
    this.api.patch("backend/users/" + this.user.identifier + "?triggerPasswordReset=true", undefined).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", `Mail-ul pentru resetare a parolei a fost trimis la adresa ${this.user.email}`);
      this.reseting.set(false);
      this.dialogRef.close(true);
    }, (e: HttpErrorResponse) => {
      this.reseting.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }
}