<p-tabMenu [model]="tabMenuItems()">
  <ng-template pTemplate="item" let-item>
    <ng-container *appRights="item.rights">
      <a [routerLink]="item.routerLink" pRipple class="flex align-items-center gap-2 p-menuitem-link">
        <span class="material-symbols-rounded">{{ item.icon }}</span>
        <span class="font-bold">
          {{ item.label }}
        </span>
      </a>
    </ng-container>
  </ng-template>
</p-tabMenu>
<div class="my-2"></div>
<router-outlet></router-outlet>