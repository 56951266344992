import { CommonModule } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_EntityLoginDevice } from "@server/services/cbox/public/api/v1/resources/user/types";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { getLastActiveMessage } from "src/helpers/functions";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-profile-device-disconnect-dialog",
  templateUrl: "./cbox-profile-device-disconnect-dialog.component.html",
  styleUrls: ["./cbox-profile-device-disconnect-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    AvatarModule,
    ProgressSpinnerModule,
  ]
})

export class CBoxProfileDeviceDisconnectDialogComponent implements OnInit {

  loading = signal(false);

  constructor(
    private dialogRef: MatDialogRef<CBoxProfileDeviceDisconnectDialogComponent>,
    private api: ApiService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public device: CBox_EntityLoginDevice) { }

  ngOnInit() { }

  disconnectDevice(): void {
    this.loading.set(true);
    this.api.auth().delete("backend/users/device/" + this.device.identifier).subscribe(async () => {
      this.dialogRef.close(true);
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  getLastActiveMessage(lastUsedAt: string): string {
    return getLastActiveMessage(lastUsedAt);
  }
}