import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProfileService {

  title = signal("");

  constructor() { }

  setTitle(title: string): void {
    this.title.set(title);
  }

  getTitle(): WritableSignal<string> {
    return this.title;
  }

}