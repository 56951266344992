
<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>
    Atasare
    <span class="text-blue-500">{{ data.equipmentType.name }}</span>
    la
    <span class="text-blue-500">{{ data.module?.serial }}</span>
  </h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <form [formGroup]="form" class="grid grid-nogutter gap-2">
    <div class="col-12">
      <span class="font-bold block mb-1">Tip echipament</span>
      <p-dropdown
        formControlName="equipmentType"
        placeholder="Selectați un tip de echipament"
        styleClass="w-full"
        appendTo="body"
        [options]="equipmentTypes()"
        [loading]="!equipmentTypes()"
      />
    </div>

    <div class="col-12">
      <span class="font-bold block mb-1">Echipament</span>
      <p-dropdown
        optionLabel="name"
        formControlName="equipment"
        placeholder="Selectați echipamentul"
        styleClass="w-full"
        appendTo="body"
        [options]="equipments()"
        [loading]="!equipments()"
      >
        <ng-template let-equipment pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ equipment?.model.name }}#{{ equipment.internalSerial }}</div>
          </div>
        </ng-template>

        <ng-template let-equipment pTemplate="selectedItem">
          <div class="flex align-items-center gap-2">
            <div>{{ equipment?.model.name }}#{{ equipment.internalSerial }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <div class="col-12">
      <span class="font-bold block mb-1">Modul</span>
      @let moduleId = form.get("moduleId");
      @let moduleIdErrors = moduleId?.errors;
      <p-dropdown
        formControlName="moduleId"
        optionLabel="serial"
        optionValue="id"
        placeholder="Selectați un modul"
        styleClass="w-full"
        appendTo="body"
        [options]="modules()"
        [loading]="!modules()"
      />
      @if (moduleId?.dirty) {
        @if (moduleIdErrors?.["required"]) {
          <small class="text-red-500">Vă rugăm să selectați un modul</small>
        } @else {
          <small>Alegeți modulul la care doriți să asociați echipamentul</small>
        }
      } @else {
        <small>Alegeți modulul la care doriți să asociați echipamentul</small>
      }
    </div>

    <div class="col-12">
      <span class="font-bold block mb-1">Actiune realizata de</span>
      <input
        formControlName="actionPerformedBy"
        class="w-full"
        id="awb"
        placeholder="Numele dumneavoastră"
        pInputText
        pAutoFocus
        [autofocus]="true"
      >
      @let name = form.get("name");
      @let nameErrors = form.get("name")?.errors;
      @if (name?.dirty) {
        @if (nameErrors) {
          @if (nameErrors?.["required"]) {
            <small class="text-red-500">Numele agentului este obligatoriu!</small>
          }
        }
      }
    </div>

    <div class="col-12">
      <span class="block font-bold mb-1">Comentarii</span>
      <textarea
        class="w-full"
        rows="5"
        formControlName="notes"
        placeholder="Notes"
        pInputTextarea
      ></textarea>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    pButton
    label="Atasează"
    (click)="link()"
  ></button>
</mat-dialog-actions>
@if (loading() === true) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}