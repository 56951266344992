import { CommonModule, isPlatformBrowser } from "@angular/common";
import { Component, computed, Inject, InjectionToken, PLATFORM_ID, signal } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { SidebarModule } from "primeng/sidebar";
import { ButtonModule } from "primeng/button";
import { ToolbarModule } from "primeng/toolbar";
import { MenuModule } from "primeng/menu";
import { RouterModule } from "@angular/router";
import { ProfileService } from "../../services/profile/profile.service";
import { ProfileNavigationComponent } from "./navigation/profile-navigation.component";
import { SplitButtonModule } from "primeng/splitbutton";
import { MenubarModule } from "primeng/menubar";
import { AvatarModule } from "primeng/avatar";
import { BadgeModule } from "primeng/badge";
import { PanelMenuModule } from "primeng/panelmenu";
import { RedirectToRouteThatHasAccess } from "src/guards/permission.guard";
import { PermissionDirective } from "src/directives/permission.directive";
import { ConfirmationService } from "primeng/api";
import { UserService } from "src/services/user/user.service";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { PermissionNamesEnum } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { CBox_PublicConfigurationBrandingLogoPng } from "@server/services/cbox/public/api/v1/enforcers/company_config/types";
import { LockersService } from "src/services/lockers/lockers.service";
import { AgentsService } from "src/services/agents/agents.service";
@Component({
  selector: "app-cbox-profile-container",
  templateUrl: "./cbox-profile-container.component.html",
  styleUrl: "./cbox-profile-container.component.scss",
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    SidebarModule,
    ButtonModule,
    MenuModule,
    PanelMenuModule,
    ToolbarModule,
    SplitButtonModule,
    MenubarModule,
    AvatarModule,
    BadgeModule,
    PanelMenuModule,
    MatIconModule,
    PermissionDirective,
    ConfirmDialogModule
  ],
  providers: [ConfirmationService]
})
export class ProfileContainerComponent {

  collapsed = signal(true);
  sidenavWidth = computed(() => this.collapsed() ? "92px" : "250px");
  sidenavTimeout?: NodeJS.Timeout;
  brandLogoSrc?: string;
  // YOU SHOULD CREATE A NEW PROPERTY "visible" cuz when the permission
  // are not fetched on the server. they are on the client, so the server renders all the icons
  // and the client hides them. You should hide them from the server. REMOVE VISIBLE TO SEE (and then refresh page)
  menuItems = computed(() => [
    {
      label: "Listă lockere",
      materialIcon: "explore_nearby",
      link: "./lockers/list",
      visible: this.setVisibility(),
      rights: [
        PermissionNamesEnum.viewLockersList,
        PermissionNamesEnum.viewLockerDetails
      ]
    },
    {
      label: "PickUps",
      materialIcon: "unarchive",
      link: "./orders/pick-up/list",
      visible: this.setVisibility(),
      rights: [
        PermissionNamesEnum.viewPickUpsList, PermissionNamesEnum.viewPickUpDetails,
        PermissionNamesEnum.viewPickUpOpenCode, PermissionNamesEnum.createPickUp,
        PermissionNamesEnum.deletePickUp,
      ]
    },
    {
      label: "DropOffs",
      materialIcon: "archive",
      link: "./orders/drop-off/list",
      visible: this.setVisibility(),
      rights: [
        PermissionNamesEnum.viewDropOffsList, PermissionNamesEnum.viewDropOffDetails,
        PermissionNamesEnum.viewDropOffOpenCode, PermissionNamesEnum.deleteDropOff
      ]
    },
    {
      label: "Rapoarte",
      materialIcon: "description",
      link: "./reports/agents",
      visible: this.setVisibility(),
      rights: [
        PermissionNamesEnum.viewReports
      ]
    },
    {
      label: "Rapoarte",
      materialIcon: "description",
      link: "./reports/agents",
      visible: false,
    },
    {
      label: "Zone",
      materialIcon: "stacks",
      link: "./zones",
      visible: this.setVisibility(),
      rights: [
        PermissionNamesEnum.viewZonesList,
        PermissionNamesEnum.createZone
      ]
    },
    {
      label: "Utilizatori",
      materialIcon: "supervisor_account",
      link: "./users/internal",
      visible: this.setVisibility(),
      rights: [
        PermissionNamesEnum.viewPlatformUsers, PermissionNamesEnum.editPlatformUser,
        PermissionNamesEnum.createPlatformUser, PermissionNamesEnum.resetPlatformUserPassword
      ]
    },
    {
      label: "Agenți",
      materialIcon: "engineering",
      link: "./agents/list",
      visible: this.setVisibility(),
      rights: [
        PermissionNamesEnum.viewAgentsList, PermissionNamesEnum.createAgent,
        PermissionNamesEnum.deleteAgent, PermissionNamesEnum.revokeAgentAccess,
        PermissionNamesEnum.regenerateAgentCredentials, PermissionNamesEnum.editAgent
      ]
    },
    {
      label: "Admin",
      materialIcon: "admin_panel_settings",
      link: "./admin/lockers",
      visible: this.setVisibility(),
      rights: [PermissionNamesEnum.systemAdminAll]
    }
  ]);
  publicRoutes = computed(() => [
    {
      label: "Admin",
      materialIcon: "admin_panel_settings",
      link: "./admin/lockers",
      visible: this.setVisibility()
    }
  ]);

  private setVisibility = signal<boolean>(false);
  constructor(public profileService: ProfileService,
              private confirmationService: ConfirmationService,
              private userService: UserService,
              private configuration: ConfigurationService,
              private lockers: LockersService, // Used here for fetching lockers on app load. DO NOT REMOVE
              private agents: AgentsService,  // Used here for fetching lockers on app load. DO NOT REMOVE
              @Inject (PLATFORM_ID) private platformId: InjectionToken<Object>,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      (async () => {
        await RedirectToRouteThatHasAccess(this.menuItems());
      })();
    };
  }

  ngAfterViewInit() {
    this.init();
  }

  toggleSidenav(toggle: boolean): void {
    clearTimeout(this.sidenavTimeout);
    if (toggle) {
      this.collapsed.set(false);
    } else {
      this.sidenavTimeout = setTimeout(() => {
        this.collapsed.set(true);
      }, 300);
    }
  }

  confirmLogout(event: Event): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Sunteți sigur că doriți să vă delogati?',
      header: 'Confirmare',
      icon: 'pi pi-exclamation-circle',
      acceptIcon:"none",
      rejectIcon:"none",
      rejectButtonStyleClass:"p-button-text",
      acceptButtonStyleClass: "p-button-danger",
      acceptLabel: "Deloghează-mă",
      rejectLabel: "Inapoi",
      accept: () => {
        this.userService.logout();
      }
    });
  }

  private async init(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      this.setVisibility.set(true);
    }

    this.brandLogoSrc = (await this.configuration.getConfigKey("branding.logo.png") as CBox_PublicConfigurationBrandingLogoPng).data;
  }
}
