@if (pageStatus() === "validToken") {
  <div class="flex flex-column justify-content-between align-items-center h-full gap-4">
    <div></div>
    <form onsubmit="return false" class="flex flex-column gap-4 w-30rem" [formGroup]="passwordForm" (submit)="resetPassword()">
      <div class="flex justify-content-center align-items-center text-center align-self-center">
        <mat-icon class="logo align-self-center w-20rem h-4rem flex-1" svgIcon="logo"></mat-icon>
        @if (response()?.logo) {
          <span>și</span>
          <div class="flex-1">
            <img class="h-4rem" [src]="response()?.logo?.png?.data" alt="logo">
          </div>
        }
      </div>
      <h1 class="text-center">Creare cont</h1>

      <div>
        <span class="font-bold block mb-1">Parolă nouă</span>
        <input
          type="password"
          autocomplete="off"
          class="w-full"
          id="password"
          formControlName="password"
          placeholder="Parolă"
          pInputText
        >
        @let newPassword = passwordForm.get("password");
        @let newPasswordErrors = newPassword?.errors;
        @if (newPassword?.dirty && newPassword?.touched) {
          @if (newPasswordErrors?.["minlength"]) {
            @let min = newPasswordErrors?.["minlength"]?.requiredLength;
            <small class="text-red-500">
              Parola trebuie să aibă cel puțin {{ min }} caractere
            </small>
          } @else if (newPasswordErrors?.["required"]) {
            <small class="text-red-500">
              Parola este obligatorie
            </small>
          }
        }
      </div>

      <div>
        <span class="font-bold block mb-1">Confirmare parolă nouă</span>
        <input
          type="password"
          autocomplete="off"
          class="w-full"
          id="password"
          formControlName="confirmPassword"
          placeholder="Parolă"
          pInputText
        >
        @let passwordConfirm = passwordForm.get("confirmPassword");
        @let passwordConfirmErrors = passwordConfirm?.errors;
        @if (passwordConfirm?.dirty && passwordConfirm?.touched) {
          @if (passwordConfirmErrors?.["matchOther"]) {
            <small class="text-red-500">
              Parolele nu coincid
            </small>
          } @else if (passwordConfirmErrors?.["required"]) {
            <small class="text-red-500">
              Confirmarea parolei este obligatorie
            </small>
          }
        }
      </div>

      <p-button
        severity="primary"
        styleClass="w-full"
        label="Resetează parola"
        type="submit"
        [disabled]="pageStatus() === 'loading'"
        (click)="$event.preventDefault(); resetPassword()"
      ></p-button>
    </form>
    <div></div>
  </div>
} @else if (pageStatus() === "invalidToken") {
  <div class="grid py-4 h-screen">
    <div class="col-12 flex flex-column justify-content-center text-center gap-4">
      <span class="pi pi-times-circle text-red-500 text-8xl"></span>
      <p class="text-red-500 font-bold text-2xl line-height-2">Link-ul de creare al contului este invalid sau a expirat.</p>
      <p-button
        icon="pi pi-sign-in"
        iconPos="left"
        label="Către autentificare"
        routerLink="/login"
      ></p-button>
    </div>
  </div>
} @else if (pageStatus() === "loading") {
  <div class="blurred-div">
    <p-progressSpinner
      ariaLabel="loading"
      class="blurred-div-spinner"
    ></p-progressSpinner>
  </div>
}

