<p-button
  size="small"
  label="Creează locker"
  icon="pi pi-plus"
  iconPos="left"
  (click)="confirmLockerCreate()"
></p-button>

<form class="grid mt-1" [formGroup]="filtersForm">
  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-1">Lockere</span>
    <p-multiSelect
      formControlName="lockerIdentifiers"
      optionLabel="name"
      optionValue="id"
      styleClass="w-full"
      appendTo="body"
      placeholder="Caută lockere"
      emptyFilterMessage="Nu există lockere"
      emptyMessage="Nu există lockere"
      [showClear]="true"
      [options]="lockers()"
      [loading]="!lockers()"
    >
      <ng-template let-locker pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>
            <small class="text-gray-400">#{{ locker?.id }}</small>
            <p class="m-0">{{ locker?.name }}</p>
          </div>
        </div>
      </ng-template>
    </p-multiSelect>
  </div>

  <div class="flex align-items-center gap-2 col-12 lg:col-3">
    <p-checkbox
      class="mt-1"
      formControlName="isActive"
      label="Active"
      [binary]="true"
    ></p-checkbox>

    <p-checkbox
      class="mt-1"
      formControlName="isConfigured"
      label="Configurate"
      [binary]="true"
    ></p-checkbox>
  </div>
</form>

<p-table
  styleClass="p-datatable-sm w-full"
  currentPageReportTemplate="{first} - {last} din {totalRecords} lockere"
  paginatorPosition="both"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [paginator]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [value]="lockersList()"
  [rowsPerPageOptions]="[25, 50, 100]"
  [showCurrentPageReport]="true"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (fetching() === true) {
      <tr>
        <td colspan="4">
          <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" />
        </td>
      </tr>
    }
    <tr>
      <th>Locker</th>
      <th></th>
      <th class="text-center">Versiune soft</th>
      <th class="text-center">Număr componente</th>
      <th class="text-center">Număr module</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-locker>
    <tr
      [ngClass]="{
        'borderless-row': showBoxesReport(),
      }"
    >
      <td>
        <div class="flex flex-column">
          <div>
            <small class="text-gray-400">#{{ locker.externalIdentifier }}</small><br>
            <b>{{ locker.name }}</b>
            <br>
            <small>{{ formatLockerAddress(locker.addressParts) }}</small>
          </div>
        </div>
      </td>
      <td class="text-center">
        <div class="flex align-items-center gap-1">
          <p-tag
            [value]="locker.state.isConfigured ? 'Configurat' : 'Neconfigurat'"
            [severity]="locker.state.isConfigured ? 'success' : 'danger'"
          ></p-tag>
          <p-tag
            [value]="locker.state.isOnline ? 'Online' : 'Offline'"
            [severity]="locker.state.isOnline ? 'success' : 'danger'"
          ></p-tag>
        </div>
      </td>
      <td class="text-center">{{ locker.softwareVersion.name }}({{ locker.softwareVersion.code }})</td>
      <td class="text-center">{{ locker.equipments.count }}</td>
      <td class="text-center">{{ locker.modules.count }}</td>
      <td class="text-right">
        <div class="card flex justify-content-end">
          <p-menu
            styleClass="w-15rem"
            #agentMenu
            appendTo="body"
            [popup]="true"
            [model]="lockerMenuItems()"
          />
          <p-button
            size="small"
            icon="pi pi-ellipsis-v"
            [plain]="true"
            [text]="true"
            [rounded]="true"
            (click)="$event.stopPropagation(); selectedLocker.set(locker); agentMenu.toggle($event)"
          ></p-button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>