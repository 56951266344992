import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, Inject, InjectionToken, OnInit, PLATFORM_ID, computed, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CBox_PublicErrorResponse, CBox_PublicPaginatedResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_GetApiUserListResponseItem } from '@server/services/cbox/public/api/v1/resources/user/types';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule, TablePageEvent } from 'primeng/table';
import { debounceTime, first } from 'rxjs';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ProfileService } from 'src/services/profile/profile.service';
import { SessionService } from 'src/services/session/session.service';
import { CBoxProfileApiUserCreateComponent } from './dialogs/create/cbox-profile-api-user-create.component';
import { CBoxProfileApiUserEditComponent } from './dialogs/edit/cbox-profile-api-user-edit.component';
import { CBoxProfileApiUserRegenerateSecretDialogComponent } from './dialogs/regenerate-secret/cbox-profile-api-user-regenerate-secret-dialog.component';
import { CBoxProfileApiUserDeleteDialogComponent } from './dialogs/delete/cbox-profile-api-user-delete-dialog.component';
import { getLastActiveMessage } from 'src/helpers/functions';
import { ToastService } from 'src/services/toast/toast.service';
import { PermissionDirective } from 'src/directives/permission.directive';
import { PermissionNamesEnum } from '@server/services/cbox/public/api/v1/enforcers/entity_permission/types';
import { ConfigurationService } from 'src/services/configuration/configuration.service';

@Component({
  selector: 'app-cbox-profile-api-users-list',
  templateUrl: './cbox-profile-api-users-list.component.html',
  styleUrls: ["./cbox-profile-api-users-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    ReactiveFormsModule,
    MenuModule,
    ProgressBarModule,
    InputTextModule,
    PermissionDirective
  ]
})

export class CBoxProfileApiUsersListComponent implements OnInit {
  filtersForm: FormGroup;
  apiUsers = signal<CBox_GetApiUserListResponseItem[]>([]);
  apiUsersFetchStatus = signal<"fetching" | "success" | "error">("fetching");
  selectedApiUser = signal<CBox_GetApiUserListResponseItem | undefined>(undefined);
  hasPermission = (permission: string) => computed(() => {
    return this.configuration.hasAccess([permission]);
  });
  apiUserMenuItems = computed<MenuItem[]>(() => [
    {
      label: "Regenerează credențiale",
      icon: "pi pi-pencil",
      visible: this.hasPermission(PermissionNamesEnum.regenerateApiUserCredentials)(),
      command: () => {
        this.regenerateApiUserCredentials(this.selectedApiUser());
      }
    },
    {
      label: "Editează utilizator",
      icon: "pi pi-pencil",
      visible: this.hasPermission(PermissionNamesEnum.editApiUser)(),
      command: () => {
        this.editApiUser(this.selectedApiUser());
      }
    },
    {
      label: "Sterge utilizator",
      icon: "pi pi-trash",
      visible: this.hasPermission(PermissionNamesEnum.deleteApiUser)(),
      command: () => {
        this.deleteApiUser(this.selectedApiUser());
      }
    }
  ]);
  permissions = PermissionNamesEnum;
  totalCount = signal(0);

  constructor(
    private profileService: ProfileService,
    private api: ApiService,
    private fb: FormBuilder,
    private session: SessionService,
    private dialog: MatDialog,
    private destroyRef: DestroyRef,
    private toastService: ToastService,
    private configuration: ConfigurationService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.filtersForm = this.fb.group({
        name: [""],
        page: [1],
        pageSize: [25]
      });
    }

  ngOnInit(): void {
    this.init();
  }

  loadUsers(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.fetchApiUsers();
    }
  }

  addUser(): void {
    const dialog = this.dialog.open(CBoxProfileApiUserCreateComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe((created: boolean) => {
      if (created) {
        this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost adăugat cu succes!");
        this.loadUsers();
      }
    });
  }

  editApiUser(apiUser: CBox_GetApiUserListResponseItem | undefined): void {
    if (!apiUser) {
      return;
    }
    const dialog = this.dialog.open(CBoxProfileApiUserEditComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false,
      data: apiUser.identifier
    });

    dialog.afterClosed().pipe(first()).subscribe((edited: boolean) => {
      if (edited) {
        this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost editat cu succes!");
        this.loadUsers();
      }
    });
  }

  regenerateApiUserCredentials(apiUser: CBox_GetApiUserListResponseItem | undefined): void {
    if (!apiUser) {
      return;
    }
    const dialog = this.dialog.open(CBoxProfileApiUserRegenerateSecretDialogComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false,
      data: apiUser
    });

    dialog.afterClosed().pipe(first()).subscribe((regenerated: boolean) => {
      if (regenerated) {
        this.loadUsers();
      }
    });
  }

  deleteApiUser(apiUser: any): void {
    if (!apiUser) {
      return;
    }
    const dialog = this.dialog.open(CBoxProfileApiUserDeleteDialogComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false,
      data: apiUser
    });

    dialog.afterClosed().pipe(first()).subscribe((deleted: boolean) => {
      if (deleted) {
        this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost șters cu succes!");
        this.loadUsers();
      }
    });
  }

  pageChanged(event: TablePageEvent): void {
    const page = event.first / event.rows;
    this.filtersForm.patchValue({
      page: page + 1,
      pageSize: event.rows
    });
    this.saveFilters();
  }

  getLastActiveMessage(lastUsedAt: string): string {
    return getLastActiveMessage(lastUsedAt);
  }

  private async init(): Promise<void> {
    this.profileService.setTitle("Listă utilizatori");
    this.filtersForm.valueChanges.pipe(
      debounceTime(500),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.saveFilters();
      this.loadUsers();
    });

    if (isPlatformBrowser(this.platformId)) {
      this.patchCachedFilters();
      this.loadUsers();
    }
  }

  private fetchApiUsers(): void {
    this.api.auth().get<CBox_PublicPaginatedResponse<CBox_GetApiUserListResponseItem>>("backend/users/api").subscribe((response) => {
      this.apiUsers.set(response.data);
      this.totalCount.set(response.totalCount);
      this.apiUsersFetchStatus.set("success");
    }, (e: HttpErrorResponse) => {
      this.apiUsersFetchStatus.set("error");
      handlePublicApiError(e, this.toastService);
    });
  }

  private saveFilters(): void {
    const filtersValues = this.filtersForm.value;
    const sessionStorageFilters = {
      name: filtersValues.name,
    };
    this.session.setVal("api-users-list/filters/session", JSON.stringify(sessionStorageFilters));
  }

  private patchCachedFilters(): void {
    const sessionStorageFilters = this.session.getVal("api-users-list/filters/session", undefined);
    if (sessionStorageFilters) {
      const parsed = JSON.parse(sessionStorageFilters);
      this.filtersForm.patchValue(parsed, {
        emitEvent: false
      });
    }
  }

  private getSearchStructure(): any {
    const data = {
      name: this.filtersForm.value.name,
    };
    return Object.entries(data)
    .reduce((acc, [key, value]) => {
      acc[key as any] = value;
      return acc;
    }, {} as any);
  }
}