
<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Creare locker</h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    size="small"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <p>Urmeaza sa creati un nou locker. Continuati?</p>
</mat-dialog-content>
<mat-dialog-actions class="flex gap-2 align-items-center">
  <button
    pButton
    mat-dialog-close
    size="small"
    class="w-full sm:w-max"
    label="Inapoi"
    [text]="true"
  ></button>
  <button
    pButton
    size="small"
    class="w-full sm:w-max"
    label="Creează locker"
    icon="pi pi-plus"
    iconPos="right"
    (click)="create()"
  ></button>
  </mat-dialog-actions>
@if (loading() === true) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}