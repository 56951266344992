import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class RoPaginatorIntlComponent extends MatPaginatorIntl {
  constructor() {
    super();

    this.getAndInitTranslations();
  }

  getAndInitTranslations(): void {
    this.itemsPerPageLabel = "Elemente pe pagină:";
    this.nextPageLabel = "Pagina următoare";
    this.previousPageLabel = "Pagina anterioară";
    this.changes.next();
  }
}