import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_PublicRolePermissionMapping } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_ActionCreateApiUserData, CBox_ActionCreateApiUserResponse } from "@server/services/cbox/public/api/v1/resources/user/types";
import { AutoFocusModule } from "primeng/autofocus";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TableModule } from "primeng/table";
import { copyString } from "src/helpers/functions";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { LockersService } from "src/services/lockers/lockers.service";
import { ProfileService } from "src/services/profile/profile.service";
import { ToastService } from "src/services/toast/toast.service";
import { UserService } from "src/services/user/user.service";

@Component({
  selector: "app-cbox-profile-user-create",
  templateUrl: "cbox-profile-api-user-create.component.html",
  styleUrls: ["./cbox-profile-api-user-create.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    DividerModule,
    InputGroupModule,
    InputGroupAddonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    AutoFocusModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    ProgressSpinnerModule,
    InputTextareaModule
  ]
})

export class CBoxProfileApiUserCreateComponent implements OnInit {

  apiUserCreateStatus = signal<"waiting" | "created" | "creating">("waiting");
  apiUserCreateResponse = signal<CBox_ActionCreateApiUserResponse | undefined>(undefined);
  apiUserForm: FormGroup;
  roles = signal<CBox_PublicRolePermissionMapping | undefined>(undefined);

  clientSecretCopied = signal(false);
  confirmation = new FormControl(false, Validators.requiredTrue);

  constructor(
    private profileService: ProfileService,
    private api: ApiService,
    private fb: FormBuilder,
    private userService: UserService,
    private lockerService: LockersService,
    private config: ConfigurationService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfileApiUserCreateComponent>,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.apiUserForm = this.fb.group({
        description: [""],
        role: ["", Validators.required],
      })
    }

  ngOnInit(): void {
    this.init();
  }

  loadData(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.fetchUserData();
    }
  }

  toggleAllLockers(): void {
    const f = this.apiUserForm;
    if (f.get("lockers.all")?.value) {
      f.get("lockers.specific")?.disable();
      f.get("lockers.specific")?.patchValue([], {
        emitEvent: false
      })
    } else {
      f.get("lockers.specific")?.enable();
    }
  }

  createUser(): void {
    this.apiUserCreateStatus.set("creating");
    this.api.post<CBox_PublicSuccessResponse<CBox_ActionCreateApiUserResponse>>("backend/users/api", this.getCreateStructure()).subscribe((response) => {
      this.apiUserCreateResponse.set(response.data);
      this.apiUserCreateStatus.set("created");
    }, (e: HttpErrorResponse) => {
      this.apiUserCreateStatus.set("waiting");
      handlePublicApiError(e, this.toastService);
    });
  }

  copyClientSecret(): void {
    if (this.clientSecretCopied()) {
      return;
    }
    copyString(this.apiUserCreateResponse()?.clientSecret!);
    this.clientSecretCopied.set(true);
    setTimeout(() => {
      this.clientSecretCopied.set(false);
    }, 2000);
  }

  private async init(): Promise<void> {
    this.profileService.setTitle("Detalii cont");
    const roles = await this.config.getRolePermissonMapping()
    this.roles.set(this.filterRoles(roles));
    this.loadData();
  }

  private fetchUserData(): void {
    this.userService.getData();
  }

  private filterRoles(roles: CBox_PublicRolePermissionMapping): CBox_PublicRolePermissionMapping {
    return roles.filter((role) => role.canCreate);
  }

  private getCreateStructure(): CBox_ActionCreateApiUserData {
    const data: CBox_ActionCreateApiUserData = {
      role: this.apiUserForm.get("role")?.value,
      description: this.apiUserForm.get("description")?.value,
    };

    return data;
  }
}