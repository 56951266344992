import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CBox_PublicGetCodeDataResponseFormatted } from 'app/open/cbox-open-page-container.component';

@Component({
  selector: 'app-cbox-default-drop-off-open-page',
  templateUrl: './cbox-default-drop-off-open-page.component.html',
  styleUrls: ['./cbox-default-drop-off-open-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ]
})

export class CBoxDefaultDropOffOpenPageComponent implements OnInit {
  @Input() response: CBox_PublicGetCodeDataResponseFormatted | undefined;

  constructor() { }

  ngOnInit() { }
}