import { Schema, ValidateFunction } from "ajv";

export enum PermissionNamesEnum {
  lockersMaster = "lockers",
  viewLockersList = "lockers.view_list",
  viewLockerDetails = "lockers.view_details",
  viewLockerPickUps = "lockers.view_pickups",
  viewLockerDropOffs = "lockers.view_dropoffs",

  pickUpRequestsMaster = "requests.pick_up",
  viewPickUpsList = "requests.pick_up.view_list",
  viewPickUpDetails = "requests.pick_up.view_details",
  viewPickUpOpenCode = "requests.pick_up.view_open_code",
  createPickUp = "requests.pick_up.create",
  deletePickUp = "requests.pick_up.delete",
  extendPickUp = "requests.pick_up.extend",
  reactivatePickUp = "requests.pick_up.reactivate",

  dropOffRequestsMaster = "requests.drop_off",
  viewDropOffsList = "requests.drop_off.view_list",
  viewDropOffDetails = "requests.drop_off.view_details",
  viewDropOffOpenCode = "requests.drop_off.view_open_code",
  deleteDropOff = "requests.drop_off.delete",

  reportsMaster = "reports.master",
  viewReports = "reports.view_list",
  viewReportData = "reports.view_data",

  agentMaster = "agents",
  viewAgentsList = "agents.view_list",
  createAgent = "agents.create",
  editAgent = "agents.edit",
  deleteAgent = "agents.delete",
  regenerateAgentCredentials = "agents.regenerate_credentials",
  revokeAgentAccess = "agents.revoke_access",
  canViewDeleted = "agents.can_view_deleted",
  allowAgentDifferentZone = "agents.allow_different_zone",

  platformUsersMaster = "users.platform",
  viewPlatformUsers = "users.platform.view_list",
  createPlatformUser = "users.platform.create",
  editPlatformUser = "users.platform.edit",
  resetPlatformUserPassword = "users.platform.reset_password",
  deletePlatformUser = "users.platform.delete",

  apiUsersMaster = "users.api",
  viewApiUsers = "users.api.view_list",
  createApiUser = "users.api.create",
  editApiUser = "users.api.edit",
  deleteApiUser = "users.api.delete",
  regenerateApiUserCredentials = "users.api.regenerate_credentials",

  zonesMaster = "zones.master",
  viewZonesList = "zones.view_list",
  createZone = "zones.create",
  updateZone = "zones.update",

  systemAdminMaster = "system_admin",
  systemAdminAll = "system_admin.all",
  seeNotConfiguredLockers = "system_admin.see_not_configured_lockers",
}

export type CBox_BooleanPermissionValue = {
  value: boolean;
};

export type CBox_MasterPermissionValue = {
  value: boolean;
};

export type CBox_PublicPermTypes = "MASTER" | "BOOLEAN";

export type CBox_PublicBooleanPermDef = {
  name: string;
  type: "BOOLEAN";
  value: boolean;
  valueStruct: CBox_BooleanPermissionValue;
  schema: CBox_PublicPermNoSchema;
};

export type CBox_PublicMasterPermDef = {
  type: "MASTER"; value: boolean;
};

export type CBox_PublicPermNoSchema = {};

export type CBox_PublicPermDef = CBox_PublicBooleanPermDef;

export type CBox_PublicPermissionDefinition = {
  [key: string]: CBox_PublicPermDef;
};

export type CBox_PublicPermArray = string[];

export type CBox_PublicPermissionCondition = {
  ["OR"]?: (CBox_PublicPermissionCondition | string)[];
  ["AND"]?: (CBox_PublicPermissionCondition | string)[];
};

export type CBox_PublicPermissionSchema = {
  schema: Schema;
  disabledBy: CBox_PublicPermissionCondition;
  validator?: ValidateFunction<unknown>;
};

export type CBox_PublicRolePermissionMapping = {
  roleName: string;
  canCreate: boolean;
  canView: boolean;
  canEdit: boolean;
  canEditLockers: boolean;
}[];

export type CBox_CachedEntityPermissions = {
  definitions: CBox_PublicPermissionDefinition;
  dataArray: CBox_PublicPermArray;
  allowedLockers: {id: number; externalIdentifier: string; name: string}[];
  zonesForLockers: Record<number, {id: number, name: string}[]>;
  rolePermissionMapping: CBox_PublicRolePermissionMapping;
  zones: {id: number; name: string}[];
  currentZone: {id: number; name: string};
};

export type CBox_ZoneData = {
  id: number;
  name: string;
  parentZoneId: number | null;
  lockers: {
    id: number;
    name: string;
    externalIdentifier: string;
  }[];
};