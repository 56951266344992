<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Editează utilizator API</h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <form [formGroup]="userForm" class="grid grid-nogutter gap-2">
    <div class="col-12">
      <span class="block font-bold mb-1">Rol</span>
      <p-dropdown
        optionLabel="roleName"
        optionValue="roleName"
        styleClass="w-full"
        placeholder="Selectati un rol"
        formControlName="role"
        [options]="roles()"
        [loading]="!roles()"
      ></p-dropdown>
    </div>

    <div class="col-12">
      <span class="font-bold block mb-1">Notițe</span>
      <textarea
        class="w-full"
        rows="5"
        cols="30"
        pInputTextarea
        formControlName="description"
      ></textarea>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    pButton
    class="w-full"
    label="Salvează"
    [disabled]="!userForm.valid"
    (click)="saveUser()"
  ></button>
</mat-dialog-actions>
@if (apiUserCreateStatus() === "creating") {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}
