import { CommonModule, isPlatformBrowser } from "@angular/common";
import { ChangeDetectorRef, Component, DestroyRef, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { CalendarModule } from "primeng/calendar";
import { DropdownModule } from "primeng/dropdown";
import { InputNumberModule } from "primeng/inputnumber";
import { FloatLabelModule } from "primeng/floatlabel";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { CheckboxModule } from "primeng/checkbox";
import { MessagesModule } from "primeng/messages";
import { TooltipModule } from "primeng/tooltip";
import { KeyFilterModule } from "primeng/keyfilter";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputMaskModule } from "primeng/inputmask";
import { RadioButtonModule } from "primeng/radiobutton";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import {
  CBox_ActionCreatePickUpData,
  CBox_ActionCreatePickUpResponse
} from "@server/services/cbox/public/api/v1/resources/pick_up/types";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { markFormDirty } from "src/helpers/functions";
import { HttpErrorResponse } from "@angular/common/http";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { CBox_GetLockerBaseData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { LockersService } from "src/services/lockers/lockers.service";
import { ToastService } from "src/services/toast/toast.service";
import { AutoFocusModule } from "primeng/autofocus";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { CBox_PublicConfigurationRequestReservation } from "@server/services/cbox/public/api/v1/enforcers/company_config/types";
import { CBox_GetPredefinedParcelListItemDataResponse } from "@server/services/cbox/public/api/v1/resources/predefined_parcel/types";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Observable, merge } from "rxjs";
import { SkeletonModule } from "primeng/skeleton";

@Component({
  selector: "app-cbox-profile-create-pickup-request",
  templateUrl: "./cbox-profile-create-pickup-request.component.html",
  styleUrls: ["./cbox-profile-create-pickup-request.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    InputTextModule,
    DropdownModule,
    ButtonModule,
    CalendarModule,
    InputNumberModule,
    FloatLabelModule,
    InputGroupModule,
    InputGroupAddonModule,
    CheckboxModule,
    MessagesModule,
    CalendarModule,
    TooltipModule,
    KeyFilterModule,
    InputSwitchModule,
    InputMaskModule,
    RadioButtonModule,
    ProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    AutoFocusModule,
    SkeletonModule
  ]
})

export class CBoxProfileCreatePickupRequestComponent implements OnInit {

  form: FormGroup;

  placingOrder = signal(false);

  fetchingLockers = signal(false);
  lockers = signal<any[]>([]);

  predefinedParcels = signal<CBox_GetPredefinedParcelListItemDataResponse[]>([]);
  fetchingPredefinedParcels = signal(true);
  selectedParcel = signal<CBox_GetPredefinedParcelListItemDataResponse | undefined>(undefined);

  today = signal(new Date());

  get reservationForm(): FormGroup {
    return this.form.get("reservation") as FormGroup;
  }

  get smsNotifyForm(): FormGroup {
    return this.form.get("notify")?.get("sms") as FormGroup;
  }

  get emailNotifyForm(): FormGroup {
    return this.form.get("notify")?.get("email") as FormGroup;
  }

  get packageForm(): FormGroup {
    return this.form.get("parcel") as FormGroup;
  }

  get valabilityForm(): FormGroup {
    return this.form.get("retention") as FormGroup;
  }

  constructor(
    private api: ApiService,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CBoxProfileCreatePickupRequestComponent>,
    private fb: FormBuilder,
    private lockerService: LockersService,
    private toastService: ToastService,
    private configuration: ConfigurationService,
    private destroyRef: DestroyRef,
    @Inject(MAT_DIALOG_DATA) private locker: CBox_GetLockerBaseData,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.form = this.fb.group({
        uniqueReference: ["", [Validators.minLength(5), Validators.maxLength(50)]],
        awb: ["", [Validators.required, Validators.maxLength(20)]],
        locker: [undefined, Validators.required],
        senderName: [""],
        recipientName: [""],
        parcel: this.fb.group({
          width: [0, [Validators.required, Validators.min(1)]],
          height: [0, [Validators.required, Validators.min(1)]],
          length: [0, [Validators.required, Validators.min(1)]],
          weight: [0]
        }),
        reservation: this.fb.group({
          enabled: [false],
          datetime: [{ value: undefined, disabled: true }, [Validators.required]],
          offset: [{ value: undefined, disabled: true }, Validators.required]
        }),
        retention: this.fb.group({
          offset: [24, Validators.required]
        }),
        notify: this.fb.group({
          sms: this.fb.group({
            enabled: [false],
            phoneNumber: [{ value: "", disabled: true }]
          }),
          email: this.fb.group({
            enabled: [false],
            email: [{ value: "", disabled: true }]
          })
        })
      });
    }

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      this.fetchPredefinedParcels();
      const lockers = await this.lockerService.getList();
      this.lockers.set(lockers);
      if (this.locker) {
        const locker = this.lockers().find((l: CBox_GetLockerBaseData) => l.id === this.locker.id);
        if (locker) {
          this.form.get("locker")?.patchValue(locker);
          this.form.get("locker")?.disable();
        }
      }
    }
    this.form.get("reservation")?.get("datetime")?.patchValue(new Date());
    const length = this.packageForm.get("length");
    const width = this.packageForm.get("width");
    const height = this.packageForm.get("height");
    const observables: Observable<any>[] = [length?.valueChanges, width?.valueChanges, height?.valueChanges]
      .filter((obs): obs is Observable<any> => !!obs);

    merge(...observables)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const dimensions = {
          length: length?.value,
          width: width?.value,
          height: height?.value
        };
        this.selectedParcel.set(undefined);
        this.predefinedParcels().forEach((parcel) => {
          if (parcel.length === dimensions.length && parcel.width === dimensions.width && parcel.height === dimensions.height) {
            this.selectedParcel.set(parcel);
          }
        });
      });
    await this.setFormValidators();
  }

  emailNotifyToggled(checked: boolean): void {
    const email = this.emailNotifyForm.get("email");
    if (checked) {
      email?.enable();
    } else {
      email?.reset();
      email?.disable();
    }
    email?.updateValueAndValidity();
  }

  smsNotifyToggled(checked: boolean): void {
    const phoneNumber = this.smsNotifyForm.get("phoneNumber");
    if (checked) {
      phoneNumber?.enable();
    } else {
      phoneNumber?.reset();
      phoneNumber?.disable();
    }
    phoneNumber?.updateValueAndValidity();
  }

  async boxReservationToggled(checked: boolean): Promise<void> {
    const offset = this.reservationForm.get("offset");
    const reservationHours = await this.configuration.getConfigKey(
                                 'request.pickUp.reservationHours') as
        CBox_PublicConfigurationRequestReservation;
    if (checked) {
      offset?.enable();
      offset?.patchValue(reservationHours.value);
    } else {
      offset?.reset();
      offset?.disable();
    }
  }

  placeOrder(): void {
    if (this.form.invalid) {
      markFormDirty(this.form);
      return;
    }
    const data = this.getCreateStructure();
    this.placingOrder.set(true);
    this.performOrderPlacement(data);
  }

  getDateWithOffset(offset: number): Date {
    if (!offset) {
      return new Date();
    }
    const date = new Date();
    date.setHours(date.getHours() + offset);
    return date;
  }

  selectPredefinedParcel(parcel: CBox_GetPredefinedParcelListItemDataResponse): void {
    this.packageForm.patchValue(parcel);
    this.selectedParcel.set(parcel);
  }

  private async setFormValidators(): Promise<void> {
    const reservationHours = await this.configuration.getConfigKey(
                                 'request.pickUp.reservationHours') as
        CBox_PublicConfigurationRequestReservation;

    const reservationOffset = this.reservationForm.get("offset");
    reservationOffset?.setValidators([
      Validators.required, Validators.min(reservationHours.minimum),
      Validators.max(reservationHours.maximum)
    ]);
  }

  private performOrderPlacement(data: CBox_ActionCreatePickUpData): void {
    this.api.auth().post<CBox_PublicSuccessResponse<CBox_ActionCreatePickUpResponse>>("backend/request/pick-up", data).subscribe((response) => {
      this.dialogRef.close(true);
      this.toastService.showSuccessToast("Confirmare", "Comanda a fost plasată cu succes!");
    }, (e: HttpErrorResponse) => {
      this.placingOrder.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  private fetchPredefinedParcels(): void {
    this.api.auth().get<CBox_PublicSuccessResponse<CBox_GetPredefinedParcelListItemDataResponse[]>>("backend/request/predefined-parcel").subscribe((response) => {
      this.predefinedParcels.set(response.data);
      this.fetchingPredefinedParcels.set(false);
    }, (e: HttpErrorResponse) => {
      this.fetchingPredefinedParcels.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  private getCreateStructure(): CBox_ActionCreatePickUpData {
    const controls = this.form.controls;
    const data: CBox_ActionCreatePickUpData = {
      awb: controls["awb"].value as string,
      uniqueReference: controls["uniqueReference"]?.value?.length ? controls["uniqueReference"]?.value : undefined,
      locker: {
        id: "CO0001" ?? ""
      },
      ...(controls["senderName"]?.value?.length && controls["recipientName"]?.value?.length ?
        {
          contactInfo: {
            ...(controls["senderName"]?.value?.length ? {
              sender: {
                name: controls["senderName"]?.value,
              }
            } : {}),
            ...(controls["recipientName"]?.value?.length ? {
              recipient: {
                name: controls["recipientName"]?.value,
              }
            } : {})
          }
        } : undefined
      ),
      parcel: {
        ...(this.selectedParcel() ? {
          predefinedBoxIdentifier: this.selectedParcel()!.identifier,
          weight: controls["parcel"].get("weight")?.value?? 0
        } : {
          width: controls["parcel"].get("width")?.value ?? 0,
          height: controls["parcel"].get("height")?.value ?? 0,
          length: controls["parcel"].get("length")?.value ?? 0,
          weight: controls["parcel"].get("weight")?.value?? 0
        })
      },
      reservation: {
        enabled: !!controls["reservation"].get("enabled")?.value,
        offset: controls["reservation"].get("offset")?.value ?? undefined
      },
      retention: {
        offset: controls["retention"].get("offset")?.value ?? 24
      },
      notify: {
        sms: {
          enabled: !!controls["notify"].get("sms")?.get("enabled")?.value,
          phone: controls["notify"].get("sms")?.get("phoneNumber")?.value ?? ""
        },
        email: {
          enabled: !!controls["notify"].get("email")?.get("enabled")?.value,
          email: controls["notify"].get("email")?.get("email")?.value ?? ""
        }
      }
    };

    return data;
  }
}