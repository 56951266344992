import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { CBox_PublicPaginatedResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";
import { CBox_GetZoneListResponseItem } from "@server/services/cbox/public/api/v1/resources/zone/types";
import { ProfileService } from "src/services/profile/profile.service";
import { TableModule } from "primeng/table";
import { ButtonModule } from "primeng/button";
import { MatDialog } from "@angular/material/dialog";
import { CBoxProfileZoneCreateDialogComponent } from "../dialogs/create/cbox-profile-zone-create-dialog.component";
import { first } from "rxjs";
import { PermissionNamesEnum } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CBox_GetApiUserListRequestParams } from "@server/services/cbox/public/api/v1/resources/user/types";
import { CBoxProfileZoneUpdateDialogComponent } from "../dialogs/update/cbox-profile-zone-update-dialog.component";
import { ProgressBarModule } from "primeng/progressbar";
import { PermissionDirective } from "src/directives/permission.directive";
import { UserService } from "src/services/user/user.service";

@Component({
  selector: "app-cbox-profile-zones-list",
  templateUrl: "./cbox-profile-zones-list.component.html",
  styleUrls: ["./cbox-profile-zones-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    ProgressBarModule,
    PermissionDirective
  ]
})

export class CBoxProfileZonesListComponent implements OnInit {

  filtersForm: FormGroup;
  zones = signal<CBox_GetZoneListResponseItem[]>([])
  fetchingZones = signal(true);
  totalCount = signal(0);
  currentUserZone = signal<number>(-1);

  permissions = PermissionNamesEnum;

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private profileService: ProfileService,
    private dialog: MatDialog,
    private configuration: ConfigurationService,
    private fb: FormBuilder,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.filtersForm = this.fb.group({
        page: [1],
        pageSize: [100]
      });
    }

  ngOnInit(): void {
    this.init();
  }

  private async init(): Promise<void> {
    this.profileService.setTitle("Listă zone");
    this.currentUserZone.set((await this.userService.getData()).data?.zoneId || -1);
    this.loadZones();
  }

  loadZones(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.fetchingZones.set(true);
      this.fetchZones();
    }
  }

  createZone(): void {
    if (!this.configuration.hasAccess([PermissionNamesEnum.createZone])) {
      this.toastService.showErrorToast("Acces interzis", "Nu aveți permisiunile necesare pentru a crea o zonă nouă");
      return;
    }
    const dialog = this.dialog.open(CBoxProfileZoneCreateDialogComponent, {
      width: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe((created) => {
      if (created) {
        this.loadZones();
      }
    });
  }

  updateZone(zone: CBox_GetZoneListResponseItem): void {
    const dialog = this.dialog.open(CBoxProfileZoneUpdateDialogComponent, {
      data: zone.id,
      width: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loadZones();
    });
  }

  private fetchZones(): void {
    this.api.auth().get<CBox_PublicPaginatedResponse<CBox_GetZoneListResponseItem>>("backend/zones", this.getSearchStructure()).subscribe((response) => {
      this.zones.set(response.data);
      this.totalCount.set(response.totalCount);
      this.fetchingZones.set(false);
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
    });
  }

  getSearchStructure(): CBox_GetApiUserListRequestParams {
    const data: CBox_GetApiUserListRequestParams = {
      page: this.filtersForm.value.page,
      pageSize: this.filtersForm.value.pageSize
    };

    return data;
  }
}