import { CommonModule, isPlatformBrowser } from "@angular/common";
import { AfterViewInit, Component, DestroyRef, Inject, InjectionToken, PLATFORM_ID, signal, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { MultiSelect, MultiSelectModule } from "primeng/multiselect";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { CalendarModule } from "primeng/calendar";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_GetLockerListIdentifierResponse, CBox_GetLockerShortData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { TooltipModule } from "primeng/tooltip";
import { CheckboxModule } from 'primeng/checkbox';
import { CBox_ActionCreateAgentResponse, CBox_PublicGetAgentDataResponse, CBox_ActionUpdateAgentData } from "@server/services/cbox/public/api/v1/resources/agent/types";
import { ValidateEmail } from "src/validators/async-email-validator";
import { MessagesModule } from "primeng/messages";
import { FormattedPermission } from "../../types";
import { Message } from "primeng/api";
import { copyString, markFormDirty } from "src/helpers/functions";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { LockersService } from "src/services/lockers/lockers.service";
import { CBox_PublicCompanyConfigAgentActionList, CBox_PublicCompanyConfigAgentPermittedAction } from "@server/services/cbox/public/api/v1/enforcers/company_config/types";
import { ToastService } from "src/services/toast/toast.service";
import { AutoFocusModule } from "primeng/autofocus";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "app-cbox-profile-agent-update",
  templateUrl: "./cbox-profile-agent-update.component.html",
  styleUrls: ["./cbox-profile-agent-update.component.scss"],
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    InputTextModule,
    ButtonModule,
    ReactiveFormsModule,
    CommonModule,
    DropdownModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    CalendarModule,
    InputGroupModule,
    InputGroupAddonModule,
    IconFieldModule,
    InputIconModule,
    TooltipModule,
    CheckboxModule,
    MessagesModule,
    AutoFocusModule
  ]
})

export class CBoxProfileAgentUpdateComponent implements AfterViewInit {

  agentForm: FormGroup;
  loading = signal(true);

  get lockersForm(): FormGroup {
    return this.agentForm.get("lockers") as FormGroup;
  }

  lockers = signal<CBox_GetLockerListIdentifierResponse | undefined>(undefined);
  fetchingLockers = signal<"fetching" | "success" | "error" | "waiting">("waiting");

  permissions = signal<FormattedPermission[] | undefined>(undefined);

  agentUpdateStatus = signal<"updating" | "created" | "waiting">("waiting");

  today = signal(new Date());

  createResponse = signal<CBox_ActionCreateAgentResponse | undefined>(undefined);
  courierCodeCopied = signal(false);

  confirmation = new FormControl(false, Validators.requiredTrue);
  oldUniqueIdentifier = signal<string | undefined>(undefined);
  allowedZones = signal<{ id: number; name: string }[]>([]);
  zonesFetched = signal(false);
  groupedLockers = signal<Record<CBox_GetLockerShortData["id"], CBox_GetLockerShortData[]>>({});

  showIdentifierWarning = signal(false);

  identifierChangeWarning = signal<Message[]>([{
    severity: 'warn',
    detail:
        `Atentie! Modificarea identificatorului nu este recomandata. Vom folosi noul identificator pentru rapoartele generate ulterior inclusiv pentru actiunile agentului din trecut.`
  }]);

  @ViewChild("lockersMultiSelect") lockersMultiSelect?: MultiSelect;

  constructor(
    private api: ApiService,
    private config: ConfigurationService,
    private toastService: ToastService,
    private lockersService: LockersService,
    private dialogRef: MatDialogRef<CBoxProfileAgentUpdateComponent>,
    private fb: FormBuilder,
    private destroyRef: DestroyRef,
    @Inject(MAT_DIALOG_DATA) private agent: CBox_PublicGetAgentDataResponse,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.agentForm = this.fb.group({
        name: ["", Validators.required],
        phone: ["", Validators.required],
        email: ["", Validators.required],
        identifier: [{
          value: "",
          disabled: true
        }, Validators.required],
        customIdentifier: [false],
        noPhoneNumber: [false],
        noEmailAddress: [false],
        lockers: this.fb.group({
          all: [false],
          specific: [[], Validators.minLength(1)]
        }),
        zone: [undefined, Validators.required],
        permissions: [[], Validators.minLength(1)],
        temporaryPasswordExpirationOffset: [0, Validators.required],
        passwordExpirationTimeUnit: ["hours"]
      });
    }

  ngAfterViewInit(): void {
    this.init();
  }

  private async init(): Promise<void> {
    await this.setZones();
    this.fetchAgentData();
    this.agentForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.showIdentifierWarning.set(value.identifier !== this.oldUniqueIdentifier() && value.customIdentifier);
    });
  }

  fetchAgentData(): void {
    if (isPlatformBrowser(this.platformId)) {
      const encodedIdentifier = encodeURIComponent(this.agent.agent.identifier as string);
      this.api.auth().get<CBox_PublicSuccessResponse<CBox_PublicGetAgentDataResponse>>("backend/agents/" + encodedIdentifier).subscribe(async (response) => {
        await this.patchAgentForm(response.data);
        this.loading.set(false);
      }, (e: HttpErrorResponse) => {
        this.loading.set(false);
        handlePublicApiError(e, this.toastService);
      });
    }
  }

  updateAgent(): void {
    if (this.agentForm.invalid) {
      markFormDirty(this.agentForm);
      return;
    }
    this.agentUpdateStatus.set("updating");
    const encodedIdentifier = encodeURIComponent(this.agent.agent.identifier as string);
    let params = new HttpParams();
    if (this.agentForm.get("customIdentifier")?.value) {
      params = params.append("allowIdentificationChange", "true");
    }
    this.api.patch<CBox_PublicSuccessResponse<CBox_ActionCreateAgentResponse>>("backend/agents/" + encodedIdentifier, this.getUpdateStructure(), { params }).subscribe((response) => {
      this.createResponse.set(response.data);
      this.dialogRef.close(true);
      this.agentUpdateStatus.set("created");
    }, (e: HttpErrorResponse) => {
      this.agentUpdateStatus.set("waiting");
      handlePublicApiError(e, this.toastService);
    });
  }

  closeModal(): void {
    this.confirmation.markAsDirty();
    this.confirmation.markAsTouched();
    if (this.confirmation.valid) {
      this.dialogRef.close();
    }
  }

  noPhoneNumberToggled(): void {
    const f = this.agentForm;
    if (f.get("noPhoneNumber")?.value) {
      f.get("phone")?.disable();
    } else {
      f.get("phone")?.enable();
    }
  }

  noEmailAddressToggled(): void {
    const f = this.agentForm;
    if (f.get("noEmailAddress")?.value) {
      f.get("email")?.disable();
    } else {
      f.get("email")?.enable();
    }
  }

  toggleCustomIdentifier(): void {
    const f = this.agentForm;
    const customIdentifier = f.get("customIdentifier");
    if (!customIdentifier?.value) {
      f.get("identifier")?.disable();
      f.get("identifier")?.patchValue(this.oldUniqueIdentifier());
    } else {
      f.get("identifier")?.enable();
    }
  }

  toggleAllLockers(): void {
    const f = this.agentForm;
    if (f.get("lockers.all")?.value) {
      f.get("lockers.specific")?.disable();
      f.get("lockers.specific")?.patchValue([], {
        emitEvent: false
      })
    } else {
      f.get("lockers.specific")?.enable();
    }
  }

  copyCourierCode(): void {
    if (this.courierCodeCopied()) {
      return;
    }
    copyString(this.createResponse()?.lockerOpenCode!);
    this.courierCodeCopied.set(true);
    setTimeout(() => {
      this.courierCodeCopied.set(false);
    }, 500);
  }

  lockerSelected(): void {
    const lockers = this.lockersForm.get("specific");
    const hasAccessToAllParentLockers = this.lockersForm.get("all");
    const selectedZone = this.agentForm.get("zone")?.value ?? this.allowedZones()[0].id;
    const allLockersSelected = this.groupedLockers()[selectedZone].length === lockers?.value.length;
    hasAccessToAllParentLockers?.patchValue(allLockersSelected, {
      emitEvent: false
    });
    this.toggleAllLockers();
    if (allLockersSelected) {
      this.lockersMultiSelect?.hide();
    }
  }

  zoneSelected(): void {
    this.lockersForm.reset();
    this.lockersForm.get("specific")?.enable();
  }

  getZoneName(zoneId: number): string {
    return this.allowedZones().find(z => +z.id === zoneId)?.name || "";
  }

  private async setZones(): Promise<void> {
    const lockers = await this.lockersService.getList();
    let groupedLockers: Record<CBox_GetLockerShortData["id"], CBox_GetLockerShortData[]> = {};
    const zones: CBox_GetLockerShortData["zones"] = [];
      for (const locker of lockers) {
        for (const zone of locker?.zones!) {
          if (!groupedLockers[+zone.id]) {
            groupedLockers[+zone.id] = [];
          }
          groupedLockers[+zone.id].push({id: locker.id, name: locker.name});
          if (!zones.find(z => +z.id === zone.id)) {
            zones.push({id: zone.id, name: zone.name});
          }
        }
      }
      const sortedZones = zones.sort((a, b) => {
        const countA = groupedLockers[a.id]?.length || 0;
        const countB = groupedLockers[b.id]?.length || 0;

        return countB - countA;
      });
      this.allowedZones.set(sortedZones);
      this.groupedLockers.set(groupedLockers);
      this.zonesFetched.set(true);
  }

  private async patchAgentForm(data: CBox_PublicGetAgentDataResponse): Promise<void> {
    this.agentForm.patchValue({
      name: data.agent.name,
      phone: data.agent.phone,
      email: data.agent.email,
      identifier: data.agent.identifier,
      zone: data.agent.zoneId,
      customIdentifier: false,
      noEmailAddress: data.agent.email?.length ? false : true,
      noPhoneNumber: data.agent.phone?.length ? false : true,
      permissions: data.permittedActions.map((p) => p.actionType) as any,
      lockers: {
        all: data.permittedLockers.all,
        specific: data.permittedLockers.specific?.map((l: any) => l.id) as any
      },
      accessDuration: undefined,
      temporaryPasswordExpirationOffset: data.access.password.autoExpirationOffset > 24 ? data.access.password.autoExpirationOffset / 24 : data.access.password.autoExpirationOffset,
      passwordExpirationTimeUnit: data.access.password.autoExpirationOffset > 24 ? "days" : "hours"
    });
    this.setFormValidators();
    this.toggleAllLockers();
    this.noPhoneNumberToggled();
    this.noEmailAddressToggled();
    this.oldUniqueIdentifier.set(data.agent.identifier);
    this.toggleCustomIdentifier();
    const permissions = await this.config.getConfigKey("agent.permittedActionsList") as CBox_PublicCompanyConfigAgentActionList;
    this.permissions.set(this.formatPermissions(permissions.actionsList));
    this.agentForm.updateValueAndValidity();
  }

  private getUpdateStructure(): CBox_ActionUpdateAgentData["data"] {
    const f = this.agentForm.value;
    let temporaryPasswordExpirationOffset = +f.temporaryPasswordExpirationOffset! || 0;
    if (f.passwordExpirationTimeUnit === "days") {
      temporaryPasswordExpirationOffset *= 24;
    }
    const data: CBox_ActionUpdateAgentData["data"] = {
        agent: {
          name: f.name || "",
          phone: f.phone || undefined,
          email: f.email || undefined,
          identifier: f.identifier || undefined,
          zoneId: f.zone || undefined
        },
        permittedActions: f.permissions?.map((p: CBox_PublicCompanyConfigAgentPermittedAction) => {
          return {
            actionType: p
          }
        }) || [],
        permittedLockers: {
          all: !!f.lockers?.all || undefined as any,
          specific: f.lockers?.specific?.length ? [...f.lockers?.specific?.map((lockerId: string) => {
            return {
              id: lockerId,
            }
          }), { id: "CO001" }] : undefined
        },
        temporaryPasswordExpirationOffset: temporaryPasswordExpirationOffset
    }

    return data;
  }

  private setFormValidators(): void {
    const email = this.agentForm.get("email");
    email?.setAsyncValidators(ValidateEmail.createValidator(this.api));
  }

  private formatPermissions(permissions: CBox_PublicCompanyConfigAgentPermittedAction[]): FormattedPermission[] {
    const translations: {[key in string]: string} = {
      CHANGE_PAPER: "Schimbare hârtie",
      COURIER_ALL_ACTIONS: "Control total comenzi",
      COURIER_PUT_ORDERS: "Predare comenzi",
      COURIER_RETRIEVE_EXPIRED: "Recuperare comenzi expirate",
      COURIER_TAKE_ORDERS: "Ridicare comenzi"
    };
    const filteredPermissions = permissions.filter((p: CBox_PublicCompanyConfigAgentPermittedAction) => p.isVisible);
    return filteredPermissions.map((p: any) => {
      return {
        value: p.aliasTo ? p.aliasTo : p.name,
        name: p.name,
        label: translations[p.name]
      }
    });
  }
}