import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_GetSelfUserResponse } from "@server/services/cbox/public/api/v1/resources/user/types";
import { SessionService } from '../session/session.service';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigurationService } from '../configuration/configuration.service';
import { ToastService } from '../toast/toast.service';
import { ApiService, handlePublicApiError } from '../api/api.service';

interface Wrapped<T> {
  isFetched: boolean;
  isError: boolean;
  data: T | undefined;
}

@Injectable({providedIn: 'root'})
export class UserService {

  userData = new BehaviorSubject<Wrapped<CBox_GetSelfUserResponse>>({
    isFetched: false,
    isError: false,
    data: undefined
  });

  constructor(
    private session: SessionService,
    private router: Router,
    private http: HttpClient,
    private toastService: ToastService,
    private configuration: ConfigurationService,
    private api: ApiService) {
      this.api.onLogout().subscribe(() => {
        this.logout();
      });
    }

  async getData(): Promise<Wrapped<CBox_GetSelfUserResponse>> {
    if (!this.userData.value.isFetched) {
      await this.fetchUserData();
    }

    return this.userData.value;
  }

  async fetchUserData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.api.auth().get<CBox_PublicSuccessResponse<CBox_GetSelfUserResponse>>("backend/users/me?includeConnectedDevices=true").subscribe((response) => {
        this.userData.next({
          isFetched: true,
          isError: false,
          data: response.data
        });
        resolve();
      }, (e: HttpErrorResponse) => {
        this.userData.next({
          isFetched: true,
          isError: true,
          data: undefined
        });
        handlePublicApiError(e, this.toastService);
        reject();
      });
    });
  }

  async login(): Promise<void> {
    await this.fetchUserData();
    await this.configuration.fetch();
  }

  logout(): void {
    this.session.clear();
    this.configuration.clear();
    this.http.post("backend/auth/logout", undefined).subscribe(() => {
      this.router.navigate(["/login"]);
    }, () => {
      this.toastService.showErrorToast("Eroare", "A aparut o eroare la delogare.");
    });
  }

  clear(): void {
    this.userData.next({
      isFetched: false,
      isError: false,
      data: undefined
    });
  }
}