<p-button
  *appRights="[permissions.createPickUp]"
  size="small"
  label="Adaugă PickUp"
  icon="pi pi-plus"
  (onClick)="createPickUpRequest()"
></p-button>
<form [formGroup]="filtersForm" class="grid align-items-end mt-2">
  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-1">Locker</span>
    <p-dropdown
      styleClass="w-full"
      placeholder="Caută un locker"
      optionLabel="name"
      optionValue="id"
      filterBy="name"
      formControlName="lockerId"
      [options]="lockers()"
      [loading]="fetchingLockers()"
      [filter]="true"
      [showClear]="true"
    >
      <ng-template let-locker pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ locker?.name }}#{{ locker?.id }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-2">Referință unică</span>
    <p-iconField class="w-full" iconPosition="right">
      @if (filtersForm.get('uniqueReference')?.value?.length > 0) {
        <p-inputIcon
          styleClass="pi pi-times cursor-pointer"
          (click)="filtersForm.patchValue({ uniqueReference: '' })"
        />
      }
      <input
        pInputText
        class="w-full"
        placeholder="Introduceți referință unica"
        formControlName="uniqueReference"
      />
    </p-iconField>
  </div>

  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-2">AWB</span>
    <p-iconField class="w-full" iconPosition="right">
      @if (filtersForm.get('awb')?.value?.length > 0) {
        <p-inputIcon
          styleClass="pi pi-times cursor-pointer"
          (click)="filtersForm.patchValue({ awb: '' })"
        />
      }
      <input
        pInputText
        class="w-full"
        placeholder="AWB"
        formControlName="awb"
      />
    </p-iconField>
  </div>

  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-2">Interval cautare</span>
    <p-calendar
      inputId="interval"
      styleClass="w-full"
      dateFormat="dd/mm/yy"
      selectionMode="range"
      formControlName="interval"
      [showIcon]="true"
      [showOnFocus]="true"
      [placeholder]="'Data de la'"
    ></p-calendar>
  </div>

  <div class="col-12 flex align-items-center gap-4">
    <p class="m-0">Comenzi:</p>
    <p-checkbox
      label="Noi"
      formControlName="includeNew"
      [binary]="true"
    ></p-checkbox>

    <p-checkbox
      label="În locker"
      formControlName="includeInLocker"
      [binary]="true"
    ></p-checkbox>

    <p-checkbox
      label="Expirate (în locker)"
      formControlName="includeExpired"
      [binary]="true"
    ></p-checkbox>

    <p-checkbox
      label="Anulate (în locker)"
      formControlName="includeCanceled"
      [binary]="true"
    ></p-checkbox>

    <p-checkbox
      label="Finalizate"
      formControlName="includeFinalized"
      [binary]="true"
    ></p-checkbox>
  </div>

  <!-- <div class="col-4">
    <span class="font-bold block mb-1">Locker</span>
    <p-dropdown
      id="locker"
      styleClass="w-full"
      placeholder="Caută un locker"
      optionLabel="name"
      filterBy="name"
      [options]="lockers()"
      [loading]="fetchingLockers()"
      [disabled]="fetchingLockers()"
      [filter]="true"
      [showClear]="true"
      (onChange)="lockerSelected($event.value)"
    >
      <ng-template let-locker pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ locker?.name }}#{{ locker?.id }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div> -->
</form>
<p-table
  styleClass="p-datatable-sm p-datatable-striped w-full"
  currentPageReportTemplate="{first} - {last} din {totalRecords} comenzi"
  paginatorPosition="both"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [value]="orders()"
  [paginator]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[25, 50, 100]"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (fetchingOrders()) {
      <tr>
        <td colspan="8">
          <p-progressBar
            mode="indeterminate"
            styleClass="w-full"
            [style]="{ height: '6px' }"
          ></p-progressBar>
        </td>
      </tr>
    }
    <tr>
      <th>Locker</th>
      <th>AWB</th>
      <th>Referință unică</th>
      <th>Stare</th>
      <!-- <th>Status</th> -->
      <th>Rezervat pana la</th>
      <th>Ridicare posibila pana la</th>
      <th *appRights="[permissions.deletePickUp]"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-order>
    <tr class="cursor-pointer" (click)="openPickupOrderInfo(order)">
      <td>
        @if (order.locker) {
          <b>{{ order.locker.name }}</b>
          <br>
        } @else {
          <b>Orice locker</b>
        }
      </td>
      <td>{{ order.awb }}</td>
      <td>
        @if (order.uniqueReference) {
          {{ order.uniqueReference }}
        }
      </td>
      <td [ngStyle]="{
        'color': getStatus(order).color
      }">
        <p class="m-0">
          {{ order.state.description }}
        </p>
        <div class="flex align-items-center gap-2">
          <div [ngStyle]="{
            'background-color': getStatus(order).color
          }" class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1">
            <p class="text-xs m-0">
              {{ getStatus(order).status }}
            </p>
          </div>
          <p class="text-xs text-gray-400 m-0">
            {{ order.state.occurredAt | date: "dd/MM/YYYY HH:mm" }}
          </p>
        </div>
      </td>
      <!-- <td>
        <div [ngStyle]="{
          'background-color': getStatus(order).color
        }" class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1">
          <p class="text-xs m-0">
            {{ getStatus(order).status }}
          </p>
        </div>
      </td> -->
      <td>{{ order.reservedUntil | date: "dd/MM/YYYY HH:mm" }}</td>
      <td>{{ order.pickUpPossibleUntil | date: "dd/MM/YYYY HH:mm" }}</td>
      <td *appRights="[permissions.deletePickUp]">
        <div class="card flex justify-content-center">
          <p-menu
            styleClass="w-15rem"
            #agentMenu
            appendTo="body"
            [popup]="true"
            [model]="requestMenuItems()"
          />
          <p-button
            size="small"
            icon="pi pi-ellipsis-v"
            [plain]="true"
            [text]="true"
            [rounded]="true"
            (click)="$event.stopPropagation(); selectedRequest.set(order); agentMenu.toggle($event)"
          ></p-button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    @if (fetchingOrders() === false) {
      <tr>
        <td colspan="8">
          <p class="text-gray-400 text-center text-lg font-bold">Nu exista comenzi.</p>
        </td>
      </tr>
    }
  </ng-template>