import { CommonModule, isPlatformBrowser } from "@angular/common";
import { Component, DestroyRef, Inject, InjectionToken, OnInit, PLATFORM_ID, computed, signal } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { CBox_ActionCreateAgentPermittedActionEnum, CBox_PublicGetAgentDataListParams, CBox_PublicGetAgentDataResponse } from "@server/services/cbox/public/api/v1/resources/agent/types";
import { CBox_PublicPaginatedResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { ChipModule } from "primeng/chip";
import { FloatLabelModule } from "primeng/floatlabel";
import { InputTextModule } from "primeng/inputtext";
import { PaginatorModule } from "primeng/paginator";
import { TableModule, TablePageEvent } from "primeng/table";
import { TagModule } from "primeng/tag";
import { debounceTime, first } from "rxjs";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { CacheService } from "src/services/cache/cache.service";
import { ProfileService } from "src/services/profile/profile.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";
import { CBoxProfileAgentCreateComponent } from "../dialogs/create/cbox-profile-agent-create.component";
import { CheckboxModule } from "primeng/checkbox";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { BadgeModule } from "primeng/badge";
import { RippleModule } from "primeng/ripple";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { CBoxProfileLockerDataComponent } from "app/profile/lockers/data/cbox-profile-locker-data.component";
import { MenuItem } from "primeng/api";
import { MenuModule } from "primeng/menu";
import { CBoxProfileAgentUpdateComponent } from "../dialogs/update/cbox-profile-agent-update.component";
import { ProgressBarModule } from "primeng/progressbar";
import { CBoxProfileAgentDeleteDialogComponent } from "../dialogs/delete/cbox-profile-agent-delete-dialog.component";
import { CBoxProfileAgentAccessRevokeConfirmationDialogComponent } from "../dialogs/access-revoke/cbox-profile-agent-access-revoke-confirmation-dialog.component";
import { CBoxProfileAgentPasswordRegenerateDialogComponent } from "../dialogs/password-regenerate/cbox-profile-agent-password-regenerate-dialog.component";
import { CBoxProfileAgentIdentificationCodeRegenerateDialogComponent } from "../dialogs/identification-code-regenerate/cbox-profile-agent-identification-code-regenerate-dialog.component";
import { PermissionDirective } from "src/directives/permission.directive";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { SessionService } from "src/services/session/session.service";
import { ToastService } from "src/services/toast/toast.service";
import { PermissionNamesEnum } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { InputIconModule } from "primeng/inputicon";
import { IconFieldModule } from "primeng/iconfield";

@Component({
  selector: "app-cbox-profile-agent-list",
  templateUrl: "./cbox-profile-agent-list.component.html",
  styleUrls: ["./cbox-profile-agent-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    FloatLabelModule,
    ButtonModule,
    TableModule,
    CardModule,
    ChipModule,
    TagModule,
    PaginatorModule,
    CheckboxModule,
    BadgeModule,
    RippleModule,
    OverlayPanelModule,
    MenuModule,
    ProgressBarModule,
    PermissionDirective,
    InputIconModule,
    IconFieldModule
  ]
})

export class CBoxProfileAgentListComponent implements OnInit {

  agents = signal<CBox_PublicGetAgentDataResponse[]>([]);
  fetchingAgents = signal<"waiting" | "fetching" | "success" | "error">("waiting");

  // Used for filtering inside the lockers overlay
  agentLockersFilter = new FormControl("");
  agentLockers = signal<{id?: string; name?: string}[]>([]);
  filteredAgentLockers = signal<{id?: string; name?: string}[]>([]);
  zones = signal<{id: number; name: string}[]>([]);

  filterByOptions = signal([
    {
      label: "Nume",
      value: "name"
    },
    {
      label: "Identificator unic",
      value: "identifier"
    },
    {
      label: "Email",
      value: "email"
    },
    {
      label: "Telefon",
      value: "phone"
    }
  ]);
  filtersForm: FormGroup;
  totalCount = signal(0);
  selectedAgent = signal<CBox_PublicGetAgentDataResponse | undefined>(undefined);

  permissions = PermissionNamesEnum;

  isAccessRevocationDisabled = computed(() => {
    const selectedAgent = this.selectedAgent();
    return (
      !!!selectedAgent?.access?.identificationCode?.hasCode &&
      !!!selectedAgent?.access?.password?.hasPassword
    );
  });
  hasPermission = (permission: string) => computed(() => {
    return this.configuration.hasAccess([permission]);
  });
  agentMenuItems = computed<MenuItem[]>(() => [
    {
      label: "Regenerează cod",
      icon: "pi pi-sync",
      visible: this.hasPermission(PermissionNamesEnum.regenerateAgentCredentials)(),
      command: () => {
        this.confirmAgentIdentificationCodeRegenerate(this.selectedAgent());
      }
    },
    {
      label: "Regenerează parolă",
      icon: "pi pi-sync",
      visible: this.hasPermission(PermissionNamesEnum.regenerateAgentCredentials)(),
      command: () => {
        this.confirmAgentPasswordRegenerate(this.selectedAgent());
      }
    },
    {
      label: "Editează",
      icon: "pi pi-pencil",
      visible: this.hasPermission(PermissionNamesEnum.editAgent)(),
      command: () => {
        this.editAgent(this.selectedAgent());
      }
    },
    {
      label: "Revocă acces",
      icon: "pi pi-times",
      visible: this.hasPermission(PermissionNamesEnum.revokeAgentAccess)(),
      command: () => {
        this.confirmAgentAccessRevoke(this.selectedAgent());
      },
      disabled: this.isAccessRevocationDisabled()
    },
    {
      label: "Șterge agent",
      icon: "pi pi-trash",
      visible: this.hasPermission(PermissionNamesEnum.deleteAgent)(),
      command: () => {
        this.confirmAgentDelete(this.selectedAgent());
      }
    }
  ]);

  permissionsTranslation = signal<{[key in string]: string}>({
    [CBox_ActionCreateAgentPermittedActionEnum.CHANGE_PAPER]: "Schimbare hârtie",
    [CBox_ActionCreateAgentPermittedActionEnum.COURIER_ALL_ACTIONS]: "Control total comenzi",
    [CBox_ActionCreateAgentPermittedActionEnum.COURIER_PUT_ORDERS]: "Predare comenzi",
    [CBox_ActionCreateAgentPermittedActionEnum.COURIER_RETRIEVE_EXPIRED]: "Recuperare comenzi expirate",
    [CBox_ActionCreateAgentPermittedActionEnum.COURIER_TAKE_ORDERS]: "Ridicare comenzi"
  });

  constructor(
    private api: ApiService,
    private cache: CacheService,
    private session: SessionService,
    private profileService: ProfileService,
    private destroyRef: DestroyRef,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private toastService: ToastService,
    private configuration: ConfigurationService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {
    this.filtersForm = this.fb.group({
      filterBy: [this.filterByOptions()[0].value],
      identifier: [""],
      name: [""],
      phone: [""],
      email: [""],
      includeDisabled: [false],
      page: [1],
      pageSize: [25]
    });
  }

  ngOnInit(): void {
    this.init();
  }

  loadAgents(): void {
    this.fetchingAgents.set("fetching");
    this.agents.set([]);
    this.fetchAgents();
  }

  createAgent(): void {
    const dialog = this.dialog.open(CBoxProfileAgentCreateComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loadAgents();
    });
  }

  pageChanged(event: TablePageEvent): void {
    const page = event.first / event.rows;
    this.filtersForm.patchValue({
      page: page + 1,
      pageSize: event.rows
    });
    this.saveFilters();
  }

  openLockerDetails(lockerName: string | undefined): void {
    if (!lockerName) {
      return;
    }
    this.dialog.open(CBoxProfileLockerDataComponent, {
      data: lockerName,
      width: "min(1200px, 100%)",
    });
  }

  private async init(): Promise<void> {
    this.profileService.setTitle("Listă agenți");
    this.zones.set(await this.configuration.getAllowedZones());

    this.agentLockersFilter.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((searchString) => {
      this.filteredAgentLockers.set(
        this.agentLockers().filter((locker) =>
          locker.name?.toLowerCase().includes(searchString!.toLowerCase())
        )
      );
    });

    this.filtersForm.valueChanges.pipe(
      debounceTime(500),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.saveFilters();
      this.loadAgents();
    });

    this.patchCachedFilters();
    if (isPlatformBrowser(this.platformId)) {
      this.loadAgents();
    }
  }

  private fetchAgents(): void {
    const params = new HttpParams({
      fromObject: this.getSearchStructure()
    });
    this.api.auth().get<CBox_PublicPaginatedResponse<CBox_PublicGetAgentDataResponse>>("backend/agents", params).subscribe((response) => {
      this.totalCount.set(response.totalCount);
      this.agents.set(response.data);
      this.fetchingAgents.set("success");
    }, (e: HttpErrorResponse) => {
      this.fetchingAgents.set("error");
      handlePublicApiError(e, this.toastService);
    });
  }

  patchAgentLockersForFilter(agent: CBox_PublicGetAgentDataResponse): void {
    this.agentLockers.set(agent.permittedLockers.specific!);
    this.filteredAgentLockers.set(agent.permittedLockers.specific!);
  }

  editAgent(agent: CBox_PublicGetAgentDataResponse | undefined): void {
    if (!agent) {
      return;
    }
    const dialog = this.dialog.open(CBoxProfileAgentUpdateComponent, {
      width: "min(600px, 100%)",
      data: agent,
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe((edited) => {
      if (edited) {
        this.loadAgents();
      }
    });
  }

  getZoneName(zoneId: number): string {
    return this.zones().find((zone) => +zone.id === zoneId)?.name || "";
  }

  private confirmAgentIdentificationCodeRegenerate(agent: CBox_PublicGetAgentDataResponse | undefined): void {
    if (!agent) {
      return;
    }

    const dialog = this.dialog.open(CBoxProfileAgentIdentificationCodeRegenerateDialogComponent, {
      data: agent,
      width: "min(400px, 100%)",
      disableClose: true
    });

    dialog.afterClosed().pipe(first()).subscribe((regenerated: boolean) => {
      this.loadAgents();
    });
  }

  private confirmAgentPasswordRegenerate(agent: CBox_PublicGetAgentDataResponse | undefined): void {
    if (!agent) {
      return;
    }

    const dialog = this.dialog.open(CBoxProfileAgentPasswordRegenerateDialogComponent, {
      data: agent,
      width: "min(400px, 100%)",
      disableClose: true
    });

    dialog.afterClosed().pipe(first()).subscribe((regenerated: boolean) => {
      this.loadAgents();
    });
  }

  private confirmAgentDelete(agent: CBox_PublicGetAgentDataResponse | undefined): void {
    if (!agent) {
      return;
    }
    const dialog = this.dialog.open(CBoxProfileAgentDeleteDialogComponent, {
      data: agent,
      width: "min(400px, 100%)",
      disableClose: true
    });

    dialog.afterClosed().pipe(first()).subscribe((deleted: boolean) => {
      if (deleted) {
        this.toastService.showSuccessToast("Confirmare", "Agentul a fost șters cu succes.");
        this.loadAgents();
      }
    });
  }

  private confirmAgentAccessRevoke(agent: CBox_PublicGetAgentDataResponse | undefined): void {
    if (!agent) {
      return;
    }
    const dialog = this.dialog.open(CBoxProfileAgentAccessRevokeConfirmationDialogComponent, {
      data: agent,
      width: "min(400px, 100%)",
      disableClose: true
    });

    dialog.afterClosed().pipe(first()).subscribe((accessRevoked) => {
      if (accessRevoked) {
        this.toastService.showSuccessToast("Confirmare", "Accesul agentului a fost revocat cu succes.");
        this.loadAgents();
      }
    });
  }

  private saveFilters(): void {
    const filtersValues = this.filtersForm.value;
    const localStorageFilters = {
      filterBy: filtersValues.filterBy
    };
    const sessionStorageFilters = {
      name: filtersValues.name,
      identifier: filtersValues.identifier,
      email: filtersValues.email,
      phone: filtersValues.phone,
      page: filtersValues.page,
      pageSize: filtersValues.pageSize,
      includeDisabled: filtersValues.includeDisabled
    };
    this.cache.setVal("agents-list/filters/local", JSON.stringify(localStorageFilters));
    this.session.setVal("agents-list/filters/session", JSON.stringify(sessionStorageFilters));
  }

  private patchCachedFilters(): void {
    const localStorageFilters = this.cache.getVal("agents-list/filters/local", undefined);
    if (localStorageFilters) {
      const parsed = JSON.parse(localStorageFilters);
      this.filtersForm.patchValue(parsed, {
        emitEvent: false
      });
    }

    const sessionStorageFilters = this.session.getVal("agents-list/filters/session", undefined);
    if (sessionStorageFilters) {
      const parsed = JSON.parse(sessionStorageFilters);
      this.filtersForm.patchValue(parsed, {
        emitEvent: false
      });
    }
  }

  private getSearchStructure(): CBox_PublicGetAgentDataListParams {
    const excludeableFields = ["identifier", "name", "phone", "email"];
    const filterBy = this.filtersForm.get("filterBy")?.value;
    const data = {
      identifier: this.filtersForm.value.identifier,
      name: this.filtersForm.value.name,
      phone: this.filtersForm.value.phone,
      email: this.filtersForm.value.email,
      includeDisabled: !!this.filtersForm.value.includeDisabled,
      page: +this.filtersForm.value.page,
      pageSize: +this.filtersForm.value.pageSize
    };
    excludeableFields.splice(excludeableFields.indexOf(filterBy), 1);
    for (const field of excludeableFields) {
      delete data[field as keyof CBox_PublicGetAgentDataListParams];
    }
    return Object.entries(data)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_PublicGetAgentDataListParams] = value;
      return acc;
    }, {} as CBox_PublicGetAgentDataListParams);
  }
}