import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';

@Component({
  selector: 'app-cbox-profile-admin-container',
  templateUrl: './cbox-profile-admin-container.component.html',
  styleUrls: ["./cbox-profile-admin-container.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TabMenuModule
  ]
})

export class CBoxProfileAdminContainerComponent implements OnInit {

  tabMenuItems = signal<MenuItem[]>([
    {
      label: "Lockere",
      routerLink: "/profile/admin/lockers",
      icon: "inventory_2"
    },
    {
      label: "Echipamente",
      routerLink: "/profile/admin/equipments",
      icon: "build"
    },
    {
      label: "Module",
      routerLink: "/profile/admin/modules",
      icon: "view_module"
    },
  ]);

  constructor() { }

  ngOnInit() { }
}