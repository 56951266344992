import { HttpErrorResponse } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";
import { isDevMode } from "@angular/core";
import { Observable, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { IS_STAGING, IS_PROD_2 } from "@server/config/shared_constants";
import { LOCAL_CBOX_SERVER_PUBLIC_URL } from "@server/config/urls";
import { isPlatformServer } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { CBox_PublicErrorResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { ToastService } from "../toast/toast.service";
import { UserService } from "../user/user.service";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class ApiService {
  url = "";

  useAuth = false;
  useHandle = true;
  private loggedSubject = new Subject<boolean>();
  private timeoutValue: number = 0;
  private logoutSubject = new Subject<void>();

  constructor(public http: HttpClient,
              private dialog: MatDialog,
              private toastService: ToastService,
              private router: Router,
              @Inject(PLATFORM_ID) platformId: InjectionToken<Object>) {

    if (isPlatformServer(platformId)) {
      this.url = "http://10.8.0.34:3040";
    } else {
      if (isDevMode()) {
        // this.url = "http://10.8.0.14:3000";
        this.url = LOCAL_CBOX_SERVER_PUBLIC_URL;
        // this.url = "http://localhost:3000";
      } else {
        if (!IS_STAGING) {
          this.url = "https://www.colete-online.ro/backend";
        } else {
          this.url = "https://staging.colete-online.ro/backend";
          if (IS_PROD_2) {
            this.url = "https://bkpx114x.colete-online.ro/backend";
          }
        }
        // this.url = 'https://staging.colete-online.ro/backend-local';
      }
    }
  }

  onLogout(): Observable<void> {
    return this.logoutSubject.asObservable();
  }

  get<T>(endpoint: string, params?: any, options?: any): Observable<T> {
    const observable = this.http.get(endpoint, {
      headers: {
        "Content-Type": "application/json"
      },
      params,
      ...options,
      withCredentials: true
    });

    return this.handleErrors(observable);
  }

  post<T>(endpoint: string, body: any, options?: any): Observable<T> {
    const observable = this.http.post(endpoint, body, {
      headers: {
        "Content-Type": "application/json"
      },
      ...options,
      withCredentials: true
    });

    return this.handleErrors(observable);
  }

  put<T>(endpoint: string, body: any, options?: any): Observable<T> {
    const observable = this.http.put(endpoint, body, {
      headers: {
        "Content-Type": "application/json"
      },
      ...options,
      withCredentials: true
    });

    return this.handleErrors(observable);
  }

  delete<T>(endpoint: string, params?: any, options?: any): Observable<T> {
    const observable = this.http.delete(endpoint, {
      headers: {
        "Content-Type": "application/json"
      },
      params,
      ...options,
      withCredentials: true
    });

    return this.handleErrors(observable);
  }

  patch<T>(endpoint: string, body: any, options?: any): Observable<T> {
    const observable = this.http.patch(endpoint, body, {
      headers: {
        "Content-Type": "application/json"
      },
      ...options,
      withCredentials: true
    });

    return this.handleErrors(observable);
  }

  private handleErrors<T>(observable: Observable<T>): Observable<any> {
    if (this.useHandle) {
      return observable.pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.dialog.closeAll();
            this.logoutSubject.next();
          }
  
          if (error.status === 403) {
            this.dialog.closeAll();
            this.toastService.showErrorToast("Acces interzis", "Această operațiune nu este permisă");
            this.router.navigate(["/profile/lockers/list"]);
          }
  
          return throwError(error);
        })
      );
    } else {
      return observable;
    }
  }

  auth(): ApiService {
    this.useAuth = true;
    return this;
  }

  noHandle(): ApiService {
    this.useHandle = false;
    return this;
  }

  // timeout in milliseconds
  timeout(timeout: number): ApiService {
    this.timeoutValue = timeout;
    return this;
  }

}

export function handlePublicApiError(e: HttpErrorResponse, toastService: ToastService): void {
  const err = e.error as CBox_PublicErrorResponse;
  toastService.clear();
  if (err?.errors) {
    for (const error of err.errors) {
      if (error.details.length) {
        toastService.showErrorToast(
          error.message,
          error.details[0].ro
        )
      } else {
        toastService.showErrorToast(
          "Eroare",
          "Eroare necunoscută. Vă rugăm să ne contactați!"
        )
      }
    }
  } else {
    toastService.showErrorToast(
      "Eroare",
      "Eroare necunoscută. Vă rugăm să ne contactați!"
    )
  }
}
