<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>
    Configurare {{ locker.name }}
    @if (locker.state.isActive === true) {
      <p-tag class="mb-1 inline" severity="success">ACTIV</p-tag>
    } @else if (locker.state.isActive === false) {
      <p-tag class="mb-1 inline" severity="danger">INACTIV</p-tag>
    }
  </h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    size="small"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <form class="formgrid grid row-gap-2 relative" [formGroup]="form">
    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">ID Locker</span>
      <input
        formControlName="id"
        class="w-full"
        pInputText
        pAutoFocus
        [autofocus]="true"
      >
      @let id = form.get("id");
      @let idErrors = form.get("id")?.errors;
      @if (id?.dirty) {
        @if (idErrors) {
          @if (idErrors?.["required"]) {
            <small class="text-red-500">Câmpul este obligatoriu!</small>
          }
        }
      }
    </div>

    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Identificator</span>
      <input
        formControlName="identifier"
        class="w-full"
        pInputText
        pAutoFocus
        [autofocus]="true"
      >
      @let identifier = form.get("identifier");
      @let identifierErrors = form.get("identifier")?.errors;
      @if (identifier?.dirty) {
        @if (identifierErrors) {
          @if (identifierErrors?.["required"]) {
            <small class="text-red-500">Câmpul este obligatoriu!</small>
          }
        }
      }
    </div>

    <div class="col-12">
      <span class="font-bold block mb-1">Nume</span>
      <input
        formControlName="name"
        class="w-full"
        pInputText
        pAutoFocus
        [autofocus]="true"
      >
      @let name = form.get("name");
      @let nameErrors = form.get("name")?.errors;
      @if (name?.dirty) {
        @if (nameErrors) {
          @if (nameErrors?.["required"]) {
            <small class="text-red-500">Câmpul este obligatoriu!</small>
          }
        }
      }
    </div>

    <h2 class="col-12 m-0">Adresă</h2>
    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Localitate</span>
      <input
        formControlName="locality"
        class="w-full"
        pInputText
      >
    </div>

    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Judet</span>
      <input
        formControlName="county"
        class="w-full"
        pInputText
      >
    </div>

    <div class="col-12 sm:col-8">
      <span class="font-bold block mb-1">Strada</span>
      <input
        formControlName="street"
        class="w-full"
        pInputText
      >
    </div>

    <div class="col-12 sm:col-4">
      <span class="font-bold block mb-1">Nr.</span>
      <input
        formControlName="number"
        class="w-full"
        pInputText
      >
    </div>

    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Cod postal</span>
      <input
        formControlName="postalCode"
        class="w-full"
        pInputText
      >
    </div>

    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Restul adresei</span>
      <input
        formControlName="restOfAddress"
        class="w-full"
        pInputText
      >
    </div>

    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Latitudine</span>
      <input
        formControlName="latitude"
        class="w-full"
        pInputText
      >
    </div>

    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Longitudine</span>
      <input
        formControlName="longitude"
        class="w-full"
        pInputText
      >
    </div>

    <div class="col-12">
      <p-table
        styleClass="p-datatable-sm"
        [reorderableColumns]="true"
        [value]="alreadyExistentModules().controls"
      >
        <ng-template pTemplate="header">
          <tr>
            <th></th>
            <th class="text-center">Serie modul</th>
            <th class="text-center">ID placa</th>
            <th class="text-center">Poziție</th>
            <th></th>
          </tr>
        </ng-template>

        <ng-template
          pTemplate="body"
          let-module
          let-columns
          let-index="rowIndex"
        >
          <tr
            [pReorderableRow]="index"
            [formGroup]="module"
            [ngClass]="{
              'bg-green-200': index === 0
            }"
          >
            <td>
              <span class="pi pi-bars" pReorderableRowHandle></span>
            </td>
            <td class="text-center">{{ module.get("serial")?.value }}</td>
            <td class="text-center">{{ module.get("position")?.value }}</td>
            <td class="text-center">{{ module.get("boardCardAddress")?.value }}</td>
            <td class="text-right">
              <span
                class="pi pi-pencil cursor-pointer"
                matTooltip="Editează modulul"
                matTooltipPosition="above"
                (click)="updateModule(module)"
              ></span>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="flex align-items-center gap-2 col-12 mt-1">
        <div class="bg-green-200 border-circle w-1rem h-1rem"></div>
        <span>Modulul principal</span>
      </div>
    </div>

    <!-- @for (existentModule of alreadyExistentModules()?.controls; track $index) {
      <h3 class="col-12 m-0">Modul {{ existentModule?.get("serial")?.value }}</h3>
      <ng-container [formGroup]="getExistentModuleForm($index)">
        <div class="col-8">
          <span class="font-bold block mb-1">Pozitie</span>
          <input
            formControlName="position"
            class="w-full"
            pInputText
          >
        </div>

        <div class="col-4">
          <span class="font-bold block mb-1">ID placa</span>
          <input
            formControlName="boardCardAddress"
            class="w-full"
            pInputText
            type="number"
          >
        </div>
      </ng-container>
    } -->

    @for (module of modulesArray.controls; track $index) {
      <div class="flex align-items-center gap-2 col-12">
        <h3 class="m-0">Modul {{ $index + 1 }}</h3>
        <span
          matTooltip="Șterge modul"
          matTooltipPosition="above"
          class="material-symbols-rounded trash cursor-pointer"
          (click)="deleteModuleAt($index)"
        >delete</span>
      </div>
      <ng-container [formGroup]="getFormAtIndex($index)">
        <div class="col-12">
          <span class="font-bold block mb-1">Modul</span>
          <p-dropdown
            emptyMessage="Nu există module"
            optionLabel="name"
            optionValue="id"
            formControlName="moduleId"
            placeholder="Selectați modulul"
            styleClass="w-full"
            [options]="modules()"
            [loading]="!modules()"
          >
            <ng-template let-module pTemplate="item">
              <div>{{ module.serial }}</div>
              <div>{{ module.type.description }}</div>
            </ng-template>

            <ng-template let-module pTemplate="selectedItem">
              <div>{{ module.serial }}</div>
              <div>{{ module.type.description }}</div>
            </ng-template>
          </p-dropdown>
        </div>

        <div class="col-8">
          <span class="font-bold block mb-1">Pozitie</span>
          <input
            formControlName="position"
            class="w-full"
            pInputText
          >
        </div>

        <div class="col-4">
          <span class="font-bold block mb-1">ID placa</span>
          <input
            formControlName="boardCardAddress"
            class="w-full"
            pInputText
            type="number"
          >
        </div>
      </ng-container>
    }
    <div
      [matTooltip]="modules() && !modules()?.length ? 'Nu există module disponibile' : ''"
      matTooltipPosition="above"
      class="col-12"
    >
      <button
        pButton
        class="w-full"
        label="Adaugă modul"
        icon="pi pi-plus"
        iconPos="right"
        [disabled]="modules() && (modules()?.length! - modulesArray.controls.length) === 0"
        (click)="addModule()"
      ></button>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    pButton
    label="Salvează"
    (click)="update()"
  ></button>
  </mat-dialog-actions>
@if (loading() === true) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}