
@if (agentCreateStatus() === "waiting" || agentCreateStatus() === "creating") {
<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Adăugare agent</h2>
    <p-button
      mat-dialog-close
      size="small"
      icon="pi pi-times"
      severity="secondary"
      [rounded]="true"
      [text]="true"
    />
  </div>
}
<mat-dialog-content>
  @if (agentCreateStatus() === "waiting" || agentCreateStatus() === "creating") {
    <form class="formgrid grid row-gap-2 relative" [formGroup]="form">
      @if (!form.get("customIdentifier")?.value) {
        <p-messages
          styleClass="text-xs"
          class="col-12"
          [value]="autoGeneratedIdentifierMessage()"
          [enableService]="false"
          [closable]="false" />
      }
      <div class="col-12 sm:col-6">
        <span class="font-bold block mb-1">Nume agent*</span>
        <input
          formControlName="name"
          class="w-full"
          id="awb"
          pInputText
          pAutoFocus
          [autofocus]="true"
        >
        @let name = form.get("name");
        @let nameErrors = form.get("name")?.errors;
        @if (name?.dirty) {
          @if (nameErrors) {
            @if (nameErrors?.["required"]) {
              <small class="text-red-500">Numele agentului este obligatoriu!</small>
            }
          }
        }
      </div>

      <div class="col-12 sm:col-6">
        <span class="font-bold block mb-1">Identificator</span>
        @let identifier = form.get("identifier");
        <input
          formControlName="identifier"
          class="w-full"
          id="awb"
          [placeholder]="identifier?.disabled ? 'Se va genera automat' : 'Introduceți identificatorul'"
          pInputText
        >
        @let identifierErrors = form.get("identifier")?.errors;
        @if (identifier?.dirty) {
          @if (identifierErrors) {
            @if (identifierErrors?.["required"]) {
              <small class="text-red-500">Identificatorul este obligatoriu!</small>
            }
          }
        }
        <div class="flex align-items-center gap-2">
          <p-checkbox
            class="mt-1"
            [binary]="true"
            formControlName="customIdentifier"
            inputId="customIdentifier"
            (onChange)="toggleCustomIdentifier(); identifier?.reset();"
          ></p-checkbox>
          <label class="-mb-1 text-xs" for="customIdentifier">Identificator personalizat</label>
        </div>
      </div>

      <div
        class="col-12 sm:col-6"
      >
        <span class="font-bold block mb-1">Telefon</span>
        @let phone = form.get("phone");
        <input
          class="w-full"
          type="tel"
          pKeyFilter="int"
          pInputText
          [placeholder]="phone?.disabled ? '' : '07xxxxxxxx'"
          formControlName="phone"
        />
        @let phoneErrors = form.get("phone")?.errors;
        @if (phone?.dirty) {
          @if (phoneErrors) {
            @if (phoneErrors?.["required"]) {
              <small class="text-red-500">Numarul de telefon este obligatoriu!</small>
            }
          }
        }
        <div class="flex align-items-center gap-2">
          <p-checkbox
            class="mt-1"
            [binary]="true"
            formControlName="noPhoneNumber"
            inputId="noPhoneNumber"
            (onChange)="noPhoneNumberToggled(); phone?.reset();"
          ></p-checkbox>
          <label class="-mb-1 text-xs" for="noPhoneNumber">Agentul nu are număr de telefon</label>
        </div>
      </div>

      <div class="col-12 sm:col-6">
        <span class="font-bold block mb-1">Email</span>
        @let email = form.get("email");
        <input
          class="w-full"
          type="text"
          pInputText
          [placeholder]="email?.disabled ? '' : 'test@companie.ro'"
          formControlName="email"
        >
        @let emailErrors = form.get("email")?.errors;
        @if (email?.dirty) {
          @if (emailErrors) {
            @if (emailErrors?.["required"]) {
              <small class="text-red-500">Email-ul este obligatoriu!</small>
            }
          }
        }
        <div class="flex align-items-center gap-2">
          <p-checkbox
            class="mt-1"
            [binary]="true"
            formControlName="noEmailAddress"
            inputId="noEmailAddress"
            (onChange)="noEmailAddressToggled(); email?.reset();"
          ></p-checkbox>
          <label class="-mb-1 text-xs" for="noEmailAddress">Agentul nu are adresă de email</label>
        </div>
      </div>

      <div class="col-12">
        <span class="font-bold block mb-1">Permisiuni*</span>
        <p-multiSelect
          formControlName="permissions"
          optionLabel="label"
          optionValue="value"
          placeholder="Alege permisiuni"
          styleClass="w-full"
          appendTo="body"
          emptyFilterMessage="Nu există permisiuni"
          [filter]="false"
          [options]="permissions()"
          [loading]="!permissions()"
          (onChange)="permissionSelected($event)"
        ></p-multiSelect>
        @let permissionsField = form.get("permissions");
        @let permissionsErrors = form.get("permissions")?.errors;
        @if (permissionsField?.dirty) {
          @if (permissionsErrors) {
            @if (permissionsErrors?.["required"]) {
              <small class="text-red-500">Vă rugăm să selectați permisiunile agentului!</small>
            }
          }
        }
      </div>

      @if (allowedZones().length > 1) {
        <div class="col-12">
          <span class="block font-bold mb-1">Zonă</span>
          <p-dropdown
            formControlName="zone"
            optionLabel="name"
            optionValue="id"
            styleClass="w-full"
            appendTo="body"
            placeholder="Selectează zona agentului"
            [options]="allowedZones()"
            [loading]="!zonesFetched()"
          ></p-dropdown>
        </div>
      }

      @let selectedZone = form.get("zone")?.value;
      @if (selectedZone || allowedZones().length === 1) {
        <div [formGroup]="lockersForm" class="col-12">
          <span class="font-bold block mb-1">Lockere</span>
          @let specific = lockersForm.get("specific");
          @let specificErrors = specific?.errors;
          <p-multiSelect
            #lockersMultiSelect
            formControlName="specific"
            optionLabel="name"
            optionValue="id"
            [placeholder]="lockersForm.get('all')?.value ? 'Toate lockerele din zona ' + this.getZoneName(
              allowedZones().length === 1 ?
                allowedZones()[0].id :
                selectedZone
            ) : 'Selectează lockere'"
            styleClass="w-full"
            appendTo="body"
            emptyFilterMessage="Nu există lockere"
            selectedItemsLabel="{0} lockere selectate"
            [options]="
              allowedZones().length === 1 ?
                groupedLockers()[allowedZones()[0].id] :
                groupedLockers()[selectedZone]"
            (onChange)="lockerSelected()"
          >
            <ng-template let-locker pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div>
                  <small class="text-gray-400">#{{ locker?.id }}</small>
                  <p class="m-0">{{ locker?.name }}</p>
                </div>
              </div>
            </ng-template>
          </p-multiSelect>
          @if (specific?.dirty) {
            @if (specificErrors?.["required"]) {
              <small class="text-red-500">Vă rugăm să selectați minim un locker</small>
            }
          }
          <div class="flex align-items-center gap-2 mt-1">
            <p-checkbox
              [binary]="true"
              formControlName="all"
              inputId="allLockers"
              (onChange)="toggleAllLockers()"
            ></p-checkbox>
            <label class="text-xs" for="allLockers">Toate lockerele</label>
          </div>
        </div>
      }

      <div
        class="col-12 sm:col-6 h-full"
      >
        <span class="font-bold block mb-1">Acces pana la*</span>
        <p-calendar
          dateFormat="dd/mm/yy"
          formControlName="accessDuration"
          [minDate]="today()"
          styleClass="w-full"
          appendTo="body"
          [showIcon]="true"
        />
        @let accessDuration = form.get("accessDuration");
        @let accessDurationErrors = form.get("accessDuration")?.errors;
        @if (accessDuration?.dirty) {
          @if (accessDurationErrors) {
            @if (accessDurationErrors?.["required"]) {
              <small class="text-red-500">Vă rugăm selectați o dată!</small>
            }
          } @else {
            <small>Data până la care agentul va avea acces la lockere. Aceasta poate fi prelungită ulterior.</small>
          }
        } @else {
          <small>Data până la care agentul va avea acces la lockere. Aceasta poate fi prelungită ulterior.</small>
        }
      </div>

      <div class="col-12 sm:col-6">
        <span class="font-bold block mb-1">Perioadă valabilitate parolă*</span>
        <div class="grid mt-1">
          <div class="col-7">
            <input
              pInputText
              pKeyFilter="money"
              class="w-full"
              formControlName="temporaryPasswordExpirationOffset"
            >
          </div>
          <div class="col-5 pl-0">
            <p-dropdown
              formControlName="passwordExpirationTimeUnit"
              optionLabel="label"
              optionValue="value"
              styleClass="w-full"
              appendTo="body"
              [options]="[
                { label: 'Zile', value: 'days' },
                { label: 'Ore', value: 'hours' }
              ]"
              (onChange)="changePasswordExpirationTimeUnit($event.value)"
            ></p-dropdown>
          </div>
        </div>
        @let temporaryPasswordExpirationOffset = form.get("temporaryPasswordExpirationOffset");
        @let temporaryPasswordExpirationOffsetErrors = form.get("temporaryPasswordExpirationOffset")?.errors;
        @if (temporaryPasswordExpirationOffset?.dirty) {
          @if (temporaryPasswordExpirationOffsetErrors) {
            @let timeUnitValue = form.get("passwordExpirationTimeUnit")?.value;
            @let timeUnit = timeUnitValue === "days" ? "zi" : "oră";
            @let pluralTimeUnit = timeUnitValue === "days" ? "zile" : "ore";
            @if (temporaryPasswordExpirationOffsetErrors?.["required"]) {
              <small class="text-red-500">Perioada de valabilitate a parolei este obligatorie!</small>
            } @else if (temporaryPasswordExpirationOffsetErrors?.["min"]) {
              @let min = temporaryPasswordExpirationOffsetErrors["min"]["min"];
              <small class="text-red-500">
                Perioada de valabilitate a parolei trebuie sa fie mai mare de
                {{ min > 1 ? min : "o" }}
                {{ min > 1 ? timeUnit : pluralTimeUnit }}!</small>
            } @else if (temporaryPasswordExpirationOffsetErrors?.["max"]) {
              <small class="text-red-500">
                Perioada de valabilitate a parolei trebuie sa fie mai
                mica de {{ temporaryPasswordExpirationOffsetErrors["max"]["max"] }} {{ pluralTimeUnit }}!</small>
            }
          }
        }
      </div>
    </form>
  } @else if (agentCreateStatus() === "created") {
    <div class="flex flex-column align-items-center justify-content-center text-center">
      <span class="material-symbols-rounded md-64 text-green-500">task_alt</span>
      <h2 class="text-center text-green-500 line-height-2 mb-0">Agentul a fost creat cu succes!</h2>
      <p class="text-2xl font-bold mb-0 word-wrap-anywhere max-w-full line-height-2">{{ createResponse()?.lockerOpenCode }}</p>
      <p>Codul de mai sus trebuie folosit pentru autentificarea agentului la locker. Vă rugăm să vă asigurați că îl copiați.</p>
      <p-button
        size="small"
        [severity]="courierCodeCopied() ? 'success' : 'primary'"
        [label]="courierCodeCopied() ? 'Parola a fost copiată' : 'Copiază parolă'"
        [icon]="courierCodeCopied() ? 'pi pi-check' : 'pi pi-copy'"
        iconPos="right"
        [text]="true"
        (click)="copyCourierCode()"
      ></p-button>
      <p>Atenție! Parola generată nu va mai putea fi vizualizată, ci doar resetată.</p>
      <div class="inline-flex align-items-center gap-2 mb-2">
        <p-checkbox
          inputId="confirmation"
          [formControl]="confirmation"
          [binary]="true"
        ></p-checkbox>
        <label
          for="confirmation"
          [ngClass]="{
            'text-red-500': !confirmation.value && confirmation.dirty && confirmation.touched
          }"
        >Confirm că am copiat codul</label>
      </div>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  @if (agentCreateStatus() === "waiting" || agentCreateStatus() === "creating") {
    <button
      pButton
      label="Adaugă curier"
      icon="pi pi-plus"
      iconPos="right"
      (click)="createAgent()"
    ></button>
  }
  @if (agentCreateStatus() === "created") {
    <button
      pButton
      class="w-full"
      severity="danger"
      label="Închide"
      [disabled]="!confirmation.value"
      (click)="closeModal()"
    ></button>
  }
  </mat-dialog-actions>
@if (agentCreateStatus() === "creating") {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}