@if (pageStatus() !== "regenerated") {
  <div class="dialog-header flex justify-content-between align-items-center w-full">
    <h2>Confirmare</h2>
    <p-button
      size="small"
      mat-dialog-close
      icon="pi pi-times"
      severity="secondary"
      [rounded]="true"
      [text]="true"
    />
  </div>
}
<mat-dialog-content>
  @if (pageStatus() !== "regenerated") {
    <p class="font-bold">Sunteți sigur că doriți să regenerati codul de identificare pentru acest agent?</p>
    <div class="flex flex-column">
      <p class="m-0"><b>Nume</b>: {{ agent.agent.name }}</p>
      <p class="m-0"><b>Email</b>: {{ agent.agent.email }}</p>
      <p class="m-0"><b>Identificator</b>: {{ agent.agent.identifier }}</p>
      <p class="m-0"><b>Telefon</b>: {{ agent.agent.phone }}</p>
    </div>
    <p-messages
      styleClass="text-xs"
      [value]="warnMessage()"
      [enableService]="false"
      [closable]="false"
    ></p-messages>
    <div>
      <span class="font-bold block">Durată acces</span>
      <p-calendar
        dateFormat="dd/mm/yy"
        styleClass="w-full"
        appendTo="body"
        [formControl]="accessExpiration"
        [minDate]="today()"
        [showIcon]="true"
      ></p-calendar>
      <small>Data până la care va fi valabil codul de identificare</small>
    </div>
    <div class="flex align-items-center gap-2 mt-4 mb-2">
      <p-checkbox
        inputId="confirm-delete"
        [binary]="true"
        (onChange)="confirmed.set($event.checked)"
      ></p-checkbox>
      <label for="confirm-delete">Confirm regenerarea codului</label>
    </div>
  } @else {
    <div class="flex flex-column align-items-center justify-content-center text-center">
      <span class="material-symbols-rounded md-64 text-green-500">task_alt</span>
      <h2 class="text-center text-green-500 line-height-2 mb-0">Codul de identificare a fost regenerat cu succes!</h2>
      <p class="text-2xl font-bold mb-0 word-wrap-anywhere max-w-full line-height-2">{{ regenerateResponse()?.lockerOpen?.code }}</p>
      <p-button
        size="small"
        [severity]="codeCopied() ? 'success' : 'primary'"
        [label]="codeCopied() ? 'Codul a fost copiat' : 'Copiază cod'"
        [icon]="codeCopied() ? 'pi pi-check' : 'pi pi-copy'"
        iconPos="right"
        [text]="true"
        (click)="copyIdentificationCode()"
      ></p-button>
      <p class="font-bold m-0">Valabil până la data de {{ regenerateResponse()?.lockerOpen?.expiration | date: "dd/MM/yyyy" }}</p>
      <p>Atenție! Codul generat nu va mai putea fi vizualizat, ci doar resetat.</p>
      <div class="inline-flex align-items-center gap-2 mb-2">
        <p-checkbox
          inputId="confirmation"
          [formControl]="codeCopyConfirmation"
          [binary]="true"
        ></p-checkbox>
        <label
          for="confirmation"
          [ngClass]="{
            'text-red-500': !codeCopyConfirmation.value && codeCopyConfirmation.dirty && codeCopyConfirmation.touched
          }"
        >Confirm că am copiat codul</label>
      </div>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  @if (pageStatus() !== "regenerated") {
    <div class="flex gap-2">
      <p-button
        size="small"
        mat-dialog-close
        label="Anulează"
        [text]="true"
      ></p-button>
      <p-button
        size="small"
        label="Regenerează"
        icon="pi pi-sync"
        [disabled]="!confirmed() || !accessExpiration.valid"
        (onClick)="regenerateIdentificationCode()"
      ></p-button>
    </div>
  } @else {
    <p-button
      class="flex-grow-1"
      styleClass="w-full"
      size="small"
      severity="danger"
      label="Închide"
      [disabled]="!codeCopyConfirmation.value"
      [mat-dialog-close]="true"
    ></p-button>
  }
</mat-dialog-actions>
@if (pageStatus() === "regenerating") {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}