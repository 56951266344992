import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localRo from '@angular/common/locales/ro';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { RoPaginatorIntlComponent } from '../shared/paginator/ro-paginator.component';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MessageService } from 'primeng/api';
registerLocaleData(localRo);


export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({
      eventCoalescing: true
    }),
    { provide: LOCALE_ID, useValue: "ro-RO" },
    provideRouter(routes),
    MessageService,
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withFetch()),
    provideNativeDateAdapter(),
    {
      provide: MatPaginatorIntl,
      useClass: RoPaginatorIntlComponent
    },
    provideAnimationsAsync()
  ]
};