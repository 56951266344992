<form [formGroup]="filtersForm" class="grid align-items-end row-gap-1 mt-2">
  <div class="col-12 md:col-6 lg:col-4 xl:col-3">
    <span class="font-bold block mb-1">Lockere</span>
    <p-multiSelect
      formControlName="lockers"
      optionLabel="name"
      optionValue="id"
      styleClass="w-full"
      appendTo="body"
      placeholder="Caută lockere"
      emptyFilterMessage="Nu există lockere"
      emptyMessage="Nu există lockere"
      [showClear]="true"
      [options]="toSearchLockers()"
      [loading]="!toSearchLockers()"
    />
    <!-- <p-iconField class="w-full" iconPosition="right">
      @if (filtersForm.get('name')?.value?.length > 0) {
        <p-inputIcon
          styleClass="pi pi-times cursor-pointer"
          (click)="filtersForm.patchValue({ name: '' })"
        />
      }
      <input
        pInputText
        class="w-full"
        placeholder="Introduceți numele locker-ului"
        formControlName="name"
      />
    </p-iconField> -->
  </div>
  <div class="col-12 md:col-6 lg:col-8 xl:col-9 flex align-items-center gap-2 mb-3">
    <p-checkbox
      inputId="boxesReport"
      [formControl]="showBoxesReport"
      [binary]="true"
      (onChange)="saveFilters()"
    ></p-checkbox>
    <label for="boxesReport">Afișează cutii lockere</label>
  </div>
</form>

<p-table
  styleClass="p-datatable-sm w-full"
  currentPageReportTemplate="{first} - {last} din {totalRecords} lockere"
  paginatorPosition="both"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [paginator]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [value]="lockers()"
  [rowsPerPageOptions]="[25, 50, 100]"
  [showCurrentPageReport]="true"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (fetching()) {
      <tr>
        <td colspan="8">
          <p-progressBar
            mode="indeterminate"
            styleClass="w-full"
            [style]="{ height: '6px' }"
          ></p-progressBar>
        </td>
      </tr>
    }
    <tr>
      <th rowspan="3">Locker</th>
      <th rowspan="3">Activitate</th>
    </tr>
    <tr>
      <th class="text-center border-200 border-left-1 border-bottom-1 border-right-1" colspan="3">PickUp</th>
      <th class="text-center border-200 border-right-1" colspan="2">DropOff</th>
      <th></th>
    </tr>
    <tr>
      <th class="text-center border-200 border-left-1">În locker<br>(active)</th>
      <th class="text-center border-200 border-left-1">În locker<br>(expirate)</th>
      <th class="text-center border-200 border-x-1">De încărcat<br>în locker</th>
      <th class="text-center">În locker</th>
      <th class="text-center border-200 border-x-1">Rezervate</th>
      <th *appRights="[permissions.createPickUp]"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-locker>
    <tr
      class="cursor-pointer hover:surface-100 transition-colors transition-100 transition-ease-in-out"
      (click)="openLockerDetails(locker)"
    >
      <td [attr.rowspan]="showBoxesReport.value ? 2 : 1">
        <div class="flex flex-column">
          <div>
            <small class="text-gray-400">#{{ locker.id }}</small><br>
            <b>{{ locker.name }}</b>
            <br>
            <small>{{ formatLockerAddress(locker.addressParts) }}</small>
          </div>
        </div>
      </td>
      <td [ngClass]="{
        'borderless-cell': showBoxesReport.value
      }">
        <div class="flex align-items-center gap-2">
          <p-tag
            [value]="locker.state === 'ACTIVE' ? 'ACTIV' : 'INACTIV'"
            [severity]="locker.state === 'ACTIVE' ? 'success' : 'danger'"
          ></p-tag>
          <p-tag
            [value]="locker.isOnline ? 'ONLINE' : 'OFFLINE'"
            [severity]="locker.isOnline ? 'success' : 'danger'"
          ></p-tag>
        </div>
      </td>
      <td [ngClass]="{
        'borderless-cell': showBoxesReport.value
      }" class="text-center">{{locker.requests.pickUp.active.count}}</td>
      <td
        class="text-center"
        [ngClass]="{
          'text-red-500': locker.requests.pickUp.expired.count > 0,
          'borderless-cell': showBoxesReport.value
        }"
      >{{locker.requests.pickUp.expired.count}}</td>
      <td [ngClass]="{
        'borderless-cell': showBoxesReport.value
      }" class="text-center">{{locker.requests.pickUp.incoming.count}}</td>
      <td [ngClass]="{
        'borderless-cell': showBoxesReport.value
      }" class="text-center">{{locker.requests.dropOff.deposited.count}}</td>
      <td [ngClass]="{
        'borderless-cell': showBoxesReport.value
      }" class="text-center">{{locker.requests.dropOff.incoming.count}}</td>
      <td
        *appRights="[permissions.createPickUp]"
        [ngClass]="{
          'borderless-cell': showBoxesReport.value
        }" class="text-right">
        @if (locker.state === "ACTIVE") {
          <p-button
            size="small"
            icon="pi pi-plus"
            label="Adaugă PickUp"
            (click)="$event.stopPropagation(); addPickupOrderToLocker(locker)"
          ></p-button>
        }
      </td>
    </tr>
    @if (showBoxesReport.value) {
      <tr class="surface-100">
        <td class="py-0 text-center">cutii libere</td>
        @for (boxes of sortedBySize(locker.boxes.available); track $index) {
          <td
            [ngClass]="{
              'text-green-500': boxes.count > 0,
              'text-red-500': boxes.count === 0,
              'border-left-2': !$first,
            }"
            class="py-0 font-bold"
          >
            <div class="flex justify-content-between">
              <p class="m-0">{{ boxes.size }}</p>
              <p class="m-0">{{ boxes.count }}</p>
            </div>
          </td>
        }
        <td class="py-0"></td>
        <td class="py-0"></td>
      </tr>
    }
  </ng-template>
</p-table>
