import { HttpErrorResponse } from "@angular/common/http";
import { DestroyRef, EventEmitter, Injector, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_AdminEquipmentListItem } from "@server/services/cbox/public/api/v1/resources/internal/equipment/types";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";

export type FormControlsOf<T> = {
  [K in keyof T]: T[K] extends object
    ? FormGroup<FormControlsOf<T[K]>>
    : FormControl<T[K] | null>;
};

export abstract class LockerComponentBase<T> {
  abstract equipmentToCopy?: CBox_AdminEquipmentListItem;
  abstract loading: boolean;
  abstract onValueChanged: EventEmitter<FormGroup<FormControlsOf<T>>>; // OUTPUT

  equipmentForm!: FormGroup<FormControlsOf<T>>;

  constructor(protected injector: Injector) {}

  protected baseInit(): void {
    const destroyRef = this.injector.get(DestroyRef);
    this.equipmentForm.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => {
      this.valueChanged();
    });
    this.valueChanged();
    if (this.equipmentToCopy) {
      this.fetchEquipmentToCopyData();
    }
    this.init();
  }

  abstract init(): Promise<void>;

  private fetchEquipmentToCopyData(): void {
    const api = this.injector.get(ApiService);
    this.loading = true;
    api.auth().get<CBox_PublicSuccessResponse<any>>("backend/internal/equipments/" + this.equipmentToCopy!.id).subscribe((response) => {
      this.patchCopiedEquipmentData(response.data);
      this.loading = false;
    }, (e: HttpErrorResponse) => {
      const toastService = this.injector.get(ToastService);
      handlePublicApiError(e, toastService);
    });
  }

  protected getForm(): FormGroup<FormControlsOf<T>> {
    return this.equipmentForm;
  }

  protected valueChanged(): void {
    this.onValueChanged.emit(this.getForm());
  }

  protected abstract patchCopiedEquipmentData(copiedEquipmentData: any): void;

}