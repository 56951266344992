<div class="flex align-items-center justify-content-center gap-1 max-w-30rem mx-auto">
  <mat-icon class="logo align-self-center w-20rem h-4rem" svgIcon="logo"></mat-icon>
  și
  <mat-icon class="logo align-self-center w-20rem h-4rem" svgIcon="ro-post-logo"></mat-icon>
</div>
@let sender = response?.contactInfo?.sender;
@if (sender) {
  <p class="text-lg mb-0 mx-2">
    Comanda de la
    <b>{{ sender.name }}</b>
    vă așteaptă la
    @if (response?.locker) {
      <b>{{ response?.locker?.name }}</b>
    }
    până la
    <b>
      {{ response?.expiresAt | date: "dd MMMM" }} ora {{ response?.expiresAt | date: "HH:mm" }}
    </b>
  </p>
} @else {
  <p class="text-lg mb-0 mx-2">
    Comanda vă așteaptă la
    @if (response?.locker) {
      <b>{{ response?.locker?.name }}</b>
    }
    până la
    <b>
      {{ response?.expiresAt | date: "dd MMMM" }} ora {{ response?.expiresAt | date: "HH:mm" }}
    </b>
  </p>
}

<h2 class="text-lg">Scaneaza QR-ul sau introdu codul de mai jos la cBox pentru a ridica comanda</h2>
<div class="flex flex-column align-items-center">
  <img [src]="response?.openQrImage" class="w-full max-w-20rem" alt="qr-image">
  <h3 class="text-2xl letter-spacing-loose mt-0">{{ response?.openCode }}</h3>
</div>
<div class="map-container">
  @defer {
    <app-map
      [latitude]="response?.locker?.coordinates?.latitude!"
      [longitude]="response?.locker?.coordinates?.longitude!"
    ></app-map>
  }
</div>
<p
  class="text-primary-500 underline cursor-pointer"
  (click)="openGoogleMaps()"
>Indicații către adresa</p>
<p-divider></p-divider>
<p class="mt-0">
  În cazul în care întâmpinați dificultăți, nu ezitați să ne contactați la numărul de telefon 021 9411
</p>
<p class="mb-0">© Colete Online 2024. Toate drepturile rezervate.</p>