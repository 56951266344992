
<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Creare modul</h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    size="small"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <form [formGroup]="form" class="grid grid-nogutter gap-2">
    <div class="col-12">
      <span class="font-bold block mb-1">Serie</span>
      <input
        formControlName="serial"
        class="w-full"
        id="awb"
        placeholder="Serie modul"
        pInputText
        pAutoFocus
        [autofocus]="true"
      >
      @let serial = form.get("serial");
      @let serialErrors = form.get("serial")?.errors;
      @if (serial?.dirty) {
        @if (serialErrors) {
          @if (serialErrors?.["required"]) {
            <small class="text-red-500">Câmpul este obligatoriu!</small>
          }
        }
      }
    </div>

    <div class="col-12">
      <span class="font-bold block mb-1">Tip modul</span>
      @let moduleTypeId = form.get("moduleTypeId");
      @let moduleTypeIdErrors = moduleTypeId?.errors;
      <p-dropdown
        formControlName="moduleTypeId"
        optionLabel="name"
        optionValue="id"
        placeholder="Selectați un tip de modul"
        styleClass="w-full"
        appendTo="body"
        [options]="moduleTypes()"
        [loading]="!moduleTypes()"
      />
      @if (moduleTypeId?.dirty) {
        @if (moduleTypeIdErrors?.["required"]) {
          <small class="text-red-500">Câmpul este obligatoriu</small>
        } @else {
          <small>Alegeți tipul de modul pe care doriți să îl creați</small>
        }
      } @else {
        <small>Alegeți tipul de modul pe care doriți să îl creați</small>
      }
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    pButton
    label="Crează modul"
    (click)="create()"
  ></button>
</mat-dialog-actions>
@if (loading() === true) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}