import { CommonModule } from "@angular/common";
import { Component, OnInit, signal } from "@angular/core";
import { MenuItem } from "primeng/api";
import { TabMenuModule } from "primeng/tabmenu";

@Component({
  selector: "app-cbox-profile-reports-container",
  templateUrl: "./cbox-profile-reports-container.component.html",
  styleUrls: ["./cbox-profile-reports-container.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TabMenuModule
  ]
})

export class CBoxProfileReportsContainerComponent implements OnInit {

  tabMenuItems = signal<MenuItem[]>([
    {
      label: "Rapoarte agenți",
      routerLink: "/profile/reports/agents",
      icon: "engineering"
    },
    // {
    //   label: "Rapoarte lockere",
    //   routerLink: "/profile/reports/lockers",
    //   icon: "inventory_2"
    // }
  ]);

  constructor() { }

  ngOnInit() { }
}