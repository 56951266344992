<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Informații modul</h2>
  <p-button
    mat-dialog-close
    size="small"
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  @if (moduleData()) {
    <h2>Serie: {{ moduleData()?.serial }}</h2>
    @if (moduleData()?.isAttached) {
      <h2 class="mb-2">Locker părinte</h2>
      <div>
        <small class="text-gray-400">#{{ moduleData()?.locker?.identifier }}</small><br>
        <b>{{ moduleData()?.locker?.name }}</b>
        <br>
      </div>
    }
    <h2>Echipamente instalate</h2>
    <p-table
      styleClass="p-datatable-sm"
      [value]="moduleData()!.equipments"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Tip</th>
          <th>Serie</th>
          <th>Serie producător</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-equipment>
        <tr>
          <td>{{ equipment.type.name }}</td>
          <td>{{ equipment.internalSerial }}</td>
          <td>{{ equipment.manufacturerSerial }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <td colspan="3">Nu există echipamente instalate</td>
      </ng-template>
    </p-table>

    <h2>Echipamente necesare</h2>
    <p-table
      styleClass="p-datatable-sm"
      [value]="moduleData()!.equipmentTypeCounts"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Tip</th>
          <th class="text-center">Instalate</th>
          <th class="text-center">Limite</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-equipment>
        <tr [ngClass]="{
          'bg-red-100': equipment.counts.actual < equipment.counts.minRequired,
          'bg-green-100': equipment.counts.actual >= equipment.counts.minRequired && equipment.counts.actual <= equipment.counts.maxAllowed,
        }">
          <td>{{ equipment.definition.name }}</td>
          <td class="text-center">{{ equipment.counts.actual }}</td>
          <td class="text-center">
            @let min = equipment.counts.minRequired;
            @let max = equipment.counts.maxAllowed;
            @if (min === max) {
              {{ min }}
            } @else {
              {{ min }} - {{ max }}
            }
          </td>
          <td class="text-right">
            @if (equipment.counts.actual < equipment.counts.minRequired) {
              <span
                matTooltip="Atasează echipament"
                class="pi pi-link cursor-pointer"
                (click)="linkNecesarryEquipment(equipment.definition)"
              ></span>
            }
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <td colspan="3">Nu există echipamente necesare</td>
      </ng-template>
    </p-table>
  }
</mat-dialog-content>
@if (loading()) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}