<div class="dialog-header flex justify-content-between align-items-center w-full">
  <h2>Confirmare</h2>
  <p-button
    size="small"
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <p>Sunteți sigur că doriți să ștergeți comanda DropOff cu AWB
    <span class="font-bold">{{ order.awb }}</span>?</p>
  @if (order.uniqueReference) {
    <p>Referinta unica: {{ order.uniqueReference }}</p>
  }
</mat-dialog-content>
<mat-dialog-actions class="flex gap-2">
  <p-button
    size="small"
    mat-dialog-close
    label="Anulează"
    [text]="true"
  ></p-button>
  <p-button
    size="small"
    severity="danger"
    label="Șterge"
    icon="pi pi-trash"
    (onClick)="deleteOrder()"
  ></p-button>
</mat-dialog-actions>
@if (deleting()) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}