<form [formGroup]="filtersForm" class="grid align-items-end row-gap-1 mt-2">
  <div class="col-12 flex-order-1 md:flex-order-0 md:col-4 xl:col-2">
    <span class="block font-bold mb-1">Filtrează după</span>
    <p-dropdown
      styleClass="w-full"
      optionLabel="label"
      optionValue="value"
      [options]="filterByOptions()"
      formControlName="filterBy"
    />
  </div>
  @let filterByValue = filtersForm.get('filterBy')?.value;
  @if (filterByValue === "name") {
    <div class="col-12 flex-order-2 md:flex-order-1 md:col-4 xl:col-3">
      <p-iconField class="w-full" iconPosition="right">
        @if (filtersForm.get('name')?.value?.length > 0) {
          <p-inputIcon
            styleClass="pi pi-times cursor-pointer"
            (click)="filtersForm.patchValue({ name: '' })"
          />
        }
        <input
          pInputText
          class="w-full"
          placeholder="Nume"
          formControlName="name"
        />
      </p-iconField>
    </div>
  } @else if (filterByValue === "identifier") {
    <div class="col-12 flex-order-2 md:flex-order-1 md:col-4 xl:col-3">
      <p-iconField class="w-full" iconPosition="right">
        @if (filtersForm.get('identifier')?.value?.length > 0) {
          <p-inputIcon
            styleClass="pi pi-times cursor-pointer"
            (click)="filtersForm.patchValue({ identifier: '' })"
          />
        }
        <input
          pInputText
          class="w-full"
          placeholder="Identificator"
          formControlName="identifier"
        />
      </p-iconField>
    </div>
  } @else if (filterByValue === "email") {
    <div class="col-12 flex-order-2 md:flex-order-1 md:col-4 xl:col-3">
      <p-iconField class="w-full" iconPosition="right">
        @if (filtersForm.get('email')?.value?.length > 0) {
          <p-inputIcon
            styleClass="pi pi-times cursor-pointer"
            (click)="filtersForm.patchValue({ email: '' })"
          />
        }
        <input
          pInputText
          class="w-full"
          placeholder="Email"
          formControlName="email"
        />
      </p-iconField>
    </div>
  } @else if (filterByValue === "phone") {
    <div class="col-12 flex-order-2 md:flex-order-1 md:col-4 xl:col-3">
      <p-iconField class="w-full" iconPosition="right">
        @if (filtersForm.get('phone')?.value?.length > 0) {
          <p-inputIcon
            styleClass="pi pi-times cursor-pointer"
            (click)="filtersForm.patchValue({ phone: '' })"
          />
        }
        <input
          pInputText
          class="w-full"
          placeholder="Telefon"
          formControlName="phone"
        />
      </p-iconField>
    </div>
  }
  <div class="
    col-12 flex-order-0
    md:flex-order-2 md:col-4
    xl:col-offset-5 xl:col-2
    text-right
  ">
    <p-button
      *appRights="[permissions.createAgent]"
      label="Adaugă agent"
      icon="pi pi-plus"
      (onClick)="createAgent()"
    ></p-button>
  </div>
  <div class="col-12 flex-order-3 flex align-items-center gap-2">
    <p-checkbox
      inputId="includeDisabled"
      [binary]="true"
      formControlName="includeDisabled"
    ></p-checkbox>
    <label for="includeDisabled">Afișează agenții dezactivați</label>
  </div>
</form>
<p-table
  styleClass="p-datatable-sm w-full"
  currentPageReportTemplate="{first} - {last} din {totalRecords} agenți"
  paginatorPosition="both"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [paginator]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [value]="agents()"
  [rowsPerPageOptions]="[25, 50, 100]"
  [showCurrentPageReport]="true"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (fetchingAgents() === 'fetching') {
      <tr>
        <td colspan="7">
          <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" />
        </td>
      </tr>
    }
    <tr>
      <th>Agent</th>
      <th></th>
      <th>Permisiuni</th>
      <th>Lockere</th>
      <th>Zonă</th>
      <th>Cod identificare</th>
      <th>Parolă</th>
      <th
        *appRights="
          [
            permissions.regenerateAgentCredentials,
            permissions.editAgent,
            permissions.deleteAgent,
            permissions.revokeAgentAccess
          ]
        "
      ></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-agent>
    <tr>
      <td>
        <p class="text-xs text-gray-400 m-0">{{ agent.agent.identifier }}</p>
        <b class="text-lg">{{ agent.agent.name }}</b>
        <p class="text-xs m-0">
          {{ agent.agent.phone }}
          @if (agent.agent.email) {
            <span>
              &#x2022;
              {{ agent.agent.email }}
            </span>
          }
        </p>
      </td>
      <td>
        <p-badge
          [value]="agent.isActive ? 'Activ' : 'Inactiv'"
          [severity]="agent.isActive ? 'success' : 'danger'"
        ></p-badge>
      </td>
      <td>
        <div class="flex align-items-center flex-wrap gap-1">
          @for (permission of agent.permittedActions; track $index) {
            <p-chip
              styleClass="text-xs"
              [label]="permissionsTranslation()[permission.actionType]"
            ></p-chip>
          } @empty {
            <p-chip
              styleClass="text-xs bg-red-200"
              severity="danger"
              icon="pi pi-exclamation-circle"
              [label]="'Nicio permisiune'"
            ></p-chip>
          }
        </div>
      </td>
      <td>
        @if (agent.permittedLockers.all) {
          Toate lockerele din zona <b>{{ getZoneName(agent.agent.zoneId) }}</b>
        } @else {
          @let lockers = agent.permittedLockers;
          @if (lockers.specific.length === 1) {
            @let locker = lockers.specific[0];
            <p class="text-xs text-gray-400 m-0">#{{ locker.id }}</p>
            <b class="font-bold">{{ locker.name }}</b>
          } @else {
            <div
              class="flex align-items-center gap-1 cursor-pointer"
              (click)="this.patchAgentLockersForFilter(agent); op.toggle($event)"
            >
              {{ lockers.specific.length }} lockere
              <span class="material-symbols-rounded">keyboard_arrow_down</span>
            </div>

            <p-overlayPanel #op>
              <div class="flex flex-column w-20rem">
                <input [formControl]="agentLockersFilter" pInputText>
                @for (locker of filteredAgentLockers(); track $index) {
                  <div
                    class="flex justifty-content-between align-items-center w-full pt-2"
                    [ngClass]="{
                      'border-top-1': $index !== 0,
                      'border-gray-100': $index !== 0
                    }"
                  >
                    <div>
                      <p class="text-xs text-gray-400 m-0">#{{ locker.id }}</p>
                      <b class="m-0">{{ locker.name }}</b>
                    </div>
                    <p-button
                      class="ml-auto"
                      size="small"
                      icon="pi pi-info-circle"
                      [text]="true"
                      (onClick)="openLockerDetails(locker.name)"
                    ></p-button>
                  </div>
                } @empty {
                  <p class="text-center text-gray-400 pt-2">Nu există lockere pentru "{{ agentLockersFilter.value }}"</p>
                }
              </div>
          </p-overlayPanel>
          }
        }
      </td>
      <td>{{ getZoneName(agent.agent.zoneId) }}</td>
      <td>
        @let hasCode = agent.access.identificationCode.hasCode;
        @let codeExpiresAt = agent.access.identificationCode.expiresAt;
        @let formattedCodeExpiresAt = codeExpiresAt | date: "dd/MM/YYYY HH:mm";
        <p class="font-bold m-0" [ngClass]="{
          'text-green-500': hasCode,
          'text-red-500': !hasCode
        }">
          {{ hasCode ? 'Activ' : 'Inactiv' }}
        </p>
        @if (hasCode) {
          <p class="text-sm m-0">
            expira la {{ formattedCodeExpiresAt }}
          </p>
        }
      </td>
      <td>
        @let hasPassword = agent.access.password.hasPassword;
        @let passwordExpiresAt = agent.access.password.expiresAt;
        @let formattedPasswordExpiresAt = passwordExpiresAt | date: "dd/MM/YYYY HH:mm";
        <p class="font-bold m-0" [ngClass]="{
          'text-green-500': hasPassword,
          'text-red-500': !hasPassword
        }">
          {{ hasPassword ? 'Activ' : 'Inactiv' }}
        </p>
        @if (hasPassword) {
          <p class="text-sm m-0">
            expira la {{ formattedPasswordExpiresAt }}
          </p>
        }
      </td>
      <td
        *appRights="
          [
            permissions.regenerateAgentCredentials,
            permissions.editAgent,
            permissions.deleteAgent,
            permissions.revokeAgentAccess
          ]
        "
      >
        <div class="card flex justify-content-center">
          <p-menu
            styleClass="w-15rem"
            #agentMenu
            appendTo="body"
            [popup]="true"
            [model]="agentMenuItems()"
          />
          <p-button
            size="small"
            icon="pi pi-ellipsis-v"
            [plain]="true"
            [text]="true"
            [rounded]="true"
            (click)="this.selectedAgent.set(agent); agentMenu.toggle($event)"
          ></p-button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    @if (fetchingAgents() === 'success') {
      <tr>
        <td class="text-center" colspan="5">
          <p class="text-gray-600">
            Nu există agenți.
            <span *appRights="[permissions.createAgent]">
              Adaugați unul nou folosind opțiunea
              <span
                class="hover:underline cursor-pointer text-primary"
                (click)="createAgent()"
              >adaugă agent</span>
            </span>
          </p>
        </td>
      </tr>
    }
</ng-template>
</p-table>