<div class="dialog-header flex justify-content-between align-items-center w-full">
  <h2>Confirmare</h2>
  <p-button
    size="small"
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <p class="font-bold">Sunteți sigur că doriți să ștergeți acest agent?</p>
  <div class="flex flex-column">
    <p class="m-0"><b>Nume</b>: {{ agent.agent.name }}</p>
    <p class="m-0"><b>Email</b>: {{ agent.agent.email }}</p>
    <p class="m-0"><b>Identificator</b>: {{ agent.agent.identifier }}</p>
    <p class="m-0"><b>Telefon</b>: {{ agent.agent.phone }}</p>
  </div>
  <p-messages
    styleClass="text-xs"
    [value]="warnMessage()"
    [enableService]="false"
    [closable]="false"
  ></p-messages>
  <div class="flex align-items-center gap-2 mt-4 mb-2">
    <p-checkbox
      inputId="confirm-delete"
      [binary]="true"
      (onChange)="confirmed.set($event.checked)"
    ></p-checkbox>
    <label for="confirm-delete">Confirm ștergerea agentului</label>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flex gap-2">
  <p-button
    size="small"
    mat-dialog-close
    label="Anulează"
    [text]="true"
  ></p-button>
  <p-button
    size="small"
    severity="danger"
    label="Șterge"
    icon="pi pi-trash"
    [disabled]="!confirmed()"
    (onClick)="deleteAgent()"
  ></p-button>
</mat-dialog-actions>
@if (deleting()) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}