import { CommonModule, isPlatformBrowser } from "@angular/common";
import { AfterViewInit, Component, DestroyRef, Inject, InjectionToken, PLATFORM_ID, computed, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CBox_GetDropOffDataResponse, CBox_GetDropOffListItemDataResponse, CBox_GetDropOffListRequestParams } from "@server/services/cbox/public/api/v1/resources/drop_off/types";
import { MenuItem } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { TableModule, TablePageEvent } from "primeng/table";
import { ProgressBarModule } from "primeng/progressbar";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { CBoxProfileDropOffRequestInfoComponent } from "../dialogs/info/cbox-profile-dropoff-request-info.component";
import { lockerCleanAddress } from "src/helpers/functions";
import { ProfileService } from "src/services/profile/profile.service";
import { DropdownModule } from "primeng/dropdown";
import { PaginatorModule } from "primeng/paginator";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { CheckboxModule } from "primeng/checkbox";
import { TooltipModule } from "primeng/tooltip";
import { CalendarModule } from "primeng/calendar";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { CBox_GetLockerBaseData, CBox_GetLockerShortData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { CBox_PublicPaginatedResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { debounceTime, first } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MenuModule } from "primeng/menu";
import { CBoxProfileDropOffRequestDeleteDialogComponent } from "../dialogs/delete/cbox-profile-drop-off-request-delete-dialog.component";
import { LockersService } from "src/services/lockers/lockers.service";
import { SessionService } from "src/services/session/session.service";
import { ToastService } from "src/services/toast/toast.service";
import { InputIconModule } from "primeng/inputicon";
import { IconFieldModule } from "primeng/iconfield";

@Component({
  selector: "app-cbox-profile-dropoff-requests-list",
  templateUrl: "./cbox-profile-dropoff-requests-list.component.html",
  styleUrls: ["./cbox-profile-dropoff-requests-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    ProgressBarModule,
    DropdownModule,
    PaginatorModule,
    InputGroupModule,
    InputGroupAddonModule,
    CheckboxModule,
    TooltipModule,
    CalendarModule,
    ReactiveFormsModule,
    InputTextModule,
    MenuModule,
    InputIconModule,
    IconFieldModule
  ]
})

export class CBoxProfileDropoffRequestsListComponent implements AfterViewInit {

  filtersForm: FormGroup;
  totalCount = signal(0);

  orders = signal<CBox_GetDropOffListItemDataResponse[]>([]);
  fetchingOrders = signal(false);

  lockers = signal<CBox_GetLockerShortData[]>([]);
  fetchingLockers = signal(false);

  selectedOrder = signal<CBox_GetDropOffListItemDataResponse | undefined>(undefined);
  orderMenuItems = computed<MenuItem[]>(() => [
    {
      label: "Șterge comanda",
      icon: "pi pi-trash",
      command: () => {
        this.confirmOrderDelete(this.selectedOrder());
      },
    },
  ]);

  constructor(
    private api: ApiService,
    private profileService: ProfileService,
    private session: SessionService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private destroyRef: DestroyRef,
    private lockerService: LockersService,
    private toastService: ToastService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {
    // Initialize the form with default values
    this.filtersForm = this.fb.group({
      page: [1],
      pageSize: [100],
      lockerId: [undefined],
      includeNew: [true],
      includeInLocker: [true],
      includeFinalized: [false],
      includeExpired: [false],
      includeCanceled: [false],
      responseId: [undefined],
      awb: [undefined],
      uniqueReference: [undefined],
      interval: [undefined],
    });
  }

  ngAfterViewInit(): void {
    this.init();
  }

  private async init(): Promise<void> {
    this.profileService.setTitle("Listă comenzi DropOff");

    this.lockers.set(await this.lockerService.getList());

    const today = new Date();
    today.setHours(23, 59, 59, 999);

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);
    oneMonthAgo.setHours(0, 0, 0, 0);

    const defaultFilters = {
      page: 1,
      pageSize: 100,
      includeNew: true,
      includeInLocker: true,
      includeFinalized: false,
      includeExpired: false,
      includeCanceled: false,
      interval: [oneMonthAgo, today],
    };

    const cachedFilters = this.session.getVal("dropoff-orders-list/filters", undefined);

    if (cachedFilters) {
      const parsedFilters = JSON.parse(cachedFilters);

      if (parsedFilters.interval && parsedFilters.interval.length === 2) {
        parsedFilters.interval = parsedFilters.interval.map((dateString: string, index: number) => {
          const modified = new Date(dateString).setHours(index === 0 ? 0 : 23, index === 0 ? 0 : 59, 59, 999);
          return new Date(modified);
        });
      }

      this.filtersForm.patchValue(
        {
          ...defaultFilters,
          ...parsedFilters,
        },
        { emitEvent: false }
      );
    } else {
      this.filtersForm.patchValue(defaultFilters, { emitEvent: false });
    }

    this.filtersForm.valueChanges
      .pipe(
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((value) => {
        value.interval = value.interval.map((date: Date, index: number) => {
          const modified = new Date(date).setHours(index === 0 ? 0 : 23, index === 0 ? 0 : 59, 59, 999);
          return new Date(modified);
        });
        this.saveFilters();
        this.onFiltersChanged();
      });

    // Load orders if possible
    if (isPlatformBrowser(this.platformId)) {
      // Check if both dates are selected before initial load
      if (this.areBothDatesSelected()) {
        this.loadOrders();
      }
    }
  }

  private saveFilters(): void {
    const filtersValues = this.filtersForm.value;
    const filtersToSave = {
      ...filtersValues,
      interval: filtersValues.interval
        ? filtersValues.interval.map((date: Date) => date?.toISOString())
        : undefined,
    };
    this.session.setVal("dropoff-orders-list/filters", JSON.stringify(filtersToSave));
  }

  loadOrders(): void {
    this.orders.set([]);
    this.fetchingOrders.set(true);
    this.fetchOrders();
  }

  openDropOffOrderInfo(order: CBox_GetDropOffDataResponse): void {
    const dialog = this.dialog.open(CBoxProfileDropOffRequestInfoComponent, {
      data: order,
      minWidth: "min(1200px, 100%)"
    });

    dialog.afterClosed().pipe(first()).subscribe((dialogResponse: {
      orderDeleted?: boolean
    }) => {
      if (dialogResponse.orderDeleted) {
        this.loadOrders();
      }
    });
  }

  formatLockerAddress(addressParts: CBox_GetLockerBaseData["addressParts"]): string {
    return lockerCleanAddress(addressParts);
  }

  orderHasActiveFlags(order: CBox_GetDropOffDataResponse): boolean {
    const flagValues = Object.values(order.flags);
    return flagValues.some((flag) => flag === true);
  }

  pageChanged(event: TablePageEvent): void {
    const page = event.first / event.rows;
    this.filtersForm.patchValue({
      page: page + 1,
      pageSize: event.rows
    });
    this.saveFilters();
  }

  lockerSelected(locker: CBox_GetLockerBaseData): void {
    this.filtersForm.patchValue({
      lockerId: locker.id
    });
  }

  includeExpiredToggled(checked: boolean): void {
    this.filtersForm.patchValue({
      includeExpired: checked,
      expiredAfter: checked ? new Date().toISOString() : undefined
    });
    this.saveFilters();
  }

  includeFinalizedToggled(checked: boolean): void {
    this.filtersForm.patchValue({
      includeFinalized: checked,
      finalizedAfter: checked ? new Date().toISOString() : undefined
    });
    this.saveFilters();
  }

  private onFiltersChanged(): void {
    if (this.areBothDatesSelected()) {
      this.loadOrders();
    }
  }

  private areBothDatesSelected(): boolean {
    const interval = this.filtersForm.get('interval')?.value;
    return interval && interval.length === 2 && interval[0] && interval[1];
  }

  private confirmOrderDelete(order: CBox_GetDropOffListItemDataResponse | undefined): void {
    if (!order) {
      return;
    }

    const dialog = this.dialog.open(CBoxProfileDropOffRequestDeleteDialogComponent, {
      width: "min(400px, 100%)",
      data: order,
      autoFocus: false,
      disableClose: true
    });

    dialog.afterClosed().pipe(first()).subscribe((deleted: boolean) => {
      if (deleted) {
        this.loadOrders();
      }
    });
  }

  private fetchOrders(): void {
    const params = new HttpParams({
      fromObject: this.getSearchStructure(),
    });

    this.api
      .auth()
      .get<CBox_PublicPaginatedResponse<CBox_GetDropOffListItemDataResponse>>("backend/request/drop-off/list", params)
      .subscribe(
        (response) => {
          this.orders.set(response.data);
          this.totalCount.set(response.totalCount);
          this.fetchingOrders.set(false);
        },
        (e: HttpErrorResponse) => {
          this.fetchingOrders.set(false);
          handlePublicApiError(e, this.toastService);
        }
      );
  }

  private getSearchStructure(): CBox_GetDropOffListRequestParams {
    const values = this.filtersForm.value;

    const params: CBox_GetDropOffListRequestParams = {
      lockerId: values.lockerId,
      page: +values.page,
      pageSize: +values.pageSize,
      includeNew: values.includeNew,
      includeInLocker: values.includeInLocker,
      uniqueReference: values.uniqueReference,
      awb: values.awb,
      includeExpired: values.includeExpired,
      includeCanceled: values.includeCanceled,
      includeFinalized: values.includeFinalized,
    };

    if (values.interval && values.interval.length === 2) {
      params.afterDate = values.interval[0].toISOString();
      params.beforeDate = values.interval[1].toISOString();
    }

    Object.keys(params).forEach(
        (key) =>
            (params[key as keyof CBox_GetDropOffListRequestParams] == null ||
             params[key as keyof CBox_GetDropOffListRequestParams] === '') &&
            delete params[key as keyof CBox_GetDropOffListRequestParams]);

    return params;
  }
}