import { CommonModule } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit, signal } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from "@angular/material/dialog";
import { CBox_ActionDeleteAgentResponse, CBox_ActionUpdateAgentResponse, CBox_PublicGetAgentDataResponse } from "@server/services/cbox/public/api/v1/resources/agent/types";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { Message, MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { MessagesModule } from "primeng/messages";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { copyString } from "src/helpers/functions";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-profile-agent-password-regenerate-dialog",
  templateUrl: "./cbox-profile-agent-password-regenerate-dialog.component.html",
  styleUrls: ["./cbox-profile-agent-password-regenerate-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ButtonModule,
    ProgressSpinnerModule,
    CheckboxModule,
    MessagesModule,
    CalendarModule,
    ReactiveFormsModule
  ],
  providers: [MessageService]
})

export class CBoxProfileAgentPasswordRegenerateDialogComponent implements OnInit {

  pageStatus = signal<"regenerating" | "regenerated" | "waiting">("waiting");
  confirmed = signal(false);
  passwordCopied = signal(false);
  today = signal(new Date());

  accessExpiration = new FormControl(new Date(), Validators.required);
  passwordCopyConfirmation = new FormControl(false, Validators.requiredTrue);

  regenerateResponse = signal<CBox_ActionUpdateAgentResponse | undefined>(undefined);

  warnMessage = signal<Message[]>([{
    severity: "warn",
    detail:
        `Regenerarea codului de identificare va duce la invalidarea codului actual. Dacă doriți să interziceți accesul agentului la lockere, vă rugăm să folosiți opțiunea "revocă acces"`
  }]);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public agent: CBox_PublicGetAgentDataResponse) { }

  ngOnInit(): void {
    const today = new Date();
    const nextMonth = new Date(today.setMonth(today.getMonth() + 1));
    this.accessExpiration.patchValue(nextMonth);
  }

  regeneratePassword(): void {
    this.pageStatus.set("regenerating");
    const encodedIdentifier = encodeURIComponent(this.agent.agent.identifier as string);
    this.api.auth()
        .patch<CBox_PublicSuccessResponse<CBox_ActionDeleteAgentResponse>>(
            "backend/agents/" + encodedIdentifier + "?generatePassword=true", undefined)
        .subscribe(
            (response) => {
              this.regenerateResponse.set(response.data);
              this.pageStatus.set("regenerated");
            },
            (e: HttpErrorResponse) => {
              this.pageStatus.set("waiting");
              handlePublicApiError(e, this.toastService);
            });
  }

  copyPassword(): void {
    if (this.passwordCopied()) {
      return;
    }
    copyString(this.regenerateResponse()?.password?.value!);
    this.passwordCopied.set(true);
    setTimeout(() => {
      this.passwordCopied.set(false);
    }, 2000);
  }
}