@if (pageStatus() !== "regenerated") {
  <div class="dialog-header flex justify-content-between align-items-center w-full">
    <h2>Regenerare credențiale</h2>
    <p-button
      size="small"
      mat-dialog-close
      icon="pi pi-times"
      severity="secondary"
      [rounded]="true"
      [text]="true"
    />
  </div>
}
<mat-dialog-content>
  @if (pageStatus() !== "regenerated") {
    <p class="font-bold">Sunteți sigur că doriți să regenerati credențialele pentru acest utilizator?</p>
    <div class="flex flex-column">
      <p class="m-0"><b>Client ID</b>: {{ apiUser.clientId }}</p>
      <p class="m-0"><b>Descriere</b>: {{ apiUser.description }}</p>
      <p class="m-0"><b>Activitate</b>: {{ getLastActiveMessage(apiUser.lastUsedAt) }}</p>
      <p class="m-0"><b>Rol</b>: {{ apiUser.role }}</p>
    </div>
    <p-messages
      styleClass="text-xs"
      [value]="warnMessage()"
      [enableService]="false"
      [closable]="false"
    ></p-messages>
    <div class="flex align-items-center gap-2 mt-4 mb-2">
      <p-checkbox
        inputId="confirm-delete"
        [binary]="true"
        (onChange)="confirmed.set($event.checked)"
      ></p-checkbox>
      <label for="confirm-delete">Confirm regenerarea credențialelor</label>
    </div>
  } @else {
    <div class="flex flex-column align-items-center justify-content-center text-center">
      <span class="material-symbols-rounded md-64 text-green-500">task_alt</span>
      <h2 class="text-center text-green-500 line-height-2 mb-0">Credențialele au fost regenerate cu succes!</h2>
      <p class="text-2xl font-bold mb-0 word-wrap-anywhere max-w-full line-height-2">{{ regenerateResponse()?.clientSecret }}</p>
      <p-button
        size="small"
        [severity]="clientSecretCopied() ? 'success' : 'primary'"
        [label]="clientSecretCopied() ? 'Codul a fost copiat' : 'Copiază cod'"
        [icon]="clientSecretCopied() ? 'pi pi-check' : 'pi pi-copy'"
        iconPos="right"
        [text]="true"
        (click)="copyClientSecret()"
      ></p-button>
      <p>Atenție! Parola generată nu va mai putea vi vizualizată, ci doar resetată.</p>
      <div class="inline-flex align-items-center gap-2 mb-2">
        <p-checkbox
          inputId="confirmation"
          [formControl]="clientSecretCopyConfirmation"
          [binary]="true"
        ></p-checkbox>
        <label
          for="confirmation"
          [ngClass]="{
            'text-red-500': !clientSecretCopyConfirmation.value && clientSecretCopyConfirmation.dirty && clientSecretCopyConfirmation.touched
          }"
        >Confirm că am copiat parola</label>
      </div>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  @if (pageStatus() !== "regenerated") {
    <div class="flex gap-2">
      <p-button
        size="small"
        mat-dialog-close
        label="Anulează"
        [text]="true"
      ></p-button>
      <p-button
        size="small"
        label="Regenerează"
        icon="pi pi-sync"
        [disabled]="!confirmed()"
        (click)="regenerateClientSecret()"
      ></p-button>
    </div>
  } @else {
    <p-button
      class="flex-grow-1"
      styleClass="w-full"
      size="small"
      severity="danger"
      label="Închide"
      [disabled]="!clientSecretCopyConfirmation.value"
      [mat-dialog-close]="true"
    ></p-button>
  }
</mat-dialog-actions>
@if (pageStatus() === "regenerating") {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}