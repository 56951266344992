import { CommonModule } from "@angular/common";
import {
  Component,
  OnInit,
  signal,
  ViewChild
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from "@angular/material/dialog";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { MultiSelect, MultiSelectChangeEvent, MultiSelectModule } from "primeng/multiselect";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { CalendarModule } from "primeng/calendar";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import {
  CBox_PublicSuccessResponse
} from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import {
  CBox_GetLockerShortData
} from "@server/services/cbox/public/api/v1/resources/locker/types";
import { IconFieldModule } from "primeng/iconfield";
import { InputIconModule } from "primeng/inputicon";
import { TooltipModule } from "primeng/tooltip";
import { CheckboxModule } from "primeng/checkbox";
import {
  CBox_ActionCreateAgentResponse,
  CBox_ActionCreateAgentData,
  CBox_ActionCreateAgentPermittedActionEnum
} from "@server/services/cbox/public/api/v1/resources/agent/types";
import { ValidateEmail } from "src/validators/async-email-validator";
import { InputSwitchModule } from "primeng/inputswitch";
import { MessagesModule } from "primeng/messages";
import { Message } from "primeng/api";
import { copyString, markFormDirty } from "src/helpers/functions";
import { InputNumberModule } from "primeng/inputnumber";
import { KeyFilterModule } from "primeng/keyfilter";
import { HttpErrorResponse } from "@angular/common/http";
import { FormattedPermission } from "../../types";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import {
  CBox_PublicCompanyConfigAgentActionList,
  CBox_PublicCompanyConfigAgentPermittedAction,
  CBox_PublicCompanyConfigAgentTempCodeExpiration
} from "@server/services/cbox/public/api/v1/enforcers/company_config/types";
import { LockersService } from "src/services/lockers/lockers.service";
import { ToastService } from "src/services/toast/toast.service";
import { AutoFocusModule } from "primeng/autofocus";
import { PermissionDirective } from "src/directives/permission.directive";
import { PermissionNamesEnum } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";

@Component({
  selector: "app-cbox-profile-agent-create",
  templateUrl: "./cbox-profile-agent-create.component.html",
  styleUrls: ["./cbox-profile-agent-create.component.scss"],
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    InputTextModule,
    ButtonModule,
    ReactiveFormsModule,
    CommonModule,
    DropdownModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    CalendarModule,
    InputGroupModule,
    InputGroupAddonModule,
    IconFieldModule,
    InputIconModule,
    TooltipModule,
    CheckboxModule,
    InputSwitchModule,
    MessagesModule,
    InputNumberModule,
    KeyFilterModule,
    AutoFocusModule,
  ]
})

export class CBoxProfileAgentCreateComponent implements OnInit {

  form: FormGroup;

  get lockersForm(): FormGroup {
    return this.form.get("lockers") as FormGroup;
  }

  permissionsTranslation = signal<{[key in string]: string}>({
    [CBox_ActionCreateAgentPermittedActionEnum.CHANGE_PAPER]: "Schimbare hârtie",
    [CBox_ActionCreateAgentPermittedActionEnum.COURIER_ALL_ACTIONS]: "Control total comenzi",
    [CBox_ActionCreateAgentPermittedActionEnum.COURIER_PUT_ORDERS]: "Predare comenzi",
    [CBox_ActionCreateAgentPermittedActionEnum.COURIER_RETRIEVE_EXPIRED]: "Recuperare comenzi expirate",
    [CBox_ActionCreateAgentPermittedActionEnum.COURIER_TAKE_ORDERS]: "Ridicare comenzi"
  });

  fetchingLockers = signal<"fetching" | "success" | "error" | "waiting">("waiting");

  permissions = signal<FormattedPermission[] | undefined>(undefined);
  allowedZones = signal<{ id: number; name: string }[]>([]);
  zonesFetched = signal(false);
  groupedLockers = signal<Record<CBox_GetLockerShortData["id"], CBox_GetLockerShortData[]>>({});

  agentCreateStatus = signal<"creating" | "created" | "waiting">("waiting");

  today = signal(new Date());

  createResponse = signal<CBox_ActionCreateAgentResponse | undefined>(undefined);
  courierCodeCopied = signal(false);

  confirmation = new FormControl(false, Validators.requiredTrue);

  autoGeneratedIdentifierMessage = signal<Message[]>([{
    severity: "info",
    detail:
        `Identificatorul agentului va fi generat automat de sistemul nostru.
        Acest cod va fi folosit în sistem pentru afișarea informațiilor
        legate de acest agent. În cazul în care doriți să atribuiți un
        identificator personalizat, vă rugăm să bifați căsuța din formular.`
  }]);

  configurationPermissions = PermissionNamesEnum;

  @ViewChild("lockersMultiSelect") lockersMultiSelect?: MultiSelect;

  constructor(
    private api: ApiService,
    private config: ConfigurationService,
    private toastService: ToastService,
    private lockersService: LockersService,
    private dialogRef: MatDialogRef<CBoxProfileAgentCreateComponent>,
    private fb: FormBuilder) {
      this.form = this.fb.group({
        name: ["", Validators.required],
        phone: ["", Validators.required],
        email: ["", Validators.required],
        zone: [undefined, Validators.required],
        identifier: [{
          value: "",
          disabled: true
        }, Validators.required],
        customIdentifier: [false],
        noPhoneNumber: [false],
        noEmailAddress: [false],
        permissions: [[], [Validators.required, Validators.minLength(1)]],
        lockers: this.fb.group({
          all: [false],
          specific: [[], [Validators.required, Validators.required]]
        }),
        accessDuration: ["", Validators.required],
        temporaryPasswordExpirationOffset: [null, Validators.required],
        passwordExpirationTimeUnit: ["days", Validators.required]
      });
    }

  ngOnInit(): void {
    this.init();
  }

  private async init(): Promise<void> {
    // this.allowedZones.set(await this.config.getAllowedZones());
    await this.setZones();
    await this.setFormValidators();
    const permissions =
        await this.config.getConfigKey("agent.permittedActionsList") as
        CBox_PublicCompanyConfigAgentActionList;
    this.permissions.set(this.formatPermissions(permissions));
  }

  private async setZones(): Promise<void> {
    const lockers = await this.lockersService.getList();
    let groupedLockers: Record<CBox_GetLockerShortData["id"], CBox_GetLockerShortData[]> = {};
    const zones: CBox_GetLockerShortData["zones"] = [];
      for (const locker of lockers) {
        for (const zone of locker?.zones!) {
          if (!groupedLockers[+zone.id]) {
            groupedLockers[+zone.id] = [];
          }
          groupedLockers[+zone.id].push({id: locker.id, name: locker.name});
          if (!zones.find(z => +z.id === zone.id)) {
            zones.push({id: zone.id, name: zone.name});
          }
        }
      }
      const sortedZones = zones.sort((a, b) => {
        const countA = groupedLockers[a.id]?.length || 0;
        const countB = groupedLockers[b.id]?.length || 0;

        return countB - countA;
      });
      this.allowedZones.set(sortedZones);
      this.groupedLockers.set(groupedLockers);
      this.zonesFetched.set(true);
  }

  lockerSelected(): void {
    const lockers = this.lockersForm.get("specific");
    const hasAccessToAllParentLockers = this.lockersForm.get("all");
    const selectedZone = this.form.get("zone")?.value ?? this.allowedZones()[0].id;
    const allLockersSelected = this.groupedLockers()[selectedZone].length === lockers?.value.length;
    hasAccessToAllParentLockers?.patchValue(allLockersSelected, {
      emitEvent: false
    });
    this.toggleAllLockers();
    if (allLockersSelected) {
      this.lockersMultiSelect?.hide();
    }
  }

  createAgent(): void {
    if (this.form.invalid) {
      markFormDirty(this.form);
      return;
    }
    this.agentCreateStatus.set("creating");
    this.api.post<CBox_PublicSuccessResponse<CBox_ActionCreateAgentResponse>>("backend/agents", this.getCreateStructure()).subscribe((response) => {
      this.createResponse.set(response.data);
      this.agentCreateStatus.set("created");
    }, (e: HttpErrorResponse) => {
      this.agentCreateStatus.set("waiting");
      handlePublicApiError(e, this.toastService);
    });
  }

  closeModal(): void {
    this.confirmation.markAsDirty();
    this.confirmation.markAsTouched();
    if (this.confirmation.valid) {
      this.dialogRef.close();
    }
  }

  noPhoneNumberToggled(): void {
    const f = this.form;
    const phoneNumber = f.get("phone");
    phoneNumber?.reset();
    if (f.get("noPhoneNumber")?.value) {
      phoneNumber?.disable();
    } else {
      phoneNumber?.enable();
    }
  }

  noEmailAddressToggled(): void {
    const f = this.form;
    const email = f.get("email");
    email?.reset();
    if (f.get("noEmailAddress")?.value) {
      email?.disable();
    } else {
      email?.enable();
    }
  }

  toggleCustomIdentifier(): void {
    const f = this.form;
    const identifier = f.get("identifier");
    if (f.get("customIdentifier")?.value) {
      identifier?.enable();
    } else {
      identifier?.disable();
    }
  }

  toggleAllLockers(): void {
    const f = this.form;
    if (f.get("lockers.all")?.value) {
      f.get("lockers.specific")?.disable();
      f.get("lockers.specific")?.patchValue([], {
        emitEvent: false
      })
    } else {
      f.get("lockers.specific")?.enable();
    }
  }

  copyCourierCode(): void {
    if (this.courierCodeCopied()) {
      return;
    }
    copyString(this.createResponse()?.lockerOpenCode!);
    this.courierCodeCopied.set(true);
    setTimeout(() => {
      this.courierCodeCopied.set(false);
    }, 2000);
  }

  async changePasswordExpirationTimeUnit(timeUnit: "days" | "hours"): Promise<void> {
    const f = this.form;
    const temporaryPasswordExpirationOffset = f.get("temporaryPasswordExpirationOffset");
    const hours = temporaryPasswordExpirationOffset?.value || 0;

    const agentTemporaryCodeExpirationHours = await this.config.getConfigKey(
      "agent.temporaryCodeExpirationHours"
    ) as CBox_PublicCompanyConfigAgentTempCodeExpiration;

    temporaryPasswordExpirationOffset?.clearValidators();

    if (timeUnit === "days") {
      const days = Math.ceil(hours / 24);
      temporaryPasswordExpirationOffset?.patchValue(days);

      temporaryPasswordExpirationOffset?.setValidators([
          Validators.min(0),
          Validators.max(Math.ceil(agentTemporaryCodeExpirationHours.maximum / 24)), // Convert max hours to max days
      ]);
    } else {
      const updatedHours = Math.ceil(hours * 24);
      temporaryPasswordExpirationOffset?.patchValue(updatedHours);

      temporaryPasswordExpirationOffset?.setValidators([
          Validators.min(agentTemporaryCodeExpirationHours.minimum),
          Validators.max(agentTemporaryCodeExpirationHours.maximum)
      ]);
    }

    temporaryPasswordExpirationOffset?.updateValueAndValidity();
  }

  permissionSelected(event: MultiSelectChangeEvent): void {
    const selectedPermission = event.itemValue;
  }

  getZoneName(zoneId: number): string {
    return this.allowedZones().find(z => +z.id === zoneId)?.name || "";
  }

  private getCreateStructure(): CBox_ActionCreateAgentData {
    const f = this.form.value;
    let temporaryPasswordExpirationOffset = +f.temporaryPasswordExpirationOffset! || 0;
    if (f.passwordExpirationTimeUnit === "days") {
      temporaryPasswordExpirationOffset *= 24;
    }
    const data: CBox_ActionCreateAgentData = {
      agent: {
        name: f.name,
        phone: f.phone,
        email: f.email,
        zoneId: f.zone,
        identifier: f.identifier || undefined,
      },
      permittedActions: f.permissions?.map((p: CBox_PublicCompanyConfigAgentPermittedAction) => {
        return {
          actionType: p
        }
      }),
      permittedLockers: {
        all: !!f.lockers?.all,
        specific: f.lockers?.specific?.length ? f.lockers?.specific?.map((lockerId: string) => {
          return {
            id: lockerId,
          }
        }) : undefined
      },
      accessExpiration: f.accessDuration,
      temporaryPasswordExpirationOffset: temporaryPasswordExpirationOffset
    }

    return data;
  }

  private async setFormValidators(): Promise<void> {
    const email = this.form.get("email");
    email?.setAsyncValidators(ValidateEmail.createValidator(this.api));

    const agentTemporaryCodeExpirationHours =
        await this.config.getConfigKey("agent.temporaryCodeExpirationHours") as
        CBox_PublicCompanyConfigAgentTempCodeExpiration;
    const temporaryPasswordExpirationOffset = this.form.get("temporaryPasswordExpirationOffset");
    temporaryPasswordExpirationOffset?.patchValue(agentTemporaryCodeExpirationHours.value / 24);
    temporaryPasswordExpirationOffset?.setValidators([
      Validators.required,
      Validators.min(0),
      Validators.max(Math.round(agentTemporaryCodeExpirationHours.maximum / 24))
    ]);
  }

  private formatPermissions(permissions: CBox_PublicCompanyConfigAgentActionList): FormattedPermission[] {
    const translations: {[key in string]: string} = {
      CHANGE_PAPER: "Schimbare hârtie",
      COURIER_ALL_ACTIONS: "Gestionare comenzi",
      COURIER_PUT_ORDERS: "Predare comenzi",
      COURIER_RETRIEVE_EXPIRED: "Recuperare comenzi expirate",
      COURIER_TAKE_ORDERS: "Ridicare comenzi"
    };
    const filteredPermissions = permissions.actionsList.filter((p: CBox_PublicCompanyConfigAgentPermittedAction) => p.isVisible);
    return filteredPermissions.map((p: CBox_PublicCompanyConfigAgentPermittedAction) => {
      return {
        value: p.name,
        label: translations[p.name]
      }
    });
  }
}